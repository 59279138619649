// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React, { useState, useEffect, useMemo } from "react";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface IUsuario {
  id_func: number;
  nome: string;
  [key: string]: any;
}

interface ICondominio {
  CONDOMINIO: string;
  peso?: number;
  [key: string]: any;
}

interface IEmpreendimentosResponse {
  condominios: ICondominio[];
  totalEmpreendimentos?: number;
  totalRedirecionados?: number;
  pesoEmpreendimentos?: number;
  pesoRedirecionados?: number;
  totalGeral?: number;
  pesoTotal?: number;
  [key: string]: any;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Carteira do Usuário", active: true },
];

const CarteiraUsuario: React.FC = () => {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [selectedUsuario, setSelectedUsuario] = useState<IUsuario | null>(null);

  const [empreendimentos, setEmpreendimentos] =
    useState<IEmpreendimentosResponse>({
      condominios: [],
    });
  const [redirecionados, setRedirecionados] = useState<ICondominio[]>([]);

  const [todosEmpreendimentos, setTodosEmpreendimentos] = useState<
    ICondominio[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;

  // Buscar a lista de usuários
  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}/carteira-consulta/euro-usuarios/not-diretoria`)
      .then((response) => {
        if (!response.ok) throw new Error("Erro ao carregar usuários");
        return response.json();
      })
      .then((data) => {
        setUsuarios(data);
        setErrorMessage("");
      })
      .catch((error) => {
        console.error("Erro ao buscar usuários:", error);
        setErrorMessage("Erro ao buscar usuários.");
      })
      .finally(() => setLoading(false));
  }, [API_URL]);

  // Quando um usuário é selecionado, buscar empreendimentos
  useEffect(() => {
    if (!selectedUsuario) {
      resetTableData();
      return;
    }

    resetTableData();

    setLoading(true);

    fetch(
      `${API_URL}/carteira-consulta/euro-colaboradores-redirecionamentos/${selectedUsuario.id_func}`,
    )
      .then((response) => {
        if (!response.ok)
          throw new Error("Erro ao buscar empreendimentos do usuário.");
        return response.json();
      })
      .then((data: IEmpreendimentosResponse) => {
        const totalEmpreendimentos = data.condominios.length;
        const pesoEmpreendimentos = data.condominios.reduce(
          (acc, curr) => acc + (curr.peso || 1),
          0,
        );

        fetch(
          `${API_URL}/carteira-por-usuario/euro-colaboradores-redirecionados/${selectedUsuario.id_func}`,
        )
          .then((resp) => {
            if (!resp.ok) throw new Error("Erro ao buscar redirecionamentos.");
            return resp.json();
          })
          .then((redirecionadosData: ICondominio[]) => {
            setRedirecionados(redirecionadosData);

            const totalRedirecionados = redirecionadosData.length;
            const pesoRedirecionados = redirecionadosData.reduce(
              (acc, curr) => acc + (curr.peso || 1),
              0,
            );

            setEmpreendimentos({
              ...data,
              totalEmpreendimentos,
              totalRedirecionados,
              pesoEmpreendimentos,
              pesoRedirecionados,
              totalGeral: totalEmpreendimentos + totalRedirecionados,
              pesoTotal: pesoEmpreendimentos + pesoRedirecionados,
            });

            setTodosEmpreendimentos([
              ...data.condominios,
              ...redirecionadosData,
            ]);
          })
          .catch((error) => {
            console.error("Erro ao buscar redirecionamentos:", error);
            setErrorMessage("Erro ao buscar empreendimentos redirecionados.");
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        console.error("Erro ao buscar empreendimentos do usuário:", error);
        setLoading(false);
      });
  }, [selectedUsuario, API_URL]);

  const tableData = useMemo(() => {
    return todosEmpreendimentos || [];
  }, [todosEmpreendimentos]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "CONDOMINIO",
        header: () => (
          <div>
            <strong>Condomínio:</strong>{" "}
            {empreendimentos.totalEmpreendimentos || 0}
          </div>
        ),
      },
      {
        accessorKey: "peso",
        header: () => (
          <div>
            <strong>Peso:</strong> {empreendimentos.pesoEmpreendimentos || 0}
          </div>
        ),
        cell: ({ row }: any) => {
          const item = row.original;
          return item?.peso ?? 1;
        },
      },
    ];
  }, [empreendimentos]);

  const tableInstance = useAdvanceTable({
    data: tableData,
    columns: columns,
    sortable: true,
    pageSize: 1000,
    pagination: true,
    initialState: {},
  });

  const resetTableData = () => {
    setEmpreendimentos({ condominios: [] });
    setRedirecionados([]);
    setTodosEmpreendimentos([]);
    setErrorMessage("");
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Carteira do Usuário</h2>

      {/* Seletor do Usuário */}
      <Row>
        <Col xs={12} xl={9}>
          <Card>
            <Card.Header>
              <p className="mb-0">
                Selecione o usuário para consultar os empreendimentos
              </p>
              {loading && usuarios.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center py-4">
                  <Spinner animation="border" />
                  <span className="mt-3">Carregando dados, aguarde...</span>
                </div>
              ) : (
                <ReactSelect
                  placeholder="Selecione um usuário"
                  options={usuarios.map((user) => ({
                    value: user,
                    label: user.nome,
                  }))}
                  closeMenuOnSelect={true}
                  value={
                    selectedUsuario
                      ? {
                          value: selectedUsuario,
                          label: selectedUsuario.nome,
                        }
                      : null
                  }
                  onChange={(newValue) => {
                    const option = newValue as { value: IUsuario } | null;

                    resetTableData();

                    setSelectedUsuario(option ? option.value : null);
                  }}
                />
              )}
            </Card.Header>
            <Card.Body>
              {loading ? (
                // Exibe o spinner de carregamento
                <div className="d-flex flex-column justify-content-center align-items-center py-4">
                  <Spinner animation="border" />
                  <span className="mt-3">Carregando dados, aguarde...</span>
                </div>
              ) : selectedUsuario ? (
                // Se um condomínio estiver selecionado, exibe a tabela
                <>
                  <div
                    style={{
                      maxHeight: "500px",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                      padding: "1rem",
                    }}
                    className="bg-white border-top border-bottom border-200 position-relative top-1"
                  >
                    <AdvanceTableProvider {...tableInstance}>
                      <AdvanceTable
                        tableProps={{
                          className:
                            "phoenix-table fs-9 table-hover text-center align-left mb-0",
                        }}
                      />
                      {tableInstance.getRowModel().rows.length === 0 && (
                        <div className="text-center py-10">
                          <p className="text-muted">
                            Nenhum condomínio encontrado
                          </p>
                        </div>
                      )}
                    </AdvanceTableProvider>
                  </div>
                </>
              ) : (
                // Caso contrário, exibe a mensagem de seleção de condomínio
                <p className="text-muted">
                  Selecione um condomínio para ver detalhes
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col
          xs={12}
          xl={3}
          className="d-flex flex-column flex-sm-row flex-xl-column gap-xl-9 gap-2 mt-2"
        >
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-primary fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Total de Empr.:</strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.totalEmpreendimentos ?? "0"}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-primary fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Total:</strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.pesoEmpreendimentos ?? "0"}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-success fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">
                Redirecionamentos:
              </strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.totalRedirecionados ?? "0"}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-success fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Redir.:</strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.pesoRedirecionados ?? "0"}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faBuilding}
              className="me-2 text-info fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Total Geral:</strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.totalGeral ?? "0"}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center me-sm-2 mb-2">
            <FontAwesomeIcon
              icon={faWeightHanging}
              className="me-2 text-info fs-5 fs-sm-6 fs-xl-5"
            />
            <div>
              <strong className="d-block fs-7 fs-sm-8">Peso Geral:</strong>
              <span className="fs-7 fs-sm-8">
                {empreendimentos.pesoTotal ?? "0"}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CarteiraUsuario;
