import React, { useState } from "react";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
// Removido o import do AdvanceTableFooter
import AdvanceTable from "components/base/AdvanceTable";

interface Rateio {
  conta: string;
  descricao: string;
  observacao: string;
  agrupar: boolean;
  valor: string;
}

const RateioTable = ({
  data,
  onObservationChange,
  onAgruparChange,
  onEdit,
  onDelete,
}: {
  data: Rateio[];
  onObservationChange: (index: number, value: string) => void;
  onAgruparChange: (index: number, checked: boolean) => void;
  onEdit?: (item: Rateio) => void;
  onDelete?: (item: Rateio) => void;
}) => {
  // Estado para rastrear qual linha está sendo editada
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [tempObservation, setTempObservation] = useState<string>("");

  const columns: ColumnDef<Rateio>[] = [
    {
      header: "Conta",
      accessorKey: "conta",
      cell: ({ row }: CellContext<Rateio, any>) => (
        <span className="text-xs p-0.5 block">{row.original.conta}</span>
      ),
    },
    {
      header: "Descrição",
      accessorKey: "descricao",
      cell: ({ row }: CellContext<Rateio, any>) => (
        <span className="text-xs text-gray-700 p-0.5 block">
          {row.original.descricao}
        </span>
      ),
    },
    {
      header: "Observação",
      accessorKey: "observacao",
      cell: ({ row }: CellContext<Rateio, any>) => {
        const isEditing = editingRow === row.index;

        const handleSave = () => {
          onObservationChange(row.index, tempObservation);
          setEditingRow(null);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            handleSave();
          } else if (e.key === "Escape") {
            setEditingRow(null);
          }
        };

        return isEditing ? (
          <input
            type="text"
            value={tempObservation}
            onChange={(e) => setTempObservation(e.target.value)}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            className="
              w-full
              border-none
              bg-transparent
              text-xs
              p-0
              m-0
              outline-none
              focus:border-b focus:border-blue-500
              cursor-text
            "
            autoFocus
          />
        ) : (
          <span
            className="text-xs p-0.5 block cursor-pointer hover:bg-gray-100"
            onClick={() => {
              setEditingRow(row.index);
              setTempObservation(row.original.observacao);
            }}
          >
            {row.original.observacao || "—"}
          </span>
        );
      },
    },
    {
      header: "Agrupar",
      accessorKey: "agrupar",
      cell: ({ row }: CellContext<Rateio, any>) => (
        <input
          type="checkbox"
          checked={row.original.agrupar}
          onChange={(e) => onAgruparChange(row.index, e.target.checked)}
          className="form-check-input p-1"
        />
      ),
    },
    {
      header: "Valor",
      accessorKey: "valor",
      cell: ({ row }: CellContext<Rateio, any>) => (
        <span className="text-end text-xs font-medium p-0.5 block">
          {row.original.valor}
        </span>
      ),
    },
  ];

  const table = useAdvanceTable({
    data,
    columns,
    pageSize: 200, // Mantém 10 itens por página
    pagination: true, // Mantém a paginação ativa
    sortable: true, // Ativando a ordenação
  });

  // Log para depuração
  console.log("Dados da tabela:", data);

  return (
    <AdvanceTableProvider {...table}>
      <div
        className="mx-n2 px-2 mx-lg-n3 px-lg-3 bg-white border-t border-b border-gray-200 relative top-1"
        style={{
          maxHeight: "308px", // Ajuste conforme a altura de 10 linhas
          overflowY: "auto",
        }}
      >
        {data.length > 0 ? (
          <AdvanceTable
            tableProps={{
              className:
                "phoenix-table table-sm fs-9 table-hover text-start w-full",
            }}
          />
        ) : (
          <div className="text-center text-gray-500 py-4">
            Nenhum dado disponível para exibição.
          </div>
        )}
      </div>
      {/* Removido o AdvanceTableFooter */}
    </AdvanceTableProvider>
  );
};

export default RateioTable;
