// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { forwardRef, useEffect } from "react";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";

interface ContaBancaria {
  value: string;
  label: string;
}

interface Step5Props {
  formaRecebimento: number;
  setFormaRecebimento: (value: number) => void;
  handleConfirm: () => void;
  configuracoes: {
    honorario: string;
    valorHonorario: number;
    taxaAdministradora: string;
    valorTaxaAdministradora: number;
    tipoRecebimentoTaxa: string;
    porcentagemRecebimento: number | null;
  };
  selectedData: any[];
  REACT_APP_API_URL: string;
  idCondominio: string;
  onComplete: () => void;
  formRef: (el: HTMLFormElement | null) => void;
  contasBancariasAtivas: ContaBancaria[];
  setContasBancariasAtivas: (value: ContaBancaria[]) => void;
  contaBancaria: string;
  setContaBancaria: (value: string) => void;
  dataHoje: string;
  setDataHoje: (value: string) => void;
  registrarCRM: boolean;
  setRegistrarCRM: (value: boolean) => void;
  setDadosQuintaPagina: (dados: Record<string, any>) => void;
}

const ModalAcordosEtapa5 = forwardRef<HTMLFormElement, Step5Props>(
  (
    {
      formaRecebimento,
      setFormaRecebimento,
      configuracoes,
      REACT_APP_API_URL,
      idCondominio,
      contasBancariasAtivas,
      setContasBancariasAtivas,
      contaBancaria,
      setContaBancaria,
      dataHoje,
      setDataHoje,
      registrarCRM,
      setRegistrarCRM,
      setDadosQuintaPagina,
    },
    ref,
  ) => {
    useEffect(() => {
      // Busca contas bancárias ao carregar o componente
      const buscarContasBancarias = async () => {
        try {
          const url = `${REACT_APP_API_URL}/efetuar-acordos/buscar-contas-bancarias/${idCondominio}`;
          const response = await fetch(url);
          const data = await response.json();

          const contasPrincipais = data.filter(
            (conta: any) => conta.fl_principal_cb === "1",
          );

          setContasBancariasAtivas(
            contasPrincipais.map((conta: any) => ({
              label: conta.st_descricao_cb,
              value: conta.id_contabanco_cb,
            })),
          );

          if (contasPrincipais.length > 0) {
            setContaBancaria(contasPrincipais[0].id_contabanco_cb);
          }
        } catch (error) {
          console.error("Erro ao buscar contas bancárias:", error);
        }
      };

      buscarContasBancarias();
    }, [
      REACT_APP_API_URL,
      idCondominio,
      setContaBancaria,
      setContasBancariasAtivas,
    ]);

    useEffect(() => {
      // Cria os dados apenas quando as dependências mudam
      const dadosQuintaPagina = {
        ALTERAR_ENCARGOS: registrarCRM ? 1 : 0,
        NM_TXJUROS_COND: configuracoes.valorHonorario.toFixed(2),
        NM_TXMULTA_COND: configuracoes.valorTaxaAdministradora.toFixed(2),
        NM_TXDESCONTO_RECB: "0.00",
        ID_FORMA_RECB: Number(formaRecebimento),
        ID_CONTABANCO_CB: contaBancaria,
        ID_CONTA_BANCO: contaBancaria,
        DT_ACORDO_ACO: new Date().toLocaleDateString("en-US"),
        ID_IMPRESSAO_FIMP: "",
        naosepararcomposicao: 2,
      };

      setDadosQuintaPagina(dadosQuintaPagina);
    }, [
      formaRecebimento,
      contaBancaria,
      registrarCRM,
      configuracoes.valorHonorario,
      configuracoes.valorTaxaAdministradora,
      setDadosQuintaPagina,
    ]);

    return (
      <Form ref={ref}>
        <h6
          className="mb-3"
          style={{ fontSize: "20px", fontWeight: "bold", color: "#495057" }}
        >
          Finalizar Acordo
        </h6>

        {/* Forma de Recebimento */}
        <Row className="mb-3">
          <Col md={12}>
            <FloatingLabel
              controlId="formaRecebimento"
              label="Forma de Recebimento"
            >
              <Form.Select
                value={formaRecebimento}
                onChange={(e) => setFormaRecebimento(Number(e.target.value))}
              >
                <option value={1}>Escolhida pelo cliente</option>
                <option value={2}>Boleto</option>
                <option value={3}>Cheque</option>
                <option value={4}>Dinheiro</option>
                <option value={5}>Cartão de Crédito</option>
                <option value={6}>Cartão de Débito</option>
                <option value={7}>Depósito</option>
                <option value={8}>Cheque Pré-datado</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>

        {/* Conta Bancária (exibida, mas desabilitada para edição) */}
        <Row className="mb-3">
          <Col md={12}>
            <FloatingLabel controlId="contaBancaria" label="Conta Bancária">
              <Form.Select value={contaBancaria} disabled>
                {contasBancariasAtivas.map((conta, index) => (
                  <option key={index} value={conta.value}>
                    {conta.label}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>

        {/* Data de Geração do Acordo */}
        <Row className="mb-3">
          <Col md={12}>
            <FloatingLabel
              controlId="dataGeracao"
              label="Data de Geração do Acordo"
            >
              <Form.Control
                type="date"
                value={dataHoje}
                onChange={(e) => setDataHoje(e.target.value)}
              />
            </FloatingLabel>
          </Col>
        </Row>

        {/* Registrar no CRM */}
        <Row className="mb-3">
          <Col md={12}>
            <Form.Check
              type="checkbox"
              id="registrarCRM"
              label="Registrar no CRM de cobrança"
              checked={registrarCRM}
              onChange={(e) => setRegistrarCRM(e.target.checked)}
            />
          </Col>
        </Row>
      </Form>
    );
  },
);

ModalAcordosEtapa5.displayName = "ModalAcordosEtapa5";

export default ModalAcordosEtapa5;
