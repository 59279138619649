import PageBreadcrumb, {
  PageBreadcrumbItem,
} from "components/common/PageBreadcrumb";
import { Col, Row } from "react-bootstrap";
import img from "assets/img/spot-illustrations/computer-dev.png";
import imgDark from "assets/img/spot-illustrations/timeline-dark.png";
import { useEffect, useState } from "react";
import BasicTimeline from "./components/BasicTimeline";
import { fetchTimelineData, Timeline } from "./data/timelineData";

export const timelineBreadcrumb: PageBreadcrumbItem[] = [
  {
    label: "Home",
    link: "/home",
  },
  {
    label: "Atualizações",
    active: true,
  },
];

const TimelinePage = () => {
  const [timelineData, setTimelineData] = useState<Timeline[]>([]);

  useEffect(() => {
    const loadTimelineData = async () => {
      const data = await fetchTimelineData();
      setTimelineData(data);
    };
    loadTimelineData();
  }, []);

  return (
    <>
      <PageBreadcrumb
        items={timelineBreadcrumb}
        className="breadcrumb-sticky-top"
      />
      <h2 className="text-bold mb-5 page-title-sticky-top">
        Notas de Atualizações
      </h2>
      <Row className="gx-xl-8 gx-xxl-11">
        <Col xl={5} className="p-xxl-7">
          <div
            className="ms-xxl-3 d-none d-xl-block position-sticky"
            style={{ top: "30%" }}
          >
            <img src={img} alt="" className="d-dark-none img-fluid" />
            <img src={imgDark} alt="" className="d-light-none img-fluid" />
          </div>
        </Col>
        <Col xl={7} className="scrollbar">
          {timelineData.map((timeline) => (
            <div key={timeline.id}>
              <h4 className="py-3 border-y mb-5 ms-8">{timeline.date}</h4>
              <BasicTimeline data={timeline.items} />
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default TimelinePage;
