// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import UserContext from "components/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface IObservacao {
  id: number;
  titulo: string;
  observacao: string;
  importante: number;
  ativo: number;
  area_func: string;
  nome_func: string;
  data: string;
  id_func_edit?: number;
  nome_func_edit?: string;
  data_edit?: string;
}

interface InfoCondominiosProps {
  open: boolean;
  handleClose: () => void;
  idCond: number;
}

const InfoCondominios: React.FC<InfoCondominiosProps> = ({
  open,
  handleClose,
  idCond,
}) => {
  const { user } = useContext(UserContext);
  const [observacoes, setObservacoes] = useState<IObservacao[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedObservacao, setSelectedObservacao] =
    useState<IObservacao | null>(null);
  const [observacao, setObservacao] = useState("");
  const [titulo, setTitulo] = useState("");
  const [importante, setImportante] = useState(0);
  const [filter, setFilter] = useState("Todos");
  const [isCreating, setIsCreating] = useState(false);
  const [observacaoToEdit, setObservacaoToEdit] = useState<IObservacao | null>(
    null,
  );

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (open) {
      fetchObservacoesFromAPI();
    } else {
      resetModal();
    }
  }, [open]);

  const resetModal = () => {
    setObservacoes([]);
    setObservacao("");
    setTitulo("");
    setImportante(0);
    setFilter("Todos");
    setIsCreating(false);
    setObservacaoToEdit(null);
    setSelectedObservacao(null);
    setShowConfirmDelete(false);
  };

  const handleCloseModal = () => {
    handleClose();
    resetModal();
  };

  // Buscar observações
  const fetchObservacoesFromAPI = async () => {
    try {
      const response = await fetch(`${API_URL}/observacoes/${idCond}`);
      const data = await response.json();
      if (data.success) {
        const sorted = data.observacoes.sort(
          (a: IObservacao, b: IObservacao) => b.importante - a.importante,
        );
        setObservacoes(sorted);
      }
    } catch (error) {
      console.error("Erro ao buscar observações:", error);
    }
  };

  // Criar Observação
  const handleAddObservacao = async () => {
    const area_func =
      user?.area === "Juridico" ? "Comunicados e Notificações" : user?.area;

    try {
      const response = await fetch(`${API_URL}/observacoes/criar`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_cond: idCond,
          id_func: user?.id_func,
          nome_func: user?.nome,
          area_func,
          titulo,
          observacao,
          importante,
          ativo: 1,
          id_func_edit: "",
          nome_func_edit: "",
          data_edit: "",
        }),
      });

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI();
        setIsCreating(false);
        setObservacao("");
        setTitulo("");
        setImportante(0);
        toast.success("Observação criada com sucesso!");
      } else {
        throw new Error("Erro ao criar observação");
      }
    } catch (error) {
      console.error("Erro ao adicionar observação:", error);
      toast.error("Erro ao criar observação.");
    }
  };

  // Editar Observação
  const handleEditObservacao = async () => {
    if (!observacaoToEdit) return;

    const area_func =
      user?.area === "Juridico" ? "Comunicados e Notificações" : user?.area;

    try {
      const response = await fetch(
        `${API_URL}/observacoes/editar/${observacaoToEdit.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            titulo,
            observacao,
            importante,
            id_func_edit: user?.id_func,
            nome_func_edit: user?.nome,
            area_func,
            data_edit: new Date(),
          }),
        },
      );

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI();
        setIsCreating(false);
        setObservacaoToEdit(null);
        setObservacao("");
        setTitulo("");
        setImportante(0);
        toast.info("Observação editada com sucesso!");
      } else {
        throw new Error("Erro ao editar observação");
      }
    } catch (error) {
      console.error("Erro ao editar observação:", error);
      toast.error("Erro ao editar observação.");
    }
  };

  // Excluir Observação
  const handleDeleteObservacao = async () => {
    if (!selectedObservacao) return;
    try {
      const response = await fetch(
        `${API_URL}/observacoes/excluir/${selectedObservacao.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ativo: 0 }),
        },
      );

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI();
        toast.error("Observação excluída com sucesso!");
      } else {
        throw new Error("Erro ao excluir observação");
      }
    } catch (error) {
      console.error("Erro ao excluir observação:", error);
      toast.error("Erro ao excluir observação.");
    } finally {
      setShowConfirmDelete(false);
      setSelectedObservacao(null);
    }
  };

  const handleSubmit = () => {
    if (observacaoToEdit) {
      handleEditObservacao();
    } else {
      handleAddObservacao();
    }
  };

  // Abrir modal de criar nova
  const handleCreateNew = () => {
    setObservacao("");
    setTitulo("");
    setImportante(0);
    setIsCreating(true);
    setObservacaoToEdit(null);
  };

  // Cancelar criação/edição
  const handleCancel = () => {
    setIsCreating(false);
    setObservacaoToEdit(null);
    setObservacao("");
    setTitulo("");
    setImportante(0);
  };

  // Abre modal de confirmação antes de excluir
  const handleOpenConfirmDelete = (obs: IObservacao) => {
    setSelectedObservacao(obs);
    setShowConfirmDelete(true);
  };

  // Fecha modal de confirmação de exclusão
  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false);
    setSelectedObservacao(null);
  };

  // Formata com quebra de linha
  const formatTextWithLineBreaks = (text: string) => {
    return text.split("\n").map((str, idx) => (
      <React.Fragment key={idx}>
        {str}
        <br />
      </React.Fragment>
    ));
  };

  const filteredObservacoes = observacoes.filter((obs) => {
    const matchFilter =
      filter === "Todos" ||
      obs.area_func === filter ||
      obs.area_func === "Cobranças";
    return matchFilter && obs.ativo === 1;
  });

  return (
    <>
      <Modal
        show={open}
        onHide={handleCloseModal}
        centered
        size="xl"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Observações do Condomínio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Aplicando opacidade condicional ao conteúdo do modal */}
          <div className={showConfirmDelete ? "opacity-50" : "opacity-100"}>
            {/* Filtros de área */}
            <div className="d-flex flex-wrap mb-3 gap-2">
              {[
                "Todos",
                "Assembleias",
                "Cobranças",
                "Comunicados e Notificações",
                "Financeiro",
                "RH",
              ].map((area) => (
                <Form.Check
                  key={area}
                  inline
                  type="radio"
                  id={`filter-${area}`}
                  label={area}
                  name="filter"
                  value={area}
                  checked={filter === area}
                  onChange={(e) => setFilter(e.target.value)}
                />
              ))}
            </div>

            {/* Lista de observações (filtradas) */}
            <div
              className="overflow-auto border p-2"
              style={{
                height: isCreating ? "333px" : "704px",
              }}
            >
              {filteredObservacoes.map((obs) => (
                <div key={obs.id} className="border rounded p-2 mb-2 bg-light">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="fw-bold">
                      {obs.area_func} — {obs.titulo}
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      {obs.importante === 1 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="#dc3545"
                          title="Observação muito importante!"
                        />
                      )}
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsCreating(true);
                          setTitulo(obs.titulo);
                          setObservacao(obs.observacao);
                          setImportante(obs.importante);
                          setObservacaoToEdit(obs);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenConfirmDelete(obs)}
                      />
                    </div>
                  </div>

                  <div style={{ whiteSpace: "pre-line" }}>
                    {formatTextWithLineBreaks(obs.observacao)}
                  </div>

                  <small className="text-muted">
                    Criado por {obs.nome_func} em{" "}
                    {new Date(obs.data).toLocaleDateString("pt-BR")}
                    {obs.id_func_edit
                      ? ` | Editado por ${obs.nome_func_edit} em 
              ${obs.data_edit ? new Date(obs.data_edit).toLocaleDateString("pt-BR") : ""}`
                      : ""}
                  </small>
                </div>
              ))}
            </div>

            {/* Criar/editar observação */}
            {isCreating && (
              <>
                <hr />
                <h5>
                  {observacaoToEdit
                    ? "Editar Observação"
                    : "Criar Nova Observação"}
                </h5>

                <Form.Group controlId="tituloObs" className="mb-2">
                  <Form.Label>Título</Form.Label>
                  <Form.Control
                    type="text"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="observacaoObs" className="mb-2">
                  <Form.Label>Observação</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={7}
                    value={observacao}
                    onChange={(e) => setObservacao(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="importanteObs" className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label="É muito importante?"
                    checked={importante === 1}
                    onChange={(e) => setImportante(e.target.checked ? 1 : 0)}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end gap-3">
                  <Button variant="primary" onClick={handleSubmit}>
                    {observacaoToEdit ? "Salvar" : "Adicionar"}
                  </Button>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!isCreating && (
            <Button variant="success" onClick={handleCreateNew}>
              Criar Nova Observação
            </Button>
          )}
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Confirmação de Exclusão */}
      <Modal
        show={showConfirmDelete}
        onHide={handleCloseConfirmDelete}
        centered
        backdrop="static"
        className="bg-opacity-50 bg-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja{" "}
          <strong className="text-danger">excluir</strong> a observação{" "}
          <strong>{selectedObservacao?.titulo}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteObservacao}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoCondominios;
