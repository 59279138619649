import React, { useContext } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import NotificationDropdownMenu from "./NotificationDropdownMenu";
import { NotificationsContext } from "../../../context/NotificationsContext";
import Badge from "components/base/Badge"; // Usando o componente Badge personalizado

const NavItemsSlim: React.FC = () => {
  const { notifications, setNotifications, markAllAsRead } =
    useContext(NotificationsContext)!;

  // Calcular número de notificações não lidas
  const unreadCount = notifications.filter((n) => !n.lida).length;

  console.log("Número de notificações não lidas:", unreadCount);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {/* Notificações */}
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              <FeatherIcon icon="bell" size={16} />
              {unreadCount > 0 && (
                <Badge
                  bg="primary"
                  variant="phoenix"
                  className="position-absolute"
                  style={{
                    top: "-5px",
                    right: "-10px",
                    fontSize: "0.75rem",
                    borderRadius: "50%",
                    padding: "4px 6px",
                  }}
                >
                  {unreadCount}
                </Badge>
              )}
            </div>
          </Dropdown.Toggle>
          <NotificationDropdownMenu
            notifications={notifications}
            setNotifications={setNotifications}
            markAllAsRead={markAllAsRead}
          />
        </Dropdown>
      </Nav.Item>

      {/* Menu de perfil */}
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0"
            variant=""
          >
            <FontAwesomeIcon icon={faChevronDown} className="fs-10" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItemsSlim;
