// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FileText } from "feather-icons-react";
import UserContext from "components/UserContext";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { toast, ToastContainer } from "react-toastify";
import InadimplenciaTable from "./table/InadimplenciaTable";
import ReactSelect from "components/base/ReactSelect";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";

interface ICondominio {
  chave: number;
  id_cond: number;
  razao: string;
  cidade: string;
  [key: string]: any;
}

interface ICobrancaAgrupada {
  unidade: string;
  idUnidade: number;
  idsCobrancas: number[];
  total: number;
  numCobrancas: number;
  cobrancas: any[];
}

interface IUser {
  id_func: number;
  usuario_cidade: "Sim" | "Não";
  advogado_externo: "Sim" | "Não";
  [key: string]: any;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Acordos", active: true },
];

const Inadimplencia: React.FC = () => {
  const { user } = useContext<{ user: IUser | null }>(UserContext);
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<ICondominio | null>(null);
  const [loadingCobrancas, setLoadingCobrancas] = useState<boolean>(false);
  const [cobrancasAgrupadas, setCobrancasAgrupadas] = useState<
    ICobrancaAgrupada[]
  >([]);

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (user) {
      const isUsuarioCidade = user.usuario_cidade === "Sim";
      const isAdvogadoExterno = user.advogado_externo === "Sim";
      let fetchURL = `${API_URL}/juridico/buscar-condominios`;

      if (isUsuarioCidade && !isAdvogadoExterno) {
        fetchURL = `${API_URL}/juridico/usuario/condominios-cidade-restritos/${user.id_func}`;
      } else if (isAdvogadoExterno) {
        fetchURL = `${API_URL}/juridico/usuario-condominios/${user.id_func}/nao`;
      }

      fetch(fetchURL)
        .then((response) => response.json())
        .then((data: ICondominio[]) => {
          setCondominios(data.sort((a, b) => a.chave - b.chave));
        })
        .catch((error) => console.error("Erro ao buscar condomínios:", error));
    }
  }, [user, API_URL]);

  const handleEfetuarAcordoClick = (cobranca: ICobrancaAgrupada) => {
    if (selectedCondominio) {
      const params = new URLSearchParams({
        unidade: cobranca.unidade,
        idUnidade: cobranca.idUnidade.toString(),
        idsCobrancas: cobranca.idsCobrancas.join(","),
        totalDevedor: cobranca.total.toFixed(2),
        idCondominio: selectedCondominio.chave.toString(),
      });

      navigate(`/pages/juridico/efetuar-acordo?${params.toString()}`);
    }
  };

  useEffect(() => {
    if (selectedCondominio) {
      setLoadingCobrancas(true);
      fetch(`${API_URL}/juridico/buscar-cobrancas/${selectedCondominio.chave}`)
        .then((response) => response.json())
        .then((data) => {
          processarCobrancas(data);
          setLoadingCobrancas(false);
        })
        .catch((error) => {
          console.error("Erro ao buscar cobranças:", error);
          setLoadingCobrancas(false);
        });
    }
  }, [selectedCondominio, API_URL]);

  const processarCobrancas = (dados: any[]) => {
    if (!Array.isArray(dados)) return;

    const agrupadas: Record<string, ICobrancaAgrupada> = {};
    dados.forEach((item) => {
      if (!item.st_bloco_uni || !item.st_unidade_uni || !item.recebimento)
        return;

      const unidadeFormatada = `${item.st_bloco_uni} ${item.st_unidade_uni}`;
      if (!agrupadas[unidadeFormatada]) {
        agrupadas[unidadeFormatada] = {
          idUnidade: item.id_unidade_uni || 0,
          cobrancas: [],
          total: 0,
          numCobrancas: 0,
          unidade: unidadeFormatada,
          idsCobrancas: [],
        };
      }

      item.recebimento.forEach((cobranca: any) => {
        if (cobranca.id_recebimento_recb && cobranca.vl_total_recb) {
          agrupadas[unidadeFormatada].idsCobrancas.push(
            cobranca.id_recebimento_recb,
          );
          agrupadas[unidadeFormatada].total += parseFloat(
            cobranca.vl_total_recb,
          );
        }
      });

      agrupadas[unidadeFormatada].numCobrancas = item.recebimento.length;
    });

    setCobrancasAgrupadas(Object.values(agrupadas));
  };

  // Gerar relatório de inadimplência
  const gerarRelatorioInadimplencia = async (condominioId: any) => {
    // Exibe o toast de loading
    const toastId = toast.loading("Gerando relatório de inadimplência...");

    // Obter a data atual e formatar como dia-mes-ano
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, "0"); // Garante que o dia tenha 2 dígitos
    const mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Meses em JS começam do 0, por isso +1
    const ano = dataAtual.getFullYear();

    try {
      // Faz a requisição para a API que gera o relatório, passando o idCondominio como parâmetro
      const response = await axios.get(`${API_URL}/juridico/gerar-relatorio`, {
        params: {
          idCondominio: condominioId,
        },
        responseType: "blob", // Definir o tipo de resposta como blob para lidar com arquivos
      });

      // Verifica o status da resposta
      if (response.status === 200) {
        // Formata o nome do arquivo com a data atual
        const fileName = `Relatorio_Inadimplencia_Euro_${condominioId}_${dia}-${mes}-${ano}.pdf`;

        // Utiliza a função saveAs para salvar o arquivo PDF
        saveAs(response.data, fileName);

        // Atualiza o toast para sucesso
        toast.update(toastId, {
          render: "Relatório gerado e baixado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        // Atualiza o toast para erro
        toast.update(toastId, {
          render: "Falha ao gerar o relatório de inadimplência.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.error("Falha ao gerar o relatório de inadimplência:", response);
      }
    } catch (error) {
      // Atualiza o toast para erro
      toast.update(toastId, {
        render: "Erro ao gerar o relatório de inadimplência.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.error("Erro ao gerar o relatório de inadimplência:", error);
    }
  };

  return (
    <div className="container mt-3">
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Acordos</h2>

      <Row>
        <Col xs={12} xl={12}>
          <Row className="align-items-center">
            <Col xs={12} xl={9}>
              <ReactSelect
                placeholder="Selecione o condomínio"
                options={condominios.map((cond) => ({
                  value: cond,
                  label: `${cond.razao} - ${cond.chave} - ${cond.cidade}`,
                }))}
                closeMenuOnSelect={true}
                value={
                  selectedCondominio
                    ? {
                        value: selectedCondominio,
                        label: `${selectedCondominio.razao} - ${selectedCondominio.chave} - ${selectedCondominio.cidade}`,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as { value: ICondominio } | null;
                  setSelectedCondominio(option ? option.value : null);
                }}
                isClearable
              />
            </Col>
            <Col xs={12} xl={3} className="text-end">
              {selectedCondominio && (
                <Button
                  variant="outline-success"
                  onClick={() => {
                    gerarRelatorioInadimplencia(selectedCondominio.chave);
                  }}
                >
                  <FileText size={20} className="mr-2" />
                  Gerar relatório
                </Button>
              )}
            </Col>
          </Row>
        </Col>

        <Col xs={12} className="p-0 mt-4">
          <InadimplenciaTable
            data={selectedCondominio ? cobrancasAgrupadas : []}
            loading={loadingCobrancas}
            onDetailsClick={handleEfetuarAcordoClick}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Inadimplencia;
