import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <footer className={classNames(className, 'footer')}>
      <Row className="g-1 justify-content-between align-items-center h-100">
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 mt-2 mt-sm-0 text-900">
          &copy;{' '} {new Date().getFullYear()} 
          <span className="d-none d-sm-inline-block mx-1">|</span>
          Euro Condomínios. Todos os direitos reservados.
            <span className="d-none d-sm-inline-block" />
            <br className="d-sm-none" />
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
