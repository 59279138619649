// src/utils/colorPalette.ts

export const colors = [
  "#238ee6", // Azul principal
  "#2E96E8", // Azul ligeiramente mais claro
  "#4BA8EB", // Azul suave
  "#6BBBEF", // Azul muito suave
  "#8CCDF2", // Azul claro
  "#B0DFF6", // Azul pastel
  "#D5EEF9", // Azul muito claro, quase branco
  "#F0F8FC", // Azul extremamente claro
];
