// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useMemo } from "react";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Eye } from "feather-icons-react";
import Button from "components/base/Button";

interface InadimplenciaTableProps {
  data: any[];
  loading: boolean;
  onDetailsClick: (item: any) => void;
}

const InadimplenciaTable: React.FC<InadimplenciaTableProps> = ({
  data,
  loading,
  onDetailsClick,
}) => {
  const filteredData = useMemo(
    () => data.filter((item) => item.unidade && item.numCobrancas >= 0),
    [data],
  );

  const styles = `
  .action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .action-buttons .icon-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }
  .phoenix-table tbody tr:hover .icon-button {
    opacity: 1 !important;
    visibility: visible;
  }
`;

  const columns = useMemo(
    () => [
      {
        accessorKey: "unidade",
        header: "Unidade",
      },
      {
        accessorKey: "numCobrancas",
        header: "Número de Cobranças",
      },
      {
        accessorKey: "total",
        header: "Total (Não Atualizado)",
        cell: ({ row }: any) => row.original.total.toFixed(2),
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          const evento = row.original;
          return (
            <div
              className="action-buttons"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="link"
                className="btn-sm text-primary p-0 m-0 icon-button"
                onClick={() => onDetailsClick(evento)}
                title="Ver detalhes"
              >
                <Eye size={16} />
              </Button>
            </div>
          );
        },
      },
    ],
    [onDetailsClick],
  );

  const tableInstance = useAdvanceTable({
    data: filteredData,
    columns,
    sortable: true,
    pageSize: 10,
    pagination: true,
    initialState: {},
  });

  return (
    <div>
      <style>{styles}</style>
      {loading ? (
        <div className="text-center my-3">
          <span>Carregando...</span>
        </div>
      ) : (
        <AdvanceTableProvider {...tableInstance}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{
                className:
                  "phoenix-table fs-9 table-hover text-center align-left",
              }}
            />
            {filteredData.length === 0 && (
              <div
                style={{
                  textAlign: "center",
                  padding: "100px",
                  color: "#6c757d",
                  fontSize: "14px",
                }}
              >
                Selecione um condomínio válido
              </div>
            )}
          </div>
          <div className="px-4">
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      )}
    </div>
  );
};

export default InadimplenciaTable;
