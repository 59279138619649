// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useState, useEffect, forwardRef } from "react";
import { Form, Row, Col, FloatingLabel } from "react-bootstrap";

interface Step3Props {
  selectedData: any[];
  configuracoes: {
    honorario: string;
    valorHonorario: number;
    taxaAdministradora: string;
    valorTaxaAdministradora: number;
    tipoRecebimentoTaxa: string;
    porcentagemRecebimento: number | null;
  };
  handleNext: () => void;
  REACT_APP_API_URL: string;
  idCondominio: string;
  formRef: (el: HTMLFormElement | null) => void;
  totalEtapa2: number;
  setParcelasGeradas: (
    parcelas: { vencimento: string; valor: number; observacao: string }[],
  ) => void;
  setDadosTerceiraPagina: (dados: any) => void;
}

const ModalAcordosEtapa3 = forwardRef<HTMLFormElement, Step3Props>(
  (
    {
      configuracoes,
      handleNext,
      totalEtapa2,
      setParcelasGeradas,
      setDadosTerceiraPagina,
    },
    ref,
  ) => {
    const [valorEntrada, setValorEntrada] = useState<number>(0);
    const [numParcelas, setNumParcelas] = useState<number>(1);
    const [dataVencimento, setDataVencimento] = useState<string>(() => {
      const dataAtual = new Date();
      return dataAtual.toISOString().split("T")[0];
    });
    const [parcelas, setParcelas] = useState<
      { vencimento: string; valor: number; observacao: string }[]
    >([]);

    const formatarDataParaApis = (dataISO: string) => {
      if (!dataISO || typeof dataISO !== "string") return "";
      const [ano, mes, dia] = dataISO.split("-");
      return `${mes}/${dia}/${ano}`;
    };

    const calcularParcelas = (): {
      vencimento: string;
      valor: number;
      observacao: string;
    }[] => {
      const totalNumerico = totalEtapa2;
      const valorEntradaNumerico = valorEntrada;

      const valorAParcelar = totalNumerico - valorEntradaNumerico;
      const valorDaParcela = valorAParcelar / numParcelas;

      const novasParcelas: {
        vencimento: string;
        valor: number;
        observacao: string;
      }[] = [];
      const dataParcela = new Date(dataVencimento);

      if (valorEntradaNumerico > 0) {
        novasParcelas.push({
          vencimento: dataVencimento,
          valor: parseFloat(valorEntradaNumerico.toFixed(2)),
          observacao: "Entrada",
        });
      }

      for (let i = 1; i <= numParcelas; i++) {
        let valorParcela = valorDaParcela;

        if (i === numParcelas) {
          const somaAtual = novasParcelas.reduce(
            (acc, parcela) => acc + parcela.valor,
            0,
          );
          valorParcela = totalNumerico - somaAtual;
        }

        novasParcelas.push({
          vencimento: dataParcela.toISOString().split("T")[0],
          valor: parseFloat(valorParcela.toFixed(2)),
          observacao: `Parcela ${i} de ${numParcelas}`,
        });

        dataParcela.setMonth(dataParcela.getMonth() + 1);
      }

      return novasParcelas;
    };

    useEffect(() => {
      if (valorEntrada >= 0 && numParcelas > 0 && dataVencimento) {
        const novasParcelas = calcularParcelas();
        setParcelas(novasParcelas);
        setParcelasGeradas(novasParcelas);

        // Formatar os dados para a API
        const dadosTerceiraPagina = {
          SIMULA_TOTAL: totalEtapa2.toFixed(2),
          SIMULA_ENTRADA: valorEntrada.toFixed(2),
          NM_PARCELA_ACO: numParcelas.toString(),
          FL_EMITIR_RECIBO: "0",
          DT_PRIMEIRA_PARCELA: formatarDataParaApis(dataVencimento),
          VL_PRIMEIRA_PARCELA:
            novasParcelas.length > 0
              ? novasParcelas[0].valor.toFixed(2)
              : "0.00",
        };

        // Passar os dados formatados para o modal geral
        setDadosTerceiraPagina(dadosTerceiraPagina);
      }
    }, [valorEntrada, numParcelas, dataVencimento]);

    useEffect(() => {
      if (valorEntrada >= 0 && numParcelas > 0 && dataVencimento) {
        const novasParcelas = calcularParcelas();
        setParcelas(novasParcelas);
        setParcelasGeradas(novasParcelas);
      }
    }, [valorEntrada, numParcelas, dataVencimento]);

    return (
      <Form
        ref={ref}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h6
            className="mb-4"
            style={{ fontSize: "20px", fontWeight: "bold", color: "#495057" }}
          >
            Configurações de Parcelamento
          </h6>
          <Row className="mb-3">
            <Col md={6}>
              <FloatingLabel controlId="valorEntrada" label="Valor de Entrada">
                <Form.Control
                  type="number"
                  placeholder="Digite o valor"
                  value={valorEntrada === 0 ? "" : valorEntrada}
                  onChange={(e) => {
                    const valor = e.target.value;
                    const valorNumerico = valor === "" ? 0 : parseFloat(valor);

                    if (valorNumerico >= 0) {
                      setValorEntrada(valorNumerico);
                    }
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel controlId="numParcelas" label="Número de Parcelas">
                <Form.Control
                  type="number"
                  placeholder="Digite o número de parcelas"
                  value={numParcelas}
                  onChange={(e) =>
                    setNumParcelas(parseInt(e.target.value) || 1)
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <FloatingLabel
                controlId="dataVencimento"
                label="Vencimento da Primeira Parcela"
              >
                <Form.Control
                  type="date"
                  placeholder="Escolha a data"
                  value={dataVencimento}
                  onChange={(e) => setDataVencimento(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>

          {/* Resumo das Parcelas */}
          {parcelas.length > 0 && (
            <div
              className="mt-6"
              style={{
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#495057",
              }}
            >
              <h6
                className="mb-4"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#495057",
                }}
              >
                Configurações de Parcelamento
              </h6>
              <p>
                {numParcelas} x de R${" "}
                {((totalEtapa2 - valorEntrada) / numParcelas).toFixed(2)}
              </p>
              {valorEntrada > 0 && (
                <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                  + Entrada de R$ {valorEntrada.toFixed(2)}
                </p>
              )}
            </div>
          )}
        </div>
      </Form>
    );
  },
);

ModalAcordosEtapa3.displayName = "ModalAcordosEtapa3";

export default ModalAcordosEtapa3;
