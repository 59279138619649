// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React, { useState, useEffect, useContext } from "react";
import UserContext from "components/UserContext";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import { Row, Col, Form, Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "components/base/Badge";
import {
  faUsers,
  faMoneyCheck,
  faEnvelope,
  faDollarSign,
  faUserTie,
  faAngleDown,
  faAngleUp,
  faShieldAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import InfoCondominios2 from "./InfoCondominios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ICondominio {
  chave: number;
  fantasia?: string;
  cidade?: string;
}

interface ICarteraData {
  FINANCEIRO?: number;
  COBRANÇA?: number;
  RH?: number;
  COMUNICADOS_NOTIFICAÇÕES?: number;
  ASSEMBLEIAS?: number;
  SQUAD?: number;
  [key: string]: any;
}

interface IUserAbsenceInfo {
  name: string;
  alertText?: string;
  originalNameForAbsence?: string;
  absenceEndDate?: string;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Consultar Carteira", active: true },
];

const departmentOrder = [
  {
    key: "SQUAD",
    label: "Squad",
    icon: <FontAwesomeIcon icon={faShieldAlt} className="me-2 fs-6" />,
  },
  {
    key: "ASSEMBLEIAS",
    label: "Assembleias",
    icon: <FontAwesomeIcon icon={faUsers} className="me-2 fs-6" />,
  },
  {
    key: "COBRANÇA",
    label: "Cobrança",
    icon: <FontAwesomeIcon icon={faMoneyCheck} className="me-2 fs-6" />,
  },
  {
    key: "COMUNICADOS_NOTIFICAÇÕES",
    label: "Comunicados e Notificações",
    icon: <FontAwesomeIcon icon={faEnvelope} className="me-2 fs-6" />,
  },
  {
    key: "FINANCEIRO",
    label: "Financeiro",
    icon: <FontAwesomeIcon icon={faDollarSign} className="me-2 fs-6" />,
  },
  {
    key: "RH",
    label: "RH",
    icon: <FontAwesomeIcon icon={faUserTie} className="me-2 fs-6" />,
  },
];

const ConsultarCarteira: React.FC = () => {
  const { user } = useContext(UserContext);
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<ICondominio | null>(null);
  const [carteiraData, setCarteiraData] = useState<ICarteraData>({});
  const [userNames, setUserNames] = useState<Record<string, IUserAbsenceInfo>>(
    {},
  );
  const [loadingCondominios, setLoadingCondominios] = useState(false);
  const [loadingCarteiraData, setLoadingCarteiraData] = useState(false);
  const [loadingUserNames, setLoadingUserNames] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ordenacao, setOrdenacao] = useState("alfabetica");
  const [ordenacaoAscendente, setOrdenacaoAscendente] = useState(true);
  const [showInfoCondominios, setShowInfoCondominios] = useState(false);
  const [obsCount, setObsCount] = useState(0);

  const API_URL = process.env.REACT_APP_API_URL;

  const isLoading =
    loadingCondominios || loadingCarteiraData || loadingUserNames;

  const toggleOrdenacao = () => setOrdenacaoAscendente(!ordenacaoAscendente);

  useEffect(() => {
    setLoadingCondominios(true);

    const urlParams = new URLSearchParams({
      ordenacao,
      direcao: ordenacaoAscendente ? "ASC" : "DESC",
    });

    // Se for "usuario_cidade=Sim", pega rota específica, senão pega a rota normal
    const url =
      user.usuario_cidade === "Sim"
        ? `${API_URL}/carteira-por-usuario/usuario-cidade/condominios-restritos/${user.id_func}?${urlParams}`
        : `${API_URL}/condominios/comCidade?${urlParams}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setCondominios(
          data.filter((cond: ICondominio) => cond.fantasia && cond.cidade),
        );
        setErrorMessage("");
      })
      .finally(() => setLoadingCondominios(false));
  }, [ordenacao, ordenacaoAscendente, user, API_URL]);

  // Buscar detalhes da carteira (cond -> roles)
  useEffect(() => {
    if (!selectedCondominio) {
      // Se não houver cond, zera a contagem e limpa dados
      setObsCount(0);
      setCarteiraData({});
      return;
    }

    setLoadingCarteiraData(true);
    setCarteiraData({});

    fetch(`${API_URL}/condominios/porId/${selectedCondominio.chave}`)
      .then((res) => {
        if (!res.ok) throw new Error("Erro ao buscar carteira.");
        return res.json();
      })
      .then((data) => {
        // Converte valores não-numéricos em null, para evitar erro
        const validData = Object.keys(data).reduce((acc, key) => {
          acc[key] = typeof data[key] === "number" ? data[key] : null;
          return acc;
        }, {} as ICarteraData);

        setCarteiraData(validData);
        setErrorMessage("");
      })
      .catch(() => setErrorMessage("Erro ao buscar carteira."))
      .finally(() => setLoadingCarteiraData(false));

    // Carrega contagem de observações
    fetch(`${API_URL}/observacoes/contar-obs/${selectedCondominio.chave}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) setObsCount(data.count);
        else setObsCount(0);
      })
      .catch(() => setObsCount(0));
  }, [selectedCondominio, API_URL]);

  // Para cada role, buscar o colaborador e checar ausência
  useEffect(() => {
    if (!selectedCondominio) return;
    if (!carteiraData) return;

    const fetchUserData = async () => {
      setLoadingUserNames(true);

      const newUserNames: Record<string, IUserAbsenceInfo> = {};

      // Percorre as chaves do carteiraData
      for (const role of Object.keys(carteiraData)) {
        const idFunc = carteiraData[role];
        if (typeof idFunc !== "number" || !idFunc) {
          continue;
        }

        try {
          const { name: originalName } = await getUserNameById(idFunc);
          const absenceInfo = await checkUserAbsence(
            idFunc,
            selectedCondominio.chave,
            originalName,
          );
          newUserNames[role] = absenceInfo;
        } catch (error) {
          // Log simplificado
          console.error(`Erro ao buscar usuário para ID ${idFunc}`);
        }
      }

      setUserNames(newUserNames);
      setLoadingUserNames(false);
    };

    fetchUserData();
  }, [carteiraData, selectedCondominio, API_URL]);

  // Buscar nome do colaborador
  async function getUserNameById(idFunc: number) {
    try {
      const resp = await fetch(`${API_URL}/user/get-user/${idFunc}`);
      const json = await resp.json();
      return {
        name: json?.user?.nome || `Usuário #${idFunc}`,
      };
    } catch {
      return { name: `#${idFunc}` };
    }
  }

  // Verificar ausência e possível redirecionamento
  async function checkUserAbsence(
    idFunc: number,
    idCondo: number,
    originalName: string,
  ): Promise<IUserAbsenceInfo> {
    try {
      // Checar se o idFunc tem ausência ativa
      const resp = await fetch(
        `${API_URL}/ausencia/check-active-absences/${idFunc}`,
      );
      const data = await resp.json();

      const hasActiveAbsence = data && data.activeAbsencesCount > 0;

      // Se ausente, checar redirecionamento
      if (hasActiveAbsence) {
        const redirResp = await fetch(
          `${API_URL}/ausencia/redirection-by-user/${idFunc}/${idCondo}`,
        );
        const redirData = await redirResp.json();

        if (redirData?.redirection) {
          const tempID = redirData.redirection.id_func_temp;
          const { name: tempName } = await getUserNameById(tempID);

          // Data de fim formatada
          const dtFim = redirData.redirection.dt_final
            ? formatDate(redirData.redirection.dt_final)
            : "";

          return {
            name: tempName,
            alertText: "Cumprindo ausência",
            originalNameForAbsence: originalName,
            absenceEndDate: dtFim,
          };
        } else {
          // Sem redirecionamento, mas está ausente
          return {
            name: originalName,
            alertText: "Ausente, necessita redirecionamento",
          };
        }
      }
      return { name: originalName };
    } catch (error) {
      return { name: originalName };
    }
  }

  // Formata data YYYY-MM-DD => DD/MM/YYYY
  function formatDate(isoDate: string) {
    const dataObj = new Date(isoDate);
    const dia = String(dataObj.getDate()).padStart(2, "0");
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  // Renderizar o “badge” de ausência, se houver
  function renderAbsenceBadge(info: IUserAbsenceInfo) {
    if (!info.alertText) return null; // Sem ausência

    let variant: "info" | "warning";
    let text: string;

    switch (info.alertText) {
      case "Cumprindo ausência":
        variant = "info";
        text = "Cumprindo ausência";
        break;

      case "Ausente, necessita redirecionamento":
        variant = "warning";
        text = "Redirecionamento necessário";
        break;

      default:
        return null;
    }

    return (
      <Badge
        variant="phoenix"
        bg={variant}
        className="fs-12 px-2 d-inline-flex justify-content-center align-items-center ms-2"
        style={{
          minWidth: "120px",
        }}
      >
        {text}
      </Badge>
    );
  }

  return (
    <div>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Consultar Carteira</h2>

      <Row>
        <Col xs={12} xl={9}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {/* Seletor de ordenação */}
                <div className="d-flex align-items-center">
                  <Form.Select
                    className="form-select-sm me-2"
                    style={{ width: "120px" }}
                    value={ordenacao}
                    onChange={(e) => setOrdenacao(e.target.value)}
                  >
                    <option value="alfabetica">Alfabética</option>
                    <option value="chave">ID</option>
                    <option value="cidade">Cidade</option>
                  </Form.Select>

                  {/* Ícone para inverter ASC/DESC */}
                  <FontAwesomeIcon
                    icon={ordenacaoAscendente ? faAngleDown : faAngleUp}
                    className="fs-8 text-900 mx-3"
                    style={{ cursor: "pointer" }}
                    onClick={toggleOrdenacao}
                  />
                </div>

                {/* Ícone de observações, com badge */}
                <div style={{ position: "relative" }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="fs-6"
                    style={{
                      color: obsCount > 0 ? "orange" : "#FFC107",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowInfoCondominios(true)}
                    title="Observações do condomínio"
                  />
                  {obsCount > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-8px",
                        backgroundColor: "orange",
                        color: "white",
                        borderRadius: "50%",
                        fontSize: "0.6rem",
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {obsCount}
                    </span>
                  )}
                </div>
              </div>

              {/* Filtro/busca de Condomínio */}
              <Form.Label className="fs-9 fw-bold mt-3 d-block">
                Buscar Condomínio
              </Form.Label>
              <ReactSelect
                placeholder="Selecione o condomínio"
                options={condominios.map((cond) => ({
                  value: cond,
                  label: `${cond.fantasia} - ${cond.chave} - ${cond.cidade}`,
                }))}
                closeMenuOnSelect={true}
                value={
                  selectedCondominio
                    ? {
                        value: selectedCondominio,
                        label: `${selectedCondominio.fantasia} - ${selectedCondominio.chave} - ${selectedCondominio.cidade}`,
                      }
                    : null
                }
                onChange={(newValue) => {
                  setCarteiraData({});
                  setUserNames({});
                  const option = newValue as { value: ICondominio } | null;
                  setSelectedCondominio(option ? option.value : null);
                }}
                isClearable
              />
            </Card.Header>

            <Card.Body>
              {isLoading ? (
                // Loading spinner
                <div className="d-flex flex-column justify-content-center align-items-center py-4">
                  <Spinner animation="border" />
                  <span className="mt-3">Carregando dados, aguarde...</span>
                </div>
              ) : selectedCondominio ? (
                <>
                  {/* Mostra os departamentos para este condomínio */}
                  {departmentOrder.map((dep, index) => {
                    const roleId = carteiraData[dep.key as keyof ICarteraData];
                    const isSquad = dep.key === "SQUAD";

                    // Se for "SQUAD", exibimos "N/A"
                    const userInfo = userNames[dep.key];
                    const displayName = isSquad
                      ? "N/A"
                      : userInfo
                        ? userInfo.name
                        : null;

                    // Se não for SQUAD e não tiver um colaborador, não exibimos
                    if (!isSquad && !displayName) return null;

                    return (
                      <React.Fragment key={dep.key}>
                        <p className="mb-3 d-flex align-items-center fs-7">
                          <span
                            className="d-flex align-items-center justify-content-center me-3"
                            style={{
                              width: "30px",
                              color: "#0B5ED7",
                              fontSize: "1.2em",
                            }}
                          >
                            {dep.icon}
                          </span>
                          <strong style={{ textAlign: "left" }}>
                            {dep.label}:
                          </strong>
                          <span className="fs-7 ms-2">{displayName}</span>

                          {/* Se houver status de ausência, exibe um "badge" */}
                          {userInfo && renderAbsenceBadge(userInfo)}
                        </p>

                        {index < departmentOrder.length - 1 && (
                          <hr
                            className="border-t my-2"
                            style={{
                              borderColor: "#0B5ED7",
                              borderWidth: "0.5px",
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <p className="text-muted">
                  Selecione um condomínio para ver detalhes
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Exibe mensagem de erro se houver */}
      {errorMessage && (
        <Row className="mt-4">
          <Col>
            <div className="text-danger">{errorMessage}</div>
          </Col>
        </Row>
      )}

      {/* Modal de Observações (InfoCondominios2) */}
      <InfoCondominios2
        open={showInfoCondominios}
        handleClose={() => setShowInfoCondominios(false)}
        idCond={selectedCondominio?.chave ?? 0}
      />
    </div>
  );
};

export default ConsultarCarteira;
