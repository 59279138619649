import React from "react";
import {
  Dropdown,
  Nav,
  ToastContainer,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import classNames from "classnames";
import Avatar from "components/base/Avatar";
import NotificationDropdownMenu from "./NotificationDropdownMenu";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { useAppContext } from "providers/AppProvider";
import { NotificationsContext } from "../../../context/NotificationsContext";
import CondominioPesquisa from "pages/geral/carteiras/CondominioPesquisa";

// ---> 1) Importe também o UserContext
import { useContext } from "react";
import UserContext from "../../UserContext";

const NavItems: React.FC = () => {
  const {
    config: { navbarPosition },
  } = useAppContext();

  // Pegue tudo que precisa do NotificationsContext
  const { notifications, setNotifications, markAllAsRead, unreadCount } =
    React.useContext(NotificationsContext)!;

  // ---> 2) Pegue o user do contexto
  const { user } = useContext(UserContext);

  return (
    <>
      <ToastContainer />
      <div className="navbar-nav navbar-nav-icons flex-row align-items-center">
        {/* SSI 0090 - GCOLLE - Consulta Carteira Topbar */}
        <Nav.Item>
          <CondominioPesquisa />
        </Nav.Item>
        <Nav.Item
          className={classNames({
            "d-lg-none":
              navbarPosition === "vertical" || navbarPosition === "dual",
          })}
        >
          <Nav.Link>
            <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
          </Nav.Link>
        </Nav.Item>

        {/* Ícone do sino com Tooltip */}
        <Nav.Item className="mx-2">
          <Dropdown autoClose="outside" className="h-80">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Notificações</Tooltip>}
            >
              <Dropdown.Toggle
                as={Link}
                to="#!"
                className="dropdown-caret-none nav-link h-80 position-relative"
                variant=""
              >
                <FeatherIcon icon="bell" size={20} />
                {unreadCount > 0 && (
                  <span
                    className="badge bg-danger text-white position-absolute"
                    style={{
                      top: "7px",
                      right: "5px",
                      fontSize: "0.6rem",
                      padding: "2px 4px",
                      borderRadius: "50%",
                    }}
                  >
                    {unreadCount}
                  </span>
                )}
              </Dropdown.Toggle>
            </OverlayTrigger>
            <NotificationDropdownMenu
              notifications={notifications}
              setNotifications={setNotifications}
              markAllAsRead={markAllAsRead}
            />
          </Dropdown>
        </Nav.Item>

        {/* Ícone de avatar */}
        <Nav.Item>
          <Dropdown autoClose="outside" className="h-80">
            <Dropdown.Toggle
              as={Link}
              to="#!"
              className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-80 d-flex align-items-center"
              variant=""
            >
              {/* ---> Passe a URL do usuário para o Avatar */}
              <Avatar size="m" src={`${user?.avatar}?t=${Date.now()}`} />
            </Dropdown.Toggle>
            <ProfileDropdownMenu />
          </Dropdown>
        </Nav.Item>
      </div>
    </>
  );
};

export default NavItems;
