// LancamentosUsuario.tsx
// 0054 - Gabriel Colle - Filtrar por numero de condominio e fixar header tabela - 05/09/2024

import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import { CircularProgress } from "@mui/material";
import axios from "axios";
import { Modal, Box, Typography } from "@mui/material";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface DetalheLancamento {
  nomeCondominio: string;
  numeroLancamentos: number;
  percentual: number;
  peso: number;
}

interface UsuarioData {
  usuario: string;
  usuarioId: string;
  numeroLancamentos: number;
  pesoTotal: number;
  numeroCondominios: number;
  agendamentos: number;
}

interface DetalhesLancamentoUsuario {
  usuario: string;
  detalhesLancamentos: DetalheLancamento[];
}

interface LancamentosUsuarioProps {
  periodo: string;
}

// -----------------------------------------------------
//  Componente principal
// -----------------------------------------------------
const LancamentosUsuario: React.FC<LancamentosUsuarioProps> = ({ periodo }) => {
  const API_URL = process.env.REACT_APP_API_URL || "";
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Dados exibidos na tabela
  const [allData, setAllData] = useState<UsuarioData[]>([]);

  // Dados exibidos no modal
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<DetalheLancamento[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");

  // Dados para o gráfico
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartValues, setChartValues] = useState<number[]>([]);

  // -----------------------------------------------------
  //  Busca e formata dados para tabela e gráfico
  // -----------------------------------------------------
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get<UsuarioData[]>(
        `${API_URL}/grafico-lancamentos/usuarios-lancamentos?periodo=${periodo}`,
      );

      if (response.data && Array.isArray(response.data)) {
        const dados = response.data;
        // Ordena inicialmente por "pesoTotal" (apenas para exibição inicial)
        dados.sort((a, b) => b.pesoTotal - a.pesoTotal);

        setAllData(dados);

        // Extrai rótulos e valores para o gráfico
        const labels = dados.map((d) => d.usuario);
        const values = dados.map((d) => d.numeroLancamentos);

        setChartLabels(labels);
        setChartValues(values);
      } else {
        setAllData([]);
        setChartLabels([]);
        setChartValues([]);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setAllData([]);
      setChartLabels([]);
      setChartValues([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [periodo]);

  // -----------------------------------------------------
  //  Busca dados detalhados para o modal
  // -----------------------------------------------------
  const handleUserClick = async (usuarioId: string) => {
    try {
      const response = await axios.get<DetalhesLancamentoUsuario>(
        `${API_URL}/grafico-lancamentos/usuarios/${usuarioId}/lancamentos-detalhados?periodo=${periodo}`,
      );
      if (
        response.data &&
        response.data.detalhesLancamentos &&
        response.data.detalhesLancamentos.length > 0
      ) {
        const sortedData = response.data.detalhesLancamentos.sort(
          (a, b) => b.numeroLancamentos - a.numeroLancamentos,
        );
        setModalData(sortedData);
        setSelectedUser(response.data.usuario);
        setModalOpen(true);
      } else {
        setModalData([]);
        setSelectedUser("");
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes dos lançamentos:", error);
    }
  };

  // -----------------------------------------------------
  //  Configuração das colunas da tabela
  // -----------------------------------------------------
  const renderUsuario = (row: UsuarioData, index: number) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => handleUserClick(row.usuarioId)}
      >
        {row.usuario}
      </div>
    );
  };

  // Exemplo de colunas para `useAdvanceTable`
  // Ajuste conforme a sua necessidade
  const columns = [
    {
      accessorKey: "rowNumber",
      header: "#",
      // Renderiza o índice manualmente
      cell: ({ row }: any) => row.index + 1,
      enableSorting: false,
    },
    {
      accessorKey: "usuario",
      header: "Usuário",
      // Exemplo: define um "cell" custom para chamar handleUserClick
      cell: ({ row }: any) => renderUsuario(row.original, row.index),
    },
    {
      accessorKey: "numeroLancamentos",
      header: "Lançamentos",
    },
    {
      accessorKey: "pesoTotal",
      header: "Peso Total",
    },
    {
      accessorKey: "numeroCondominios",
      header: "Número de Condomínios",
    },
    {
      accessorKey: "agendamentos",
      header: "Agendamentos",
    },
  ];

  // -----------------------------------------------------
  //  useAdvanceTable
  // -----------------------------------------------------
  const table = useAdvanceTable<UsuarioData>({
    data: allData,
    columns,
    pageSize: 1000, // Exemplo: 10 linhas por página
    pagination: true,
    sortable: true,
    selection: false, // Se quiser habilitar seleção de linhas, mude para true
    initialState: {
      // Exemplo: ordena inicialmente por 'pesoTotal' (desc)
      sorting: [{ id: "pesoTotal", desc: true }],
    },
  });

  // -----------------------------------------------------
  //  Configuração do Gráfico Echarts (Doughnut)
  // -----------------------------------------------------
  const getChartOption = () => {
    const data = chartLabels.map((label, index) => ({
      value: chartValues[index],
      name: label,
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: false, // Se quiser exibir a legenda, mude para true
      },
      series: [
        {
          name: "Lançamentos por Usuário",
          type: "pie",
          radius: ["40%", "70%"], // Controla o "furo" do doughnut
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "14",
            },
          },
          data,
        },
      ],
    };
  };

  // -----------------------------------------------------
  //  Renderização final
  // -----------------------------------------------------
  return (
    <div className="container-fluid">
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "20vh", gap: "20px" }}
        >
          <CircularProgress />
          <span>Aguarde, os dados estão sendo carregados...</span>
        </div>
      ) : (
        <div className="row">
          {/* Tabela Avançada */}
          <div className="col-md-6">
            <AdvanceTableProvider {...table}>
              <div
                className="mx-n4 px-4 mx-lg-n4 px-lg-3 bg-white border-top border-bottom border-100 position-relative top-1"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                <AdvanceTable
                  tableProps={{
                    className: "phoenix-table fs-9 table-hover",
                  }}
                />
              </div>
            </AdvanceTableProvider>
          </div>

          {/* Gráfico */}
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            {chartValues.length > 0 && chartLabels.length > 0 && (
              <ReactECharts
                option={getChartOption()}
                style={{
                  width: "100%", // Ocupa toda a largura disponível do contêiner
                  maxWidth: "1200px", // Tamanho máximo do gráfico, ajustável
                  height: "600px", // Aumenta a altura do gráfico
                }}
              />
            )}
          </div>
        </div>
      )}

      {/* Modal de Detalhes */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "#fff",
            border: "1px solid #000",
            boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
            padding: "16px",
            overflowY: "auto",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Detalhes dos Lançamentos - {selectedUser}
          </Typography>
          {/* Se quiser manter a mesma abordagem de tabela MUI aqui, tudo bem */}
          <div style={{ marginTop: "16px" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="text-center">Condomínio</th>
                  <th className="text-center">Lançamentos</th>
                  <th className="text-center">Percentual</th>
                  <th className="text-center">Peso Financeiro</th>
                </tr>
              </thead>
              <tbody>
                {modalData.map((detail, index) => (
                  <tr key={index}>
                    <td className="text-center">{detail.nomeCondominio}</td>
                    <td className="text-center">{detail.numeroLancamentos}</td>
                    <td className="text-center">{detail.percentual}</td>
                    <td className="text-center">{detail.peso}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LancamentosUsuario;
