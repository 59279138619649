import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import useAuth from "../hooks/useAuth";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const auth = useAuth();
  const [user, setUserState] = useState(auth.user);

  // Função para sincronizar o estado do usuário com o localStorage
  const setUser = (newUser) => {
    setUserState(newUser);
    if (newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
    } else {
      localStorage.removeItem("user");
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;

        if (Date.now() >= expirationTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          setUser(null);
        } else {
          const userFromStorage =
            storedUser && storedUser !== "undefined"
              ? JSON.parse(storedUser)
              : decodedToken;
          setUser(userFromStorage);
        }
      } catch (error) {
        console.error(
          "Erro ao processar o token ou usuário armazenado:",
          error,
        );
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
      }
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    if (auth.user && JSON.stringify(auth.user) !== JSON.stringify(user)) {
      setUser(auth.user);
    }
  }, [auth.user]);

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
