/* ***********************************
Autor: Matheus Botelho
Data: 08/04/2024
Atividade: Modal de Sucesso
Função: Padronizar o modal de confirmação
************************************ */

import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmarModal = ({ isOpen, message, onConfirm, onCancel }) => {
  return (
    <Modal show={isOpen} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmarModal;
