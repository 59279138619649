import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Button from "components/base/Button";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import useAdvanceTable from "hooks/useAdvanceTable";
import dayjs from "dayjs";
import { Download } from "feather-icons-react";

interface Encargos {
  atualizacaomonetaria: string;
  juros: string;
  multa: string;
  honorarios: string;
}

interface DadosFiltrados {
  id_recebimento_recb: string;
  dt_vencimento_recb: string;
  vl_emitido_recb: string;
  encargos: Encargos;
}

interface RespostaApi {
  ids_recebimento_recb: string[];
  dadosFiltrados: DadosFiltrados[];
}

interface ModalBoletosAcordosProps {
  show: boolean;
  onHide: () => void;
  idAcordo: string | null;
  idCondominio: number;
  dtInicio: string;
  dtFim: string;
  idUnidade: string;
  REACT_APP_API_URL: string;
  onToast: (message: string, type: "success" | "error") => void;
}

const ModalBoletosAcordos: React.FC<ModalBoletosAcordosProps> = ({
  show,
  onHide,
  idAcordo,
  idCondominio,
  dtInicio,
  dtFim,
  idUnidade,
  REACT_APP_API_URL,
  onToast,
}) => {
  const [dados, setDados] = useState<RespostaApi | null>(null);
  const [loading, setLoading] = useState(false);

  const styles = `
  .action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .action-buttons .icon-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }
  .phoenix-table tbody tr:hover .icon-button {
    opacity: 1 !important;
    visibility: visible;
  }
`;

  const url = useMemo(() => {
    if (!idAcordo) return null;
    return `${REACT_APP_API_URL}/efetuar-acordos/buscar-acordo/${idAcordo}?idCondominio=${idCondominio}&dtInicio=${dtInicio}&dtFim=${dtFim}&idUnidade=${idUnidade}`;
  }, [REACT_APP_API_URL, idAcordo, idCondominio, dtInicio, dtFim, idUnidade]);

  useEffect(() => {
    if (show && url) {
      setLoading(true);
      fetch(url)
        .then((resp) => {
          if (!resp.ok) {
            throw new Error("Erro ao buscar dados de boletos do acordo.");
          }
          return resp.json();
        })
        .then((data: RespostaApi) => {
          setDados(data);
        })
        .catch((err) => {
          console.error("Erro ao buscar dados de boletos do acordo:", err);
          onToast("Erro ao buscar dados de boletos do acordo.", "error");
        })

        .finally(() => setLoading(false));
    }
  }, [show, url]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "dt_vencimento_recb",
        header: "Vencimento",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return value ? dayjs(value).format("DD/MM/YYYY") : "-";
        },
      },
      {
        accessorKey: "id_recebimento_recb",
        header: "Cobrança",
        cell: ({ getValue }: any) => {
          return getValue() || "-";
        },
      },
      {
        accessorKey: "vl_emitido_recb",
        header: "Principal",
        cell: ({ getValue }: any) => {
          const valor = parseFloat(getValue() || 0);
          return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
      },
      // Agora, ajustando para exibir o campo que chega na API:
      {
        accessorKey: "vl_acordomulta_recb",
        header: "Multa",
        cell: ({ getValue }: any) => {
          const valor = parseFloat(getValue() || 0);
          return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
      },
      {
        accessorKey: "vl_acordojuros_recb",
        header: "Juros",
        cell: ({ getValue }: any) => {
          const valor = parseFloat(getValue() || 0);
          return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
      },
      {
        accessorKey: "vl_acordoatualizacao_recb",
        header: "Atualização",
        cell: ({ getValue }: any) => {
          const valor = parseFloat(getValue() || 0);
          return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
      },
      {
        accessorKey: "vl_acordohonorarios_recb",
        header: "Honorários",
        cell: ({ getValue }: any) => {
          const valor = parseFloat(getValue() || 0);
          return valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
        },
      },
      {
        // Aqui calculamos o total (principal + multa + juros + atualização + honorários)
        id: "atualizado",
        header: "Total Atualizado",
        cell: ({ row }: any) => {
          const principal = parseFloat(row.original?.vl_emitido_recb || 0);
          const multa = parseFloat(row.original?.vl_acordomulta_recb || 0);
          const juros = parseFloat(row.original?.vl_acordojuros_recb || 0);
          const atualizacao = parseFloat(
            row.original?.vl_acordoatualizacao_recb || 0,
          );
          const honorarios = parseFloat(
            row.original?.vl_acordohonorarios_recb || 0,
          );

          const totalAtualizado =
            principal + multa + juros + atualizacao + honorarios;

          return totalAtualizado.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          });
        },
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          return (
            <div
              className="action-buttons"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="link"
                className="btn-sm text-primary p-0 m-0 icon-button"
                onClick={() =>
                  handleDownloadBoleto(row.original.id_recebimento_recb)
                }
                title="Baixar Boleto"
              >
                <Download size={16} />
              </Button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const dataTable = dados?.dadosFiltrados || [];
  const tableInstance = useAdvanceTable({
    data: dataTable,
    columns,
    sortable: true,
    pagination: true,
    pageSize: 5,
  });

  // Cálculo do resumo
  const totalPrincipal = dataTable.reduce(
    (acc, boleto) => acc + parseFloat(boleto.vl_emitido_recb || "0"),
    0,
  );
  const totalBoletos = dataTable.length;

  const handleDownloadBoleto = async (idCobranca: string) => {
    try {
      const url = `${REACT_APP_API_URL}/efetuar-acordos/baixar-boleto/${idCobranca}`;
      const response = await fetch(url);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Erro ao buscar o link do boleto.");
      }

      const data = await response.json();

      if (data.link_segundavia) {
        window.open(data.link_segundavia, "_blank");
        onToast("Boleto aberto com sucesso.", "success");
      } else {
        onToast("Nenhum link foi retornado.", "error");
      }
    } catch (error) {
      console.error("Erro ao buscar o link do boleto:", error);
      onToast("Erro ao buscar o link do boleto.", "error");
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <style>{styles}</style>
      <Modal.Header closeButton>
        <Modal.Title>Boletos do Acordo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && dataTable.length > 0 && (
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <p>
              <strong>Número do Acordo:</strong> {idAcordo || "-"}
            </p>
            <p>
              <strong>Total de Cobranças:</strong> {totalBoletos}
            </p>
            <p>
              <strong>Total Principal:</strong>{" "}
              {totalPrincipal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        )}
        {loading && <p>Carregando...</p>}
        {!loading && dataTable.length === 0 && (
          <p className="text-muted text-center">
            Nenhum boleto encontrado para este acordo.
          </p>
        )}
        {!loading && dataTable.length > 0 && (
          <div
            style={{
              maxHeight: "524px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "1rem",
            }}
            className="bg-white border-top border-bottom border-200 position-relative top-1"
          >
            <AdvanceTableProvider {...tableInstance}>
              <AdvanceTable
                tableProps={{
                  className:
                    "phoenix-table fs-9 table-hover text-center align-left mb-0",
                }}
              />
            </AdvanceTableProvider>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBoletosAcordos;
