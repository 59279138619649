import React, { useState } from "react";
import { Card, Dropdown } from "react-bootstrap";
import Scrollbar from "components/base/Scrollbar";
import Button from "components/base/Button";
import NotificationItem from "components/common/NotificationItem";
import classNames from "classnames";
import { Notification } from "data/notifications";

interface NotificationDropdownMenuProps {
  className?: string;
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  markAllAsRead: () => void;
}

const NotificationDropdownMenu: React.FC<NotificationDropdownMenuProps> = ({
  className,
  notifications,
  setNotifications,
  markAllAsRead,
}) => {
  const [visibleNotifications, setVisibleNotifications] = useState(10);

  const handleShowMore = () => {
    setVisibleNotifications((prev) => prev + 10);
  };

  const displayedNotifications = notifications.slice(0, visibleNotifications);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        "navbar-dropdown-caret py-0 notification-dropdown-menu shadow border border-300",
      )}
    >
      <Card className="position-relative border-0">
        <Card.Header className="p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-black mb-0">Notificações</h5>
            <Button
              variant="link"
              className="p-0 fs-9 fw-normal"
              onClick={markAllAsRead}
            >
              Marcar todas como lidas
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-0" style={{ height: "27rem" }}>
          <Scrollbar>
            {notifications.length === 0 ? (
              <div className="p-3 text-center text-muted">
                Sem notificações.
              </div>
            ) : (
              displayedNotifications.map((notification, index) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  type="dropdownItem"
                  className={classNames({
                    "border-bottom":
                      index !== displayedNotifications.length - 1,
                  })}
                  setNotifications={setNotifications}
                />
              ))
            )}
          </Scrollbar>
        </Card.Body>
        <Card.Footer className="p-0 border-top">
          <div className="my-2 text-center">
            {notifications.length > visibleNotifications && (
              <Button
                variant="link"
                className="fw-bold fs-10 text-600"
                onClick={handleShowMore}
              >
                Exibir mais
              </Button>
            )}
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default NotificationDropdownMenu;
