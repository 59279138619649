/* ***********************************
Autor: Matheus Botelho
Data: 07/03/2024
Atividade: Agenda
Função: Criação de visualização para a Agenda do Google Calendar
************************************ */

// SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - INICIO

import React, { useEffect, useState } from "react";
import {
  format,
  parseISO,
  isFuture,
  isToday,
  startOfDay,
  isBefore,
  subDays,
} from "date-fns";
import { ptBR as localePtBR } from "date-fns/locale";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@mui/material/Tooltip";
import PageBreadcrumb from "components/common/PageBreadcrumb";

/**
 * Definições de tipos para eventos e anexos,
 * para maior segurança do TypeScript.
 */
interface IAttachment {
  fileUrl: string;
}

interface IEvent {
  id: string;
  summary?: string;
  description?: string;
  location?: string;
  start: {
    dateTime?: string;
    date?: string;
  };
  end: {
    dateTime?: string;
    date?: string;
  };
  attachments?: IAttachment[];
}

const Agenda2: React.FC = () => {
  const [events, setEvents] = useState<IEvent[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [expandedEventId, setExpandedEventId] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [daysToShow, setDaysToShow] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);

  const API_URL = process.env.REACT_APP_API_URL || "";

  const defaultBreadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Agenda", active: true },
  ];

  // Carrega os eventos quando o componente é montado ou quando daysToShow muda
  useEffect(() => {
    fetchEvents();
  }, [daysToShow]);

  // Atualiza currentTime a cada 5 minutos
  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(new Date()), 300000);
    return () => clearInterval(intervalId);
  }, []);

  // Chama fetchEvents quando searchPerformed muda
  useEffect(() => {
    fetchEvents();
  }, [searchPerformed]);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const url = `${API_URL}/calendar/events?daysAgo=${daysToShow}&search=${encodeURIComponent(
        searchTerm,
      )}`;
      const response = await fetch(url);
      if (!response.ok) throw new Error("Erro ao buscar eventos.");
      const data = await response.json();
      setEvents(data.items || []);
      setSearchPerformed(!!searchTerm);
    } catch (err: any) {
      setError(err.toString());
      setSearchPerformed(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchPerformed) {
      setSearchTerm("");
      setDaysToShow(0);
      setSearchPerformed(false);
    } else {
      setDaysToShow(730);
      setSearchPerformed(true);
    }
  };

  const handlePreviousDay = () => {
    if (!searchTerm.trim()) {
      setDaysToShow((prevDaysToShow) => prevDaysToShow + 1);
    }
  };

  const handleNextDay = () => {
    if (!searchTerm.trim() && daysToShow > 0) {
      setDaysToShow((prevDaysToShow) => prevDaysToShow - 1);
    }
  };

  /**
   * Define o status do evento (passado, futuro ou atual)
   */
  const eventStatus = (
    start: string,
    end: string,
    isAllDayEvent: boolean,
  ): string => {
    const currentDate = startOfDay(new Date());
    const startTime = parseISO(start);
    const eventDate = isAllDayEvent
      ? parseISO(start).setHours(0, 0, 0, 0)
      : startOfDay(startTime);

    if (isFuture(startTime) || isToday(startTime)) return "future";
    if (isBefore(eventDate, currentDate)) return "past";
    return "current";
  };

  /**
   * Agrupa os eventos por dia
   */
  const groupEventsByDay = (eventsData: IEvent[]) => {
    const grouped: Record<string, IEvent[]> = {};
    eventsData.forEach((event) => {
      let dateKey = "";
      if (event.start.dateTime) {
        const date = parseISO(event.start.dateTime);
        dateKey = format(date, "yyyy-MM-dd");
      } else if (event.start.date) {
        const date = parseISO(event.start.date);
        dateKey = format(date, "yyyy-MM-dd");
      }
      if (dateKey) {
        if (!grouped[dateKey]) {
          grouped[dateKey] = [];
        }
        grouped[dateKey].push(event);
      }
    });
    return grouped;
  };

  /**
   * Mostra ou esconde os detalhes de um evento
   */
  const toggleEventDetails = (eventId: string) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  /**
   * Retorna uma classe para o cabeçalho da data (por exemplo, se for hoje ou passado)
   */
  const dateHeaderClass = (date: string) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) {
      return "today";
    } else if (isBefore(parsedDate, startOfDay(new Date()))) {
      return "past";
    }
    return "";
  };

  const navigationDate = format(subDays(new Date(), daysToShow), "dd/MM/yyyy", {
    locale: localePtBR, // Use `localePtBR` aqui
  });

  const groupedEvents = groupEventsByDay(events);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2>Agenda</h2>

      {/* Container de busca */}
      <div className="d-flex align-items-center mb-4" style={{ gap: "10px" }}>
        <input
          type="text"
          placeholder="Digite sua pesquisa"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            flexGrow: 1,
            maxWidth: "200px",
            border: "1px solid #ccc",
            padding: "9px",
            borderRadius: "5px",
            marginTop: "10px",
            fontSize: "12px",
          }}
        />
        <button
          onClick={handleSearch}
          className="btn"
          style={{
            whiteSpace: "nowrap",
            backgroundColor: "#343434",
            color: "#fff",
            fontSize: "12px",
            fontWeight: 600,
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          {searchPerformed ? "Limpar Busca" : "Buscar"}
        </button>
      </div>

      {/* Navegação de dias */}
      <div
        className="d-flex align-items-center mb-3"
        style={{ justifyContent: "start" }}
      >
        <div
          onClick={handlePreviousDay}
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "50%",
            padding: "8px",
            transition: "0.2s",
            marginRight: "10px",
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: "10px", color: "#000" }} />
        </div>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {navigationDate}
        </span>
        <div
          onClick={handleNextDay}
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "50%",
            padding: "8px",
            transition: "0.2s",
            marginLeft: "10px",
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: "10px", color: "#000" }} />
        </div>
      </div>

      {/* Status de carregamento e erros */}
      {loading && (
        <p className="text-primary" style={{ fontWeight: "bold" }}>
          Carregando eventos...
        </p>
      )}
      {error && (
        <p className="text-danger" style={{ fontWeight: "bold" }}>
          Erro: {error}
        </p>
      )}

      {/* Listagem de eventos */}
      <div>
        {Object.entries(groupedEvents).length > 0 ? (
          Object.entries(groupedEvents).map(([date, eventsOnDate]) => (
            <div key={date} className="mb-4">
              {/* Cabeçalho da data */}
              <div
                className={`p-2 mb-2 ${
                  dateHeaderClass(date) === "today" ? "fw-bold" : ""
                }`}
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dateHeaderClass(date) === "today"
                      ? "#22aad373"
                      : dateHeaderClass(date) === "past"
                        ? "#49494979"
                        : "#595959",
                  color:
                    dateHeaderClass(date) === "past"
                      ? "#fff"
                      : dateHeaderClass(date) === "today"
                        ? "#343434"
                        : "#ffffff",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                {format(parseISO(date), "dd 'de' MMMM', 'EEEE", {
                  locale: localePtBR, // Use `localePtBR` aqui também
                })}
              </div>

              {/* Lista de eventos */}
              <ul className="list-unstyled">
                {eventsOnDate.map((event) => {
                  const isAllDayEvent =
                    !!event.start.date && !event.start.dateTime;

                  // Define estilos condicionalmente
                  const baseStyle = {
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                    transition: "transform 0.3s ease",
                    backgroundColor: "#fff",
                  };

                  const conditionalStyle =
                    eventStatus(
                      event.start.dateTime || event.start.date || "",
                      event.end.dateTime || event.end.date || "",
                      isAllDayEvent,
                    ) === "past"
                      ? { backgroundColor: "#bebebe4d" }
                      : eventStatus(
                            event.start.dateTime || event.start.date || "",
                            event.end.dateTime || event.end.date || "",
                            isAllDayEvent,
                          ) === "current"
                        ? { backgroundColor: "#1a73e85f", color: "#fff" }
                        : {};

                  // Combina os estilos base e condicional
                  const combinedStyle = { ...baseStyle, ...conditionalStyle };

                  return (
                    <li
                      key={event.id}
                      onClick={() => toggleEventDetails(event.id)}
                      style={combinedStyle} // Apenas um atributo style
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.02)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                      className={
                        eventStatus(
                          event.start.dateTime || event.start.date || "",
                          event.end.dateTime || event.end.date || "",
                          isAllDayEvent,
                        ) === "past"
                          ? "mb-2"
                          : eventStatus(
                                event.start.dateTime || event.start.date || "",
                                event.end.dateTime || event.end.date || "",
                                isAllDayEvent,
                              ) === "current"
                            ? "text-white"
                            : ""
                      }
                    >
                      {/* Horário do evento */}
                      <div style={{ marginRight: "30px", width: "50px" }}>
                        <span
                          style={{
                            color: "#1a73e8",
                            fontWeight: "bold",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {event.start.dateTime
                            ? format(parseISO(event.start.dateTime), "HH:mm")
                            : isAllDayEvent
                              ? "Todo o dia"
                              : "Horário não informado"}
                        </span>
                      </div>

                      {/* Conteúdo do evento */}
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <strong
                            style={{
                              color: "#222",
                              fontWeight: 600,
                              fontSize: "14px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              display: "block",
                            }}
                          >
                            {event.summary}
                          </strong>

                          {/* Detalhes expandidos do evento */}
                          {expandedEventId === event.id && (
                            <div
                              style={{
                                marginTop: "5px",
                                fontSize: "12px",
                                color: "#343434",
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: event.description || "",
                                }}
                                style={{ marginBottom: "0" }}
                              />
                              {event.location && (
                                <p
                                  style={{
                                    marginTop: "12px",
                                    fontWeight: 900,
                                    color: "#000",
                                  }}
                                >
                                  Local: {event.location}
                                </p>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Botão de anexo(s) */}
                        {event.attachments &&
                          event.attachments.length > 0 &&
                          event.attachments.map((attachment, index) => (
                            <Tooltip title="Ver ata de Reunião" key={index}>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(attachment.fileUrl, "_blank");
                                }}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "auto",
                                  padding: "5px",
                                  border: "none",
                                  borderRadius: "5px",
                                  backgroundColor: "#0e94ff",
                                  color: "#ffffff",
                                }}
                              >
                                <AttachFileIcon />
                              </button>
                            </Tooltip>
                          ))}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))
        ) : !loading ? (
          <p>Nenhum evento encontrado ou aguardando autenticação.</p>
        ) : null}
      </div>
    </div>
  );
};

export default Agenda2;

// SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - FIM
