// EnviarEmailModal.js

import React from "react";
import { Modal, Button } from "react-bootstrap";

const EnviarEmailModal = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Modal show={isOpen} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enviar Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Deseja enviar o email de confirmação?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Não
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EnviarEmailModal;
