/* ***********************************
Autor: Matheus Botelho
Data: 07/03/2024
Atividade: Agenda
Função: Criação de visualização para a Agenda do Google Calendar
************************************ */

import React, { useEffect, useState } from "react";
import {
  format,
  parseISO,
  isFuture,
  isToday,
  startOfDay,
  isBefore,
  subDays,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import "./Agenda.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Tooltip from "@mui/material/Tooltip";

function Agenda() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [daysToShow, setDaysToShow] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setLoading(true);
    const intervalId = setInterval(() => setCurrentTime(new Date()), 300000);

    // INICIO DA ALTERAÇÃO
    let effectiveDaysToShow = searchString.trim() ? 730 : daysToShow;

    const url = `${API_URL}/calendar/events?daysAgo=${effectiveDaysToShow}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data.items || []);
        setLoading(false);
      })
      .catch((error) => {
        setError("Erro ao buscar eventos: " + error.message);
        setLoading(false);
      });

    return () => clearInterval(intervalId);
  }, [API_URL, daysToShow, searchString, currentTime]);

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value.trim()) {
      // Ao pesquisar, retrocede a visualização para 2 anos atrás
      setDaysToShow(730);
    } else {
      // Reseta a visualização para hoje
      setDaysToShow(0);
    }
  };

  //FIM DA ALTERAÇÃO - 16/04/2024 - ADICIONAR FILTRO DE PESQUISA NA AGENDA

  const handlePreviousDay = () => {
    setDaysToShow(daysToShow + 1);
  };

  const handleNextDay = () => {
    setDaysToShow(daysToShow - 1 >= 0 ? daysToShow - 1 : 0);
  };

  const eventStatus = (start, end, isAllDayEvent) => {
    const currentDate = startOfDay(new Date()); // Começo do dia atual para comparação
    const startTime = parseISO(start);
    const eventDate = isAllDayEvent
      ? parseISO(start).setHours(0, 0, 0, 0)
      : startOfDay(startTime);

    // Para eventos de dia inteiro ou eventos específicos, compara se estão no passado
    if (isFuture(startTime) || isToday(startTime)) return "future";
    if (isBefore(eventDate, currentDate)) return "past"; // Usa isBefore para verificar se a data do evento é antes da data atual
    return "current";
  };

  const groupEventsByDay = (events) => {
    const grouped = {};
    events.forEach((event) => {
      let dateKey;
      if (event.start.dateTime) {
        const date = parseISO(event.start.dateTime);
        dateKey = format(date, "yyyy-MM-dd");
      } else if (event.start.date) {
        const date = parseISO(event.start.date);
        dateKey = format(date, "yyyy-MM-dd");
      }

      if (dateKey) {
        if (!grouped[dateKey]) {
          grouped[dateKey] = [];
        }
        grouped[dateKey].push(event);
      }
    });
    return grouped;
  };

  const toggleEventDetails = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  const dateHeaderClass = (date) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) {
      return "today";
    } else if (isBefore(parsedDate, startOfDay(new Date()))) {
      return "past";
    }
    return ""; // Retorna uma string vazia para dias próximos ou futuros
  };

  const navigationDate = format(subDays(new Date(), daysToShow), "dd/MM/yyyy", {
    locale: ptBR,
  });

  const filteredEvents =
    searchString.trim() === ""
      ? events
      : events.filter((event) =>
          event.summary.toLowerCase().includes(searchString.toLowerCase())
        );

  const groupedEvents = groupEventsByDay(filteredEvents);

  return (
    <div className="agenda-container">
      <h2 className="agenda-title">Agenda</h2>
      <div className="search-container">
        <SearchRoundedIcon className="search-icon-agenda" />
        <input
          type="text"
          placeholder="Pesquisar"
          value={searchString}
          onChange={handleSearchChange}
          className="search-input-agenda"
        />
      </div>

      <div className="navigation-days">
        <div className="navigation-arrow-wrapper" onClick={handlePreviousDay}>
          <ArrowBackIosIcon className="navigation-arrow" />
        </div>
        <span className="navigation-date">{navigationDate}</span>
        <div className="navigation-arrow-wrapper" onClick={handleNextDay}>
          <ArrowForwardIosIcon className="navigation-arrow" />
        </div>
      </div>
      {loading && <p className="loading-message">Carregando eventos...</p>}
      {error && <p className="error-message">Erro: {error}</p>}
      <div className="events-container">
        {Object.entries(groupedEvents).length > 0 ? (
          Object.entries(groupedEvents).map(([date, events]) => (
            <div key={date} className="date-group">
              <div className={`date-header ${dateHeaderClass(date)}`}>
                {format(parseISO(date), "dd 'de' MMMM', 'EEEE", {
                  locale: ptBR,
                })}
              </div>
              <ul className="event-list">
                {events.map((event) => (
                  <li
                    key={event.id}
                    className={`event-item ${eventStatus(
                      event.start.dateTime || event.start.date,
                      event.end.dateTime || event.end.date,
                      !!event.start.date && !event.start.dateTime
                    )}`}
                    onClick={() => toggleEventDetails(event.id)}
                  >
                    <div className="event-time-date">
                      <span className="event-time">
                        {event.start.dateTime
                          ? format(parseISO(event.start.dateTime), "HH:mm")
                          : event.start.date
                          ? "Todo o dia"
                          : "Horário não informado"}
                      </span>
                    </div>
                    <div className="event-summary-details">
                      <div className="event-details">
                        <strong className="event-summary">
                          {event.summary}
                        </strong>
                        {expandedEventId === event.id && (
                          <div className="event-expanded-details">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.description,
                              }}
                            />
                            <p className="event-place">
                              Local: {event.location}
                            </p>
                          </div>
                        )}
                      </div>
                      {/* Mover o botão de anexo para fora do bloco condicional */}
                      {event.attachments &&
                        event.attachments.length > 0 &&
                        event.attachments.map((attachment, index) => (
                          <Tooltip title="Ver ata de Reunião" key={index}>
                            <button
                              className="attachment-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Impede que o clique no botão também acione o toggleEventDetails
                                window.open(attachment.fileUrl, "_blank");
                              }}
                            >
                              <AttachFileIcon />
                            </button>
                          </Tooltip>
                        ))}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : !loading ? (
          <p className="no-events-message">
            Nenhum evento encontrado ou aguardando autenticação.
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default Agenda;
