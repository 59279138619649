//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
import React, { useState } from "react";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import VisaoGeralTable from "./table/VisaoGeralTable";
import CondominioTable from "./table/CondominioTable";
import UsuarioTable from "./table/UsuarioTable";

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Fechamento Mensal", active: true },
];

const FechamentoMensal: React.FC = () => {
  const [view, setView] = useState<string>("visaoGeral");

  const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setView(event.target.value);
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <div className="d-flex align-items-center justify-content-between mb-4">
        <h2 className="mb-0">Fechamento Mensal</h2>
        <select
          className="form-select"
          style={{
            width: "200px",
            fontSize: "1.15rem",
            padding: "0.5rem 1rem",
          }}
          value={view}
          onChange={handleViewChange}
        >
          <option value="visaoGeral">Visão Geral</option>
          <option value="porCondominio">Por Condomínio</option>
          <option value="porUsuario">Por Usuário</option>
        </select>
      </div>

      {/* Renderiza a tabela baseada na seleção */}
      <div>
        {view === "visaoGeral" && <VisaoGeralTable />}
        {view === "porCondominio" && <CondominioTable />}
        {view === "porUsuario" && <UsuarioTable />}
      </div>
    </div>
  );
};

export default FechamentoMensal;
