import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faScrewdriverWrench,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export interface TimelineData {
  oppositeContent: {
    date: string;
    time: string;
  };
  content: {
    title: string;
    subtitle: string;
  };
  separator: {
    icon: IconProp;
    dotClass: string;
    barClass?: string;
  };
}

export interface TimelineItem {
  id: number;
  time: string;
  icon: IconProp;
  iconColor: string;
  title: string;
  content?: string;
  tasker: string;
  file?: string;
}

export interface Timeline {
  id: number;
  date: string;
  items: TimelineItem[];
}

const API_URL = process.env.REACT_APP_API_URL || "";

export const fetchTimelineData = async (): Promise<Timeline[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/atualizacoes/buscar-atualizacoes`,
    );
    const updates = response.data;

    const timelineData: Timeline[] = updates.map((update: any) => ({
      id: update.id,
      date: `${update.versao} - ${update.titulo}`,
      items: [
        {
          id: update.id * 10 + 1,
          time: new Date(update.criado_em).toLocaleDateString("pt-BR"), // Formata a data
          icon: faRocket,
          iconColor: "info",
          title: "Implementações",
          content: update.implementacoes,
          tasker: "EURO TI",
        },
        {
          id: update.id * 10 + 2,
          time: new Date(update.criado_em).toLocaleDateString("pt-BR"), // Formata a data
          icon: faScrewdriverWrench,
          iconColor: "warning",
          title: "Correções",
          content: update.correcoes,
          tasker: "EURO TI",
        },
        {
          id: update.id * 10 + 3,
          time: new Date(update.criado_em).toLocaleDateString("pt-BR"), // Formata a data
          icon: faCheck,
          iconColor: "success",
          title: "Melhorias",
          content: update.melhorias,
          tasker: "EURO TI",
        },
      ].filter((item) => item.content), // Remove items without content
    }));

    return timelineData;
  } catch (error) {
    console.error("Error fetching timeline data:", error);
    return [];
  }
};
