// SSI 0094 - GCOLLE - Pagina de Eventos, Ícone usuário. Pagina de Notificações corerção socket. - 17/01/2025
import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import Button from "components/base/Button";
import PageBreadcrumb, {
  PageBreadcrumbItem,
} from "components/common/PageBreadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import AdvanceTable from "components/base/AdvanceTable";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Trash2 } from "feather-icons-react";

export const timelineBreadcrumb: PageBreadcrumbItem[] = [
  { label: "Home", link: "/home" },
  { label: "Eventos", active: true },
];

interface Evento {
  id: string;
  titulo: string;
  createdAt: string;
}

const Eventos = () => {
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [show, setShow] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [imagem, setImagem] = useState<File | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Evento | null>(null);
  const [socket, setSocket] = useState<any>(null);

  const API_URL = process.env.REACT_APP_API_URL;
  const ORIGIN_API = process.env.ORIGIN_API || "http://localhost:3000";

  const styles = `
  .action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .action-buttons .icon-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }
  .phoenix-table tbody tr:hover .icon-button {
    opacity: 1 !important;
    visibility: visible;
  }
`;

  useEffect(() => {
    const newSocket = io(ORIGIN_API);
    setSocket(newSocket);

    // Listener para novos eventos
    newSocket.on("eventCreated", (newEvent: Evento) => {
      setEventos((prevEventos) => [newEvent, ...prevEventos]);
    });

    return () => {
      newSocket.close();
    };
  }, [ORIGIN_API]);

  const fetchEventos = async () => {
    try {
      const response = await axios.get(`${API_URL}/eventos/buscar`);
      setEventos(response.data.eventos);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }
  };

  useEffect(() => {
    fetchEventos();
  }, [API_URL]);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setTitulo("");
    setDescricao("");
    setImagem(null);
  };

  const sendInfoToServer = async (
    userIdsToSend: string[],
    eventId: string,
    eventTitle: string,
  ) => {
    if (!socket) {
      console.error("Socket não inicializado");
      toast.error("Erro: conexão com socket não encontrada.");
      return;
    }

    try {
      socket.emit("sendEventNotification", {
        userIds: userIdsToSend,
        eventId,
        eventTitle,
      });

      toast.success("Notificação enviada com sucesso!");
    } catch (error) {
      console.error("Erro ao enviar notificação:", error);
      toast.error("Erro ao enviar notificação.");
    }
  };

  const handleCreateEvento = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!imagem) {
      toast.error("Por favor, selecione uma imagem.");
      return;
    }

    const formData = new FormData();
    formData.append("titulo", titulo);
    formData.append("descricao", descricao);
    formData.append("imagem", imagem);

    try {
      const response = await axios.post(`${API_URL}/eventos/criar`, formData);
      const newEventId = response.data.eventId; // Supondo que o backend retorna o eventId
      toast.success("Evento criado com sucesso!");
      fetchEventos();
      handleClose();

      const userResponse = await axios.get(
        `${API_URL}/criar-notificacao/obterTodosUsuarios`,
      );
      const userIdsToSend = userResponse.data.filter((id: any) => id !== null);

      if (userIdsToSend.length === 0) {
        console.warn("Nenhum ID de usuário foi retornado.");
        toast.error("Erro: nenhum usuário encontrado para notificação.");
        return;
      }

      socket.emit("sendEventNotification", {
        userIds: userIdsToSend,
        eventId: newEventId,
        eventTitle: titulo,
      });

      toast.success("Notificações enviadas com sucesso!");
    } catch (error) {
      console.error("Erro ao criar evento ou enviar notificação:", error);
      toast.error("Erro ao criar evento ou enviar notificação.");
    }
  };

  const handleShowConfirmDelete = (evento: Evento) => {
    setSelectedEvent(evento);
    setShowConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false);
    setSelectedEvent(null);
  };

  const handleDeleteEvent = async (eventoId: string) => {
    try {
      await axios.delete(`${API_URL}/eventos/${eventoId}`);
      toast.success("Evento deletado com sucesso!");
      fetchEventos();
    } catch (error) {
      console.error("Erro ao deletar evento:", error);
      toast.error("Erro ao deletar evento.");
    } finally {
      handleCloseConfirmDelete();
    }
  };

  const table = useAdvanceTable({
    data: eventos,
    columns: [
      {
        accessorKey: "titulo",
        header: "Título",
      },
      {
        accessorKey: "createdAt",
        header: "Data",
        cell: ({ getValue }) =>
          new Date(getValue() as string).toLocaleDateString("pt-BR"),
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          const evento: Evento = row.original;
          return (
            <div
              className="action-buttons"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="link"
                className="btn-sm text-danger p-0 m-0 icon-button"
                onClick={() => handleShowConfirmDelete(evento)}
                title="Deletar"
              >
                <Trash2 size={16} />
              </Button>
            </div>
          );
        },
      },
    ],
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (
    <div>
      <style>{styles}</style>
      <ToastContainer />
      <PageBreadcrumb items={timelineBreadcrumb} />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Eventos</h2>
        <Button variant="primary" onClick={handleShow}>
          Criar Evento
        </Button>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{
              className:
                "phoenix-table fs-9 table-hover text-center align-left",
            }}
          />
        </div>
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateEvento}>
            <Form.Group className="mb-3" controlId="formTitulo">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescricao">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Digite a descrição"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formImagem">
              <Form.Label>Imagem</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setImagem((e.target as HTMLInputElement).files?.[0] || null)
                }
                required
              />
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                onClick={async () => {
                  try {
                    if (!imagem) {
                      toast.error("Por favor, selecione uma imagem.");
                      return;
                    }

                    const formData = new FormData();
                    formData.append("titulo", titulo);
                    formData.append("descricao", descricao);
                    formData.append("imagem", imagem);

                    // Criação do evento
                    const response = await axios.post(
                      `${API_URL}/eventos/criar`,
                      formData,
                    );
                    const newEventId = response.data.eventId;
                    toast.success("Evento criado com sucesso!");

                    // Atualiza lista de eventos
                    fetchEventos();
                    handleClose();

                    // Obtém IDs de usuários
                    const userResponse = await axios.get(
                      `${API_URL}/criar-notificacao/obterTodosUsuarios`,
                    );
                    const userIdsToSend = userResponse.data.filter(
                      (id: any) => id !== null,
                    );

                    if (userIdsToSend.length === 0) {
                      console.warn("Nenhum ID de usuário foi retornado.");
                      toast.error(
                        "Erro: nenhum usuário encontrado para notificação.",
                      );
                      return;
                    }

                    // Envia notificação
                    await sendInfoToServer(userIdsToSend, newEventId, titulo);
                  } catch (error) {
                    console.error(
                      "Erro ao criar evento ou enviar notificação:",
                      error,
                    );
                    toast.error("Erro ao criar evento ou enviar notificação.");
                  }
                }}
              >
                Criar Evento
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal para Confirmar Exclusão */}
      <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja{" "}
          <strong style={{ color: "#ef4444" }}>DELETAR</strong> o evento{" "}
          <strong>{selectedEvent?.titulo}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmDelete}>
            Cancelar
          </Button>
          {selectedEvent && (
            <Button
              variant="danger"
              onClick={() => handleDeleteEvent(selectedEvent.id)}
            >
              Deletar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Eventos;
