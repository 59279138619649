import React, { useEffect, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import axios from "axios";
import { ptBR } from "date-fns/locale";
import { parse, format } from "date-fns";

// Registrar os componentes do ECharts
echarts.use([
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LineChart,
  CanvasRenderer,
]);

interface DataItem {
  month: string;
  total: number;
}

const GraficoCondominios: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        const response = await axios.get(
          `${API_URL}/dados-dashboard/buscar-total-condominios`,
        );
        if (response.data && Array.isArray(response.data)) {
          setData(
            response.data.map(
              (item: { periodo: string; total_condominios: number }) => ({
                month: format(
                  parse(item.periodo, "yyyy-MM", new Date()),
                  "MMM/yy",
                  { locale: ptBR },
                ),
                total: item.total_condominios,
              }),
            ),
          );
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, []);

  const dates = data.map((d) => d.month);
  const currentMonthData = data.map((d) => d.total);

  // Calcular a média dos valores
  const average =
    currentMonthData.reduce((acc, curr) => acc + curr, 0) /
    (currentMonthData.length || 1);

  // Calcular o valor mínimo e arredondar para baixo para o múltiplo de 10 mais próximo
  const minValue = Math.min(...currentMonthData);
  const yAxisMin = Math.floor(minValue / 10) * 10;

  const getDefaultOptions = () => ({
    title: {
      text: "",
      left: "center",
      top: 0,
      textStyle: {
        color: "#343a40",
        fontSize: 14,
        fontWeight: 600,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "#999",
          width: 1,
        },
      },
      backgroundColor: "#ffffff",
      borderColor: "#dee2e6",
      textStyle: { color: "#343a40" },
      borderWidth: 1,
      padding: 10,
      formatter: (params: any) => {
        const param = params[0];
        return `
          <strong>${param.axisValue}</strong><br/>
          ${param.marker} ${param.seriesName}: ${param.data}
        `;
      },
    },
    xAxis: {
      type: "category",
      data: dates,
      boundaryGap: false,
      axisLabel: {
        color: "#6c757d",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: 12.8,
      },
      axisLine: {
        lineStyle: {
          color: "#dee2e6",
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      min: yAxisMin, // Iniciar o eixo Y no múltiplo de 10 abaixo do menor valor
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        color: "#6c757d",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: 12.8,
        formatter: (value: number) => `${value}`,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#e9ecef",
        },
      },
    },
    series: [
      {
        name: "Total de Condomínios",
        type: "line",
        data: currentMonthData,
        smooth: false, // Desabilitar suavização para linhas retas
        showSymbol: false, // Ocultar símbolos por padrão
        lineStyle: {
          color: "#238ee6",
          width: 2,
        },
        itemStyle: {
          color: "#238ee6",
        },
        label: {
          show: true,
          position: "top",
          color: "#238ee6",
          fontWeight: 600,
          formatter: "{c}",
        },
        emphasis: {
          focus: "series",
          label: {
            show: true,
            position: "top",
            color: "#238ee6",
            fontWeight: 600,
            formatter: "{c}",
          },
        },
      },
    ],

    grid: {
      right: "2%",
      left: "0%",
      bottom: "0px",
      top: "30px",
      containLabel: true,
    },
    legend: {
      show: false, // Ocultar legenda para um visual mais clean
    },
    animation: false, // Desativar animações para um visual mais clean e rápido
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions()}
      style={{ height: "380px", width: "100%" }}
    />
  );
};

export default GraficoCondominios;
