import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Dropdown } from "react-bootstrap";
import Avatar from "components/base/Avatar";
import RevealDropdown from "components/base/RevealDropdown";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Notification } from "data/notifications";
import { format } from "date-fns";

interface NotificationItemProps {
  notification: Notification;
  className?: string;
  type: "dropdownItem" | "pageItem";
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  className,
  type,
  setNotifications,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const markAsRead = async () => {
    try {
      const response = await fetch(
        `${API_URL}/notificacoes/lida/${notification.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ lida: !notification.lida ? 1 : 0 }),
        },
      );

      if (response.ok) {
        setNotifications((prev) =>
          prev.map((n) =>
            n.id === notification.id ? { ...n, lida: !n.lida } : n,
          ),
        );
      } else {
        console.error("Erro ao atualizar a notificação:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao atualizar a notificação:", error);
    }
  };

  const handleDownload = async () => {
    if (!notification.link_anexo) return;

    try {
      const response = await fetch(
        `${API_URL}/assembleia/download-file-url/${notification.id}`,
      );
      const data = await response.json();

      if (response.ok && data.success) {
        const link = document.createElement("a");
        link.href = data.fileUrl;
        link.download = notification.nome_arquivo || "downloaded_file";
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Erro ao obter o link do arquivo:", data.message);
      }
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };

  // Formatação da data e hora
  const formattedTime = format(new Date(notification.data_recebida), "HH:mm");
  const formattedDate = format(
    new Date(notification.data_recebida),
    "dd/MM/yyyy",
  );

  return (
    <div
      className={classNames(
        className,
        "py-3 border-300 notification-card position-relative",
        {
          "bg-light": !notification.lida, // Fundo claro para notificações não lidas
          "px-4 px-lg-6": type === "pageItem",
          "px-2 px-sm-3": type === "dropdownItem",
        },
      )}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-start">
          <Avatar
            src={notification.avatar}
            placeholder={!notification.avatar}
            size={type === "pageItem" ? "xl" : "m"}
            className="me-3"
          />
          <div
            className={classNames("flex-1", {
              "me-sm-3": type === "dropdownItem",
              "mt-2 me-2": type === "pageItem",
            })}
          >
            <h5 className="mb-1 text-dark text-uppercase">
              {notification.event_link ? (
                <a
                  href={notification.event_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  {notification.titulo}
                </a>
              ) : (
                notification.titulo
              )}
            </h5>

            <p className="fs-9 text-1000 mb-2 mb-sm-3">
              {notification.mensagem}
            </p>
            <p className="fs-9 text-muted mb-0 d-flex align-items-center w-100">
              <FontAwesomeIcon icon={faClock} className="me-2" />
              <span className="fw-bold me-3">{formattedTime}</span>
              <span className="ms-2 ms-sm-12 text-muted">{formattedDate}</span>
            </p>
          </div>
        </div>
        <RevealDropdown
          className={classNames("", {
            // Removida a classe "d-none d-sm-inline-block" para que o dropdown seja sempre exibido
            "": type === "dropdownItem",
          })}
          btnClassName="notification-dropdown-toggle"
          dropdownMenuClassName="mt-2"
        >
          <Dropdown.Item onClick={markAsRead}>
            Marcar como {notification.lida ? "não lida" : "lida"}
          </Dropdown.Item>
        </RevealDropdown>
      </div>
      {/* Botão de download, se aplicável */}
      {notification.link_anexo && (
        <div
          className="position-absolute top-0 end-0 m-2"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation(); // Evita que o clique no ícone acione outras ações
            handleDownload();
          }}
        >
          <CloudDownloadIcon
            className="text-primary"
            style={{ cursor: "pointer" }}
            aria-label="Baixar Anexo"
          />
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
