import MainLayout from "layouts/MainLayout";
import { RouteObject, createBrowserRouter, Navigate } from "react-router-dom";
import MainLayoutProvider from "providers/MainLayoutProvider";
import Error404 from "pages/error/Error404";
import App from "App";
import SimpleSignIn from "pages/authentication/simple/SignIn";
import ComingSoon from "pages/ComingSoon";
import RotasComPermissao from "./RotasComPermissao"; // Certifique-se de que o caminho está correto
import { useContext } from "react";
import UserContext from "./components/UserContext";

// Importações das páginas/componentes
import RateioPage from "./pages/financeiro/rateio/RateioPage";
import AlterarTitularidade from "./components/Cadastro/AlterarTitularidade";
import IndicadorFinanceiro from "./components/Indicadores/IndicadorFinanceiro";
import DashboardInadimplencia from "./components/DashboardInadimplencia";
import KpisPageFin from "./components/Indicadores/KpisPageFin";
import Agenda from "./components/Agenda";
import CriarSalarios from "./pages/rh/CriarSalarios";
import DashboardProject from "./pages/dashboard/DashboardProject";
import AlterarTitularidade2 from "./pages/cadastro/AlterarTitularidade2";
import ListarEventos from "./pages/atualizacoes/eventos/ListarEventos"; //SSI 0019 - 27/05/24
import CriarEvento from "./pages/atualizacoes/updates/CriarEventosEureka"; //SSI 0019 - 27/05/24
import Timeline from "./pages/atualizacoes/atualizacao/Timeline";
import FechamentoMensal from "./pages/financeiro/fechamento-mensal/FechamentoMensal"; //SSI 0078 - 20/12/24
import EditarCarteira from "./pages/geral/carteiras/EditarCarteira"; // SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import ConsultarCarteira from "./pages/geral/carteiras/ConsultarCarteira"; // SSI 0083 - GCOLLE - 09/01/2025
import RedirecionarCarteira from "./pages/geral/carteiras/RedirecionarCarteira"; // SSI 0083 - GCOLLE - 09/01/2025
import TransferenciaCondominio from "./pages/geral/carteiras/TransferenciaCondominio"; // SSI 0083 - GCOLLE - 09/01/2025
import CarteiraUsuario from "./pages/geral/carteiras/CarteiraUsuario"; // SSI 0083 - GCOLLE - 09/01/2025
import MinhaCarteira from "./pages/geral/carteiras/MinhaCarteira"; // SSI 0083 - GCOLLE - 09/01/2025
import EditarUsuarioCidade from "./pages/geral/carteiras/EditarUsuarioCidade/EditarUsuarioCidade"; // SSI 0083 - GCOLLE - 09/01/2025
import Geral from "./pages/informacoes/Geral"; //SSI 0078 - MATHEUS - 20/12/24 // SSI 0085 - GCOLLE - 10/01/2025
import Ausencia from "./pages/informacoes/Ausencia"; // SSI 0085 - GCOLLE - 10/01/2025
import InativarUsuario from "./pages/informacoes/InativarUsuario"; // SSI 0085 - GCOLLE - 10/01/2025
import Agenda2 from "./pages/agenda/Agenda"; // SSI 0087 - MATHEUS BOTELHO - 13/01/2025
import CriarNotificacao from "pages/notificacoes/CriarNotificacao"; // SSI 0095 - GCOLLE - 17/01/2025
import PaginaLancamentos from "pages/indicadores/lancamentosfinanceiro/PaginaLancamentos"; // SSI 0088 - MATHEUS BOTELHO - 13/01/2025
import DashboardInadimplencia2 from "pages/indicadores/inadimplencia/DashboardInadimplencia"; // SSI 0089 - MATHEUS BOTELHO - 14/01/2025
import Inadimplencia from "pages/juridico/inadimplencia/Inadimplencia";
import EfetuarAcordo from "pages/juridico/acordos/EfetuarAcordos";
import Assembleia from "pages/assembleias/Assembleia"; //atividade: Assembleias / data: 01/04/2024

// Áreas disponíveis
const ALL_AREAS = [
  "Financeiro",
  "RH",
  "Cadastro",
  "Seguros",
  "Jurídico",
  "Diretoria",
  "Assembleias",
  "Cobranças",
];

// Exemplo de rota privada, redirecionando para login caso não logado
const PrivateRoute = ({ element }: { element: React.ReactNode }) => {
  const { user } = useContext(UserContext);
  return user ? element : <Navigate to="/login" />;
};

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: "/login",
        element: <SimpleSignIn />,
      },
      {
        path: "/",
        element: (
          <PrivateRoute
            element={
              <MainLayoutProvider>
                <MainLayout />
              </MainLayoutProvider>
            }
          />
        ),
        children: [
          {
            index: true,
            element: <DashboardProject />,
          },
          {
            path: "pages",
            children: [
              {
                path: "assembleias",
                children: [
                  {
                    path: "agenda-assembleia",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin", "Gestor", "Agente"]}
                        requiredAreas={["Diretoria", "Assembleias"]}
                        requiredUsuarioCidade={"Não"}
                        requiredAdvogadoExterno={"Não"}
                      >
                        <Assembleia />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // Cadastro
              {
                path: "cadastro",
                children: [
                  {
                    index: true,
                    element: <div>Cadastro Page</div>,
                  },
                  // {
                  //   path: "alterar-titularidade",
                  //   element: <AlterarTitularidade />,
                  // },
                  {
                    path: "alterar-titularidade",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={["Diretoria", "Cadastro"]}
                      >
                        <AlterarTitularidade2 />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // Financeiro
              {
                path: "financeiro",
                children: [
                  {
                    path: "rateio",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={["Diretoria", "Financeiro"]}
                      >
                        <RateioPage />
                      </RotasComPermissao>
                    ),
                  },
                  {
                    path: "fechamento-mensal",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={["Diretoria", "Financeiro"]}
                      >
                        <FechamentoMensal />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // Geral
              {
                path: "geral",
                children: [
                  {
                    path: "agenda",
                    children: [
                      {
                        index: true,
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                            requiredUsuarioCidade={"Não"}
                            requiredAdvogadoExterno={"Não"}
                          >
                            <Agenda />
                          </RotasComPermissao>
                        ),
                      },
                      // SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - INICIO
                      {
                        path: "ver-agenda",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                            requiredUsuarioCidade={"Não"}
                            requiredAdvogadoExterno={"Não"}
                          >
                            <Agenda2 />
                          </RotasComPermissao>
                        ),
                      },

                      // SSI 0087 - MATHEUS BOTELHO - 13/01/2025 - FIM
                    ],
                  },
                  {
                    path: "carteiras",
                    children: [
                      {
                        index: true,
                        element: <div>Carteiras Page</div>,
                      },
                      {
                        path: "editar-carteira",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]}
                            requiredAreas={ALL_AREAS}
                          >
                            <EditarCarteira />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "consultar-carteira",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                          >
                            <ConsultarCarteira />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "redirecionar-carteira",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]}
                            requiredAreas={ALL_AREAS}
                          >
                            <RedirecionarCarteira />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "transferencia-de-carteira",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]}
                            requiredAreas={ALL_AREAS}
                          >
                            <TransferenciaCondominio />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "carteira-do-usuario",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]}
                            requiredAreas={ALL_AREAS}
                          >
                            <CarteiraUsuario />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "minha-carteira",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor", "Agente"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                          >
                            <MinhaCarteira />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "editar-usuarios-cidade",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                          >
                            <EditarUsuarioCidade />
                          </RotasComPermissao>
                        ),
                      },
                    ],
                  },
                ],
              },
              // Indicadores
              {
                path: "indicadores",
                children: [
                  {
                    path: "financeiro",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin"]}
                        requiredAreas={ALL_AREAS}
                      >
                        <IndicadorFinanceiro />
                      </RotasComPermissao>
                    ),
                  },
                  // {
                  //   path: "inadiplencias",
                  //   element: (
                  //     <RotasComPermissao
                  //       requiredRoles={["admin"]}
                  //       requiredAreas={ALL_AREAS}
                  //     >
                  //       <DashboardInadimplencia />
                  //     </RotasComPermissao>
                  //   ),
                  // },
                  {
                    path: "inadimplencias",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin"]}
                        requiredAreas={ALL_AREAS}
                      >
                        <DashboardInadimplencia2 />
                      </RotasComPermissao>
                    ),
                  },
                  // SSI 0088 - MATHEUS BOTELHO - 13/01/2025 - INICIO
                  {
                    path: "lancamentos-financeiro",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["admin", "Gestor"]}
                        requiredAreas={ALL_AREAS}
                      >
                        <PaginaLancamentos />
                      </RotasComPermissao>
                    ),
                  },
                  // SSI 0088 - MATHEUS BOTELHO - 13/01/2025 - FIM
                ],
              },
              // Informações
              {
                path: "informacoes",
                children: [
                  {
                    path: "usuarios",
                    children: [
                      {
                        path: "geral",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin"]}
                            requiredAreas={ALL_AREAS}
                          >
                            <Geral />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "ausencia",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                          >
                            <Ausencia />
                          </RotasComPermissao>
                        ),
                      },
                      {
                        path: "inativar-usuario",
                        element: (
                          <RotasComPermissao
                            requiredRoles={["admin", "Gestor"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                            requiredAreas={ALL_AREAS}
                          >
                            <InativarUsuario />
                          </RotasComPermissao>
                        ),
                      },
                    ],
                  },
                ],
              },
              // Jurídico
              {
                path: "juridico",
                children: [
                  {
                    path: "inadimplencias",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Agente", "Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        {" "}
                        <Inadimplencia />
                      </RotasComPermissao>
                    ),
                  },
                  {
                    path: "efetuar-acordo",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Agente", "Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        <EfetuarAcordo />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // Notificações
              {
                path: "notificacoes",
                children: [
                  {
                    path: "criar-notificacao",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        <CriarNotificacao />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // RH
              {
                path: "rh",
                children: [
                  //SSI 0091 - MATHEUS BOTELHO - 15/01/2025 - INICIO
                  {
                    path: "importacao-salarios",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Agente", "Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={["RH", "Diretoria"]}
                      >
                        <CriarSalarios />
                      </RotasComPermissao>
                    ),
                  },
                  //SSI 0091 - MATHEUS BOTELHO - 15/01/2025 - FIM
                ],
              },
              {
                path: "atualizacoes",
                children: [
                  {
                    path: "atualizacoes-eureka",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Agente", "Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        <Timeline />
                      </RotasComPermissao>
                    ),
                  },
                  {
                    path: "listar-eventos",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Agente", "Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        <ListarEventos />
                      </RotasComPermissao>
                    ),
                  },
                  {
                    path: "criar-eventos",
                    element: (
                      <RotasComPermissao
                        requiredRoles={["Gestor", "admin"]} // SSI 0100 - MATHEUS BOTELHO - 23/01/2025
                        requiredAreas={ALL_AREAS}
                      >
                        <CriarEvento />
                      </RotasComPermissao>
                    ),
                  },
                ],
              },
              // Comunicados ou rotas adicionais
              {
                path: "comunicados",
                children: [
                  {
                    path: "em-breve",
                    children: [
                      {
                        index: true,
                        element: <ComingSoon />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
export default routes;
