// LancamentosCondominio.tsx
// 0054 - Gabriel Colle - Filtrar por Lançamentos e nomes ao clicar no Header - 05/09/2024

import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import axios from "axios";

import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TableContainer,
  Paper,
} from "@mui/material";

import { CheckCircleOutline, Cancel } from "@mui/icons-material";

import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface DetalheLancamento {
  descricao_cont: string;
  nome_favorecido: string;
  vl_bruto: number;
  complementoApro: string;
  complementoPdes: string;
  dtLiquidacao: string;
  dtVencimento: string;
  docPgto: number;
  docFiscal: number;
  comprovante: number;
}

interface CondominioData {
  id_cond: number;
  CONDOMINIO: string;
  numeroLancamentos: number;
  // adicione outras propriedades se existirem na sua API
}

interface LancamentosCondominioProps {
  periodo: string;
}

const LancamentosCondominio: React.FC<LancamentosCondominioProps> = ({
  periodo,
}) => {
  const API_URL = process.env.REACT_APP_API_URL || "";
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Dados da tabela
  const [allData, setAllData] = useState<CondominioData[]>([]);

  // Dados para o gráfico
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartValues, setChartValues] = useState<number[]>([]);

  // Estado do modal
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<DetalheLancamento[]>([]);
  const [selectedCondominio, setSelectedCondominio] = useState<string>("");

  // Colunas para AdvanceTable
  const columns = [
    {
      accessorKey: "rowNumber",
      header: "#",
      cell: ({ row }: any) => row.index + 1, // Renderiza o índice
      enableSorting: false,
    },
    {
      accessorKey: "CONDOMINIO",
      header: "Condomínio",
      cell: ({ row }: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleCondominioClick(row.original.id_cond)}
        >
          {row.original.CONDOMINIO}
        </span>
      ),
    },
    {
      accessorKey: "numeroLancamentos",
      header: "Lançamentos",
      cell: ({ row }: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleCondominioClick(row.original.id_cond)}
        >
          {row.original.numeroLancamentos}
        </span>
      ),
    },
  ];

  // Configuração do useAdvanceTable
  const table = useAdvanceTable<CondominioData>({
    data: allData,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "numeroLancamentos", desc: true }],
    },
  });

  // Buscar dados para a tabela e o gráfico
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get<CondominioData[]>(
        `${API_URL}/grafico-lancamentos/condominios-lancamentos?periodo=${periodo}`,
      );
      if (response.data && Array.isArray(response.data)) {
        const dados = response.data;
        // Ordena em ordem decrescente por número de lançamentos
        dados.sort((a, b) => b.numeroLancamentos - a.numeroLancamentos);

        // Atualiza estado com todos os registros (tabela)
        setAllData(dados);

        // Mantém lógica dos 15 principais + "Outros" para o gráfico
        const top15 = dados.slice(0, 15);
        const outrosLancamentos = dados.slice(15).reduce((acc, curr) => {
          return acc + curr.numeroLancamentos;
        }, 0);

        // Se houver mais de 15, adiciona item "Outros"
        if (dados.length > 15 && outrosLancamentos > 0) {
          top15.push({
            id_cond: 99999999,
            CONDOMINIO: "Outros",
            numeroLancamentos: outrosLancamentos,
          });
        }

        // Extrai labels e valores
        setChartLabels(top15.map((d) => d.CONDOMINIO));
        setChartValues(top15.map((d) => d.numeroLancamentos));
      } else {
        setAllData([]);
        setChartLabels([]);
        setChartValues([]);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setAllData([]);
      setChartLabels([]);
      setChartValues([]);
    }
    setIsLoading(false);
  };

  // Buscar detalhes do condomínio selecionado (modal)
  const handleCondominioClick = async (idCondominio: number) => {
    try {
      const response = await axios.get<DetalheLancamento[]>(
        `${API_URL}/grafico-lancamentos/condominios/${idCondominio}/detalhes-lancamentos?periodo=${periodo}`,
      );
      if (response.data && response.data.length > 0) {
        setModalData(response.data);
        setSelectedCondominio(response.data[0].descricao_cont || "Condomínio");
        setModalOpen(true);
      } else {
        setModalData([]);
        setSelectedCondominio("");
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes dos lançamentos:", error);
    }
  };

  // Monta as opções do gráfico Echarts (doughnut)
  const getChartOption = () => {
    const data = chartLabels.map((label, index) => ({
      value: chartValues[index],
      name: label,
    }));

    return {
      tooltip: {
        trigger: "item",
      },
      legend: {
        show: false, // Se quiser exibir legenda, altere para true
      },
      series: [
        {
          name: "Lançamentos por Condomínio",
          type: "pie",
          radius: ["40%", "70%"], // Furo do doughnut
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "14",
            },
          },
          data,
        },
      ],
    };
  };

  useEffect(() => {
    fetchData();
  }, [periodo]);

  return (
    <div className="indicador-lancamentos-condos">
      {isLoading ? (
        <div className="carregar-lancamento-condos">
          <CircularProgress />
          Aguarde, os dados estão sendo carregados...
        </div>
      ) : (
        <div className="row">
          {/* Tabela */}
          <div className="col-md-6">
            <AdvanceTableProvider {...table}>
              <div
                className="mx-n4 px-4 mx-lg-n4 px-lg-3 bg-white border-top border-bottom border-100 position-relative top-1"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                <AdvanceTable
                  tableProps={{
                    className: "phoenix-table fs-9 table-hover",
                  }}
                />
              </div>
            </AdvanceTableProvider>
          </div>

          {/* Gráfico */}
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            {chartLabels.length > 0 && chartValues.length > 0 && (
              <ReactECharts
                option={getChartOption()}
                style={{
                  width: "100%", // Ocupa toda a largura disponível do contêiner
                  maxWidth: "1200px", // Tamanho máximo ajustável
                  height: "600px", // Aumenta a altura do gráfico
                }}
              />
            )}
          </div>
        </div>
      )}

      {/* Modal de detalhes */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          className="modalBox"
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Detalhes dos Lançamentos - {selectedCondominio}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Detalhes dos Lançamentos">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell
                    sx={{
                      width: "300px",
                    }}
                  >
                    Descrição
                  </TableCell>
                  <TableCell>Complemento Apro</TableCell>
                  <TableCell>Complemento Pdes</TableCell>
                  <TableCell>Data Liquidado</TableCell>
                  <TableCell>Data Vencimento</TableCell>
                  <TableCell>Doc Pgto</TableCell>
                  <TableCell>Doc Fiscal</TableCell>
                  <TableCell>Comprovante</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalData.map((detalhe, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell className="descricao-column">
                      <strong>{detalhe.descricao_cont}</strong> para{" "}
                      <strong>{detalhe.nome_favorecido}</strong> no valor de{" "}
                      <strong>R$ {detalhe.vl_bruto}</strong>
                    </TableCell>
                    <TableCell>{detalhe.complementoApro}</TableCell>
                    <TableCell>{detalhe.complementoPdes}</TableCell>
                    <TableCell>
                      {new Date(detalhe.dtLiquidacao).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(detalhe.dtVencimento).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {detalhe.docPgto === 1 ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      {detalhe.docFiscal === 1 ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      {detalhe.comprovante === 1 ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default LancamentosCondominio;
