// KpisPageFin.tsx
// 0054 - Gabriel Colle - Botão para recarregar banco de dados novamente, com requisição superlogica - 05/09/2024

import React, { useState } from "react";
import LancamentosUsuario from "./charts/LancamentoUsuarios";
import LancamentosCondominio from "./charts/LancamentoCondos";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { Form } from "react-bootstrap";

const PaginaLancamentos: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL || "";
  const [periodo, setPeriodo] = useState<string>("mesAtual");
  const [visualizacao, setVisualizacao] = useState<string>("usuarios"); // Adicionado
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultBreadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Lançamentos Financeiro", active: true },
  ];

  // Função para recarregar os dados
  const recarregarDados = async () => {
    setIsLoading(true);
    toast.info("Recarregando os dados, aguarde até ser concluído...");
    try {
      await axios.get(`${API_URL}/lancamentos/recarregarDados`);
      toast.success("Dados recarregados com sucesso!");
    } catch (error) {
      console.error("Erro ao recarregar dados:", error);
      toast.error("Erro ao recarregar dados. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const iniciarTimeoutAtualizacao = () => {
    setTimeout(() => {
      window.location.reload();
    }, 60000);
  };

  return (
    <div className="container mt-4">
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      {/* Título */}
      <h2>Lançamentos Financeiro</h2>

      {/* Filtros */}
      <div className="row align-items-center mt-4">
        {/* Seleção de período */}
        <div className="col-md-4 mb-2">
          <Form.Group>
            <Form.Label>Selecione o período</Form.Label>
            <Form.Select
              value={periodo}
              onChange={(e) => setPeriodo(e.target.value)}
              size="sm"
            >
              <option value="mesAtual">Mês Atual</option>
              <option value="ultimoMes">Mês Anterior</option>
              <option value="ultimos3Meses">Últimos 3 Meses</option>
              <option value="ultimos6Meses">Últimos 6 Meses</option>
            </Form.Select>
          </Form.Group>
        </div>

        {/* Seleção de visualização */}
        <div className="col-md-4 mb-2">
          <Form.Group>
            <Form.Label>Selecione a visualização</Form.Label>
            <Form.Select
              value={visualizacao}
              onChange={(e) => setVisualizacao(e.target.value)}
              size="sm"
            >
              <option value="usuarios">Usuário</option>
              <option value="condominios">Condomínio</option>
            </Form.Select>
          </Form.Group>
        </div>

        {/* Botão para recarregar */}
        <div className="col-md-4 mb-2 justify-content-end d-flex">
          <button
            onClick={recarregarDados}
            onClickCapture={iniciarTimeoutAtualizacao}
            disabled={isLoading}
            className="btn btn-primary me-1 mb-1"
            type="button"
          >
            {isLoading ? <CircularProgress size={20} /> : "Recarregar Dados"}
          </button>
        </div>
      </div>

      {/* Conteúdo dinâmico */}
      <div className="mt-4">
        {visualizacao === "usuarios" ? (
          <>
            <h4 className="mb-3">Lançamentos por Usuário</h4>
            <LancamentosUsuario periodo={periodo} />
          </>
        ) : (
          <>
            <h4 className="mb-3">Lançamentos por Condomínio</h4>
            <LancamentosCondominio periodo={periodo} />
          </>
        )}
      </div>
    </div>
  );
};

export default PaginaLancamentos;
