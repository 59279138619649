import React from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { colors } from "./utils/colorPalette"; // Ajuste o caminho, se necessário

interface CondoData {
  city: string;
  count: number;
  bg: string;
}

interface CondosPorCidadeChartProps {
  condoData: CondoData[];
}

echarts.use([TooltipComponent, PieChart, LegendComponent]);

const getDefaultOptions = (
  condoData: CondoData[],
): echarts.EChartsCoreOption => ({
  color: condoData.map((_, index) => colors[index % colors.length]),
  tooltip: {
    trigger: "item",
    formatter: (params: {
      color: string;
      name: string;
      value: number;
      percent: number;
    }) => {
      return `
        <div>
          <span style="display: inline-block; width: 10px; height: 10px; background-color: ${params.color}; margin-right: 5px; border-radius: 50%;"></span>
          ${params.name}: ${params.value} (${params.percent}%)
        </div>`;
    },
    backgroundColor: "#fff",
    borderColor: "#ccc",
    borderWidth: 1,
    textStyle: {
      color: "#000",
      fontSize: 12,
    },
  },
  series: [
    {
      name: "Condomínios",
      type: "pie",
      radius: ["48%", "90%"],
      avoidLabelOverlap: true,
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      labelLine: {
        show: false,
      },
      data: condoData.map((condo: CondoData) => ({
        value: condo.count,
        name: condo.city,
      })),
    },
  ],
});

const CondosPorCidadeChart: React.FC<CondosPorCidadeChartProps> = ({
  condoData,
}) => {
  const options = getDefaultOptions(condoData);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={options}
      style={{ minHeight: "400px", width: "100%" }}
    />
  );
};

export default CondosPorCidadeChart;
