import React, { Suspense, useEffect, useState } from "react";
import AuthSplitLayout from "layouts/AuthSplitLayout";

const bg =
  "https://euro-principal.s3.us-east-2.amazonaws.com/background/BgLogin.jpg";

const SignInForm = React.lazy(
  () => import("components/modules/auth/SignInForm"),
);

const SignIn = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  // Pré-carrega a imagem
  useEffect(() => {
    const img = new Image();
    img.src = bg;
    img.onload = () => setBgLoaded(true);
  }, []);

  // Renderiza apenas quando a imagem está carregada
  if (!bgLoaded) {
    return <div></div>;
  }

  return (
    <Suspense fallback={<div></div>}>
      <AuthSplitLayout bg={bg}>
        <SignInForm />
      </AuthSplitLayout>
    </Suspense>
  );
};

export default SignIn;
