// SSI 0089 - MATHEUS BOTELHO - 14/01/2025

import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import axios from "axios";

// Import do AdvanceTable
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";

import PageBreadcrumb from "components/common/PageBreadcrumb";

// Componentes do MUI
import { CircularProgress } from "@mui/material";

// Tipos para os dados retornados da API
interface IDadoAPI {
  status_financeiro: string;
  valorcorrigido: string | number;
  id_condominio_cond: number | string;
  id_unidade_uni: number | string;
  CONDOMINIO: string;
  nome: string;
  diasatraso: number;
}

interface ISemProcessoPorCondominio {
  condominio: string;
  valorCorrigidoTotal: number;
  unidades: number;
}

interface ITopDevedores {
  comProcesso: IDadoAPI[];
  semProcesso: IDadoAPI[];
  semProcessoPorCondominio: ISemProcessoPorCondominio[];
}

const ChartInadimplencia: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL || "";

  const [chartOptions, setChartOptions] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Estado que armazena os dados (top devedores e agregados).
  const [topDevedores, setTopDevedores] = useState<ITopDevedores>({
    comProcesso: [],
    semProcesso: [],
    semProcessoPorCondominio: [],
  });

  // Estado para controlar qual tabela exibir
  const [selectedView, setSelectedView] = useState<
    "porUnidade" | "porCondominio"
  >("porUnidade");

  const defaultBreadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Lançamentos Financeiro", active: true },
  ];

  // -----------------------------------------------------
  //  Configuração das colunas para cada tabela
  // -----------------------------------------------------
  const columnsSemProcesso = [
    {
      accessorKey: "rowNumber",
      header: "#",
      cell: ({ row }: any) => row.index + 1,
      enableSorting: false,
    },
    {
      accessorKey: "CONDOMINIO",
      header: "Condomínio",
    },
    {
      accessorKey: "id_unidade_uni",
      header: "Unidade",
    },
    {
      accessorKey: "nome",
      header: "Proprietário",
    },
    {
      accessorKey: "valorcorrigido",
      header: "Valor Corrigido",
      cell: ({ row }: any) => {
        const valor = parseFloat(String(row.original.valorcorrigido));
        return `R$ ${valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`;
      },
    },
    {
      accessorKey: "diasatraso",
      header: "Dias de Atraso",
    },
  ];

  const columnsSemProcessoPorCondominio = [
    {
      accessorKey: "rowNumber",
      header: "#",
      cell: ({ row }: any) => row.index + 1,
      enableSorting: false,
    },
    {
      accessorKey: "condominio",
      header: "Condomínio",
    },
    {
      accessorKey: "valorCorrigidoTotal",
      header: "Total Valor Corrigido",
      cell: ({ row }: any) => {
        const valor = parseFloat(String(row.original.valorCorrigidoTotal));
        return `R$ ${valor.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`;
      },
    },
    {
      accessorKey: "unidades",
      header: "Unidades Inadimplentes",
    },
  ];

  // -----------------------------------------------------
  //  Carregamento dos dados (fetchData)
  // -----------------------------------------------------
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get<IDadoAPI[]>(
        `${API_URL}/dashboard-inadimplencia/inadimplencia-todos-condominios`,
      );
      const dados = response.data || [];

      // Variáveis de agregação
      const agrupado = { comProcesso: 0, semProcesso: 0 };
      const somaPorUnidade: Record<
        string,
        IDadoAPI & { valorcorrigido: number }
      > = {};
      const somaPorCondominio: Record<
        string,
        { valorCorrigidoTotal: number; unidades: Set<string> }
      > = {};

      // Processamento dos dados
      dados.forEach((item: IDadoAPI) => {
        const valorCorrigidoNum = parseFloat(String(item.valorcorrigido)) || 0;
        const chaveUnidade = `${item.id_condominio_cond}-${item.id_unidade_uni}`;
        const key =
          item.status_financeiro === "Jurídico" ? "comProcesso" : "semProcesso";

        // Soma valores em agrupado
        agrupado[key] += valorCorrigidoNum;

        // Soma valores por unidade
        if (!somaPorUnidade[chaveUnidade]) {
          somaPorUnidade[chaveUnidade] = {
            ...item,
            valorcorrigido: valorCorrigidoNum,
          };
        } else {
          somaPorUnidade[chaveUnidade].valorcorrigido += valorCorrigidoNum;
        }

        // Soma por condomínio (apenas para "sem processo")
        if (key === "semProcesso") {
          const condominio = item.CONDOMINIO;
          if (!somaPorCondominio[condominio]) {
            somaPorCondominio[condominio] = {
              valorCorrigidoTotal: valorCorrigidoNum,
              unidades: new Set([chaveUnidade]),
            };
          } else {
            somaPorCondominio[condominio].valorCorrigidoTotal +=
              valorCorrigidoNum;
            somaPorCondominio[condominio].unidades.add(chaveUnidade);
          }
        }
      });

      const optionsEcharts = {
        title: {
          text: "Comparativo de Inadimplências",
          left: "center",
          top: 0,
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            const valor = Number(params.value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return `<strong>${params.name}:</strong> R$ ${valor} (${params.percent}%)`;
          },
        },
        legend: {
          orient: "horizontal",
          top: 30,
          data: ["Sem", "Com"],
        },
        color: ["#164871", "#0e93ffe1"],
        series: [
          {
            name: "Inadimplência",
            type: "pie",
            radius: ["40%", "70%"],
            minAngle: 10,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: agrupado.semProcesso || 0,
                name: "Sem",
              },
              {
                value: agrupado.comProcesso || 0,
                name: "Com",
              },
            ],
          },
        ],
      };

      // Calcula top devedores (Sem Processo)
      const topSemProcesso = Object.values(somaPorUnidade)
        .filter((item) => item.status_financeiro !== "Jurídico")
        .sort((a, b) => b.valorcorrigido - a.valorcorrigido)
        .slice(0, 1000);

      // Agrupados por condomínio (Sem Processo)
      const topSemProcessoPorCondominio = Object.entries(somaPorCondominio)
        .map(([condominio, { valorCorrigidoTotal, unidades }]) => ({
          condominio,
          valorCorrigidoTotal,
          unidades: unidades.size,
        }))
        .sort((a, b) => b.valorCorrigidoTotal - a.valorCorrigidoTotal);

      // Atualiza estados
      setChartOptions(optionsEcharts);
      setTopDevedores({
        comProcesso: [],
        semProcesso: topSemProcesso,
        semProcessoPorCondominio: topSemProcessoPorCondominio,
      });
    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Tabela de pessoas "Sem Processo"
  const tableSemProcesso = useAdvanceTable<IDadoAPI>({
    data: topDevedores.semProcesso,
    columns: columnsSemProcesso,
    pageSize: 9,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {},
  });

  // Tabela agrupada por condomínio "Sem Processo"
  const tableSemProcessoPorCondominio =
    useAdvanceTable<ISemProcessoPorCondominio>({
      data: topDevedores.semProcessoPorCondominio,
      columns: columnsSemProcessoPorCondominio,
      pageSize: 9,
      pagination: true,
      sortable: true,
      selection: false,
      initialState: {
        // sorting: [{ id: "valorCorrigidoTotal", desc: true }],
      },
    });

  // -----------------------------------------------------
  //  Renderização
  // -----------------------------------------------------
  if (loading || !chartOptions) {
    return (
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ marginBottom: "10px" }} />
        <span>Aguarde, os dados estão sendo carregados.</span>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-6">
        <PageBreadcrumb items={defaultBreadcrumbItems} />

        {/* Título */}
        <h2>Dashboard de Inadimplências</h2>
      </div>
      {/* Linha contendo o gráfico (50%) e a tabela (50%) */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        {/* Conteúdo (menu de seleção + tabela) (lado direito) */}
        <div
          style={{
            width: "80%",
          }}
        >
          {/* Menu de seleção */}
          <div className="form-group mb-3">
            <label htmlFor="selectView" style={{ fontWeight: "bold" }}>
              Visualizar:
            </label>
            <select
              className="form-control"
              id="selectView"
              onChange={(e) =>
                setSelectedView(
                  e.target.value as "porUnidade" | "porCondominio",
                )
              }
              value={selectedView}
              style={{ maxWidth: "200px", marginTop: "5px" }}
            >
              <option value="porUnidade">Por unidade</option>
              <option value="porCondominio">Por condomínio</option>
            </select>
          </div>

          {/* Renderização condicional da tabela */}
          {selectedView === "porUnidade" && (
            <div>
              <h4 style={{ margin: "10px 0" }}>
                Lista de inadimplentes por unidade
              </h4>
              <AdvanceTableProvider {...tableSemProcesso}>
                <div>
                  <AdvanceTable
                    tableProps={{
                      className: "phoenix-table fs-9 table-hover",
                      style: { border: "none" },
                    }}
                  />
                </div>
                <AdvanceTableFooter pagination /> {/* Adiciona paginação */}
              </AdvanceTableProvider>
            </div>
          )}

          {selectedView === "porCondominio" && (
            <div>
              <h4 style={{ margin: "10px 0" }}>
                Lista de inadimplentes por condomínio
              </h4>
              <AdvanceTableProvider {...tableSemProcessoPorCondominio}>
                <div>
                  <AdvanceTable
                    tableProps={{
                      className: "phoenix-table fs-9 table-hover",
                      style: { border: "none" },
                    }}
                  />
                </div>
                <AdvanceTableFooter pagination /> {/* Adiciona paginação */}
              </AdvanceTableProvider>
            </div>
          )}
        </div>
        {/* Gráfico (lado esquerdo) */}
        <div style={{ width: "50%", height: "680px" }}>
          <ReactECharts
            option={chartOptions}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartInadimplencia;
