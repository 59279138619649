import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

const ModalPesquisa = ({ isOpen, onRequestClose, onSelectCadastro }) => {
  const [nomeResp, setNomeResp] = useState("");
  const [nomeCond, setNomeCond] = useState("");
  const [resultados, setResultados] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const buscarCadastros = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/alteracao-cadastro/dados-pesquisa`,
          {
            params: { nome_resp: nomeResp, nome_cond: nomeCond },
          },
        );
        setResultados(response.data);
      } catch (error) {
        console.error("Erro ao buscar cadastros", error);
      }
    };

    buscarCadastros();
  }, [nomeResp, nomeCond, API_URL]);

  return (
    <Modal
      show={isOpen}
      onHide={onRequestClose}
      centered
      size="lg"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal.Header
        closeButton
        style={{
          position: "relative",
          background: "#fff",
          padding: "20px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Modal.Title style={{ fontSize: "24px", color: "#343434" }}>
          Buscar Cadastro
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          position: "relative",
          padding: "30px",
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <input
            type="text"
            placeholder="Nome do Responsável"
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "6px",
              fontSize: "14px",
            }}
            value={nomeResp}
            onChange={(e) => setNomeResp(e.target.value)}
          />
          <input
            type="text"
            placeholder="Nome do Condomínio"
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "6px",
              fontSize: "14px",
            }}
            value={nomeCond}
            onChange={(e) => setNomeCond(e.target.value)}
          />
        </div>
        <ul
          style={{
            listStyle: "none",
            width: "100%",
            padding: "0",
            marginTop: "20px",
            overflowY: "auto",
          }}
        >
          {resultados.length > 0 ? (
            resultados.map((cadastro) => (
              <li
                key={cadastro.id}
                onClick={() => onSelectCadastro(cadastro.id)}
                style={{
                  background: "#f8f9fa",
                  padding: "10px",
                  marginBottom: "5px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  textAlign: "left",
                  border: "1px solid #ddd",
                  fontSize: "14px",
                  transition: "all 0.2s ease-in-out",
                  color: "#343434",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#a0caf8")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#f8f9fa")
                }
              >
                {cadastro.nome_resp} - {cadastro.nome_cond} - ID: {cadastro.id}
              </li>
            ))
          ) : (
            <li
              style={{
                background: "#f8f9fa",
                padding: "10px",
                borderRadius: "4px",
                textAlign: "center",
                fontSize: "14px",
                color: "#343434",
              }}
            >
              Nenhum resultado encontrado.
            </li>
          )}
        </ul>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "20px",
          borderTop: "1px solid #ddd",
        }}
      >
        <Button
          variant="secondary"
          onClick={onRequestClose}
          style={{
            background: "#6c757d",
            borderColor: "#6c757d",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "4px",
          }}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPesquisa;
