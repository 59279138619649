// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useEffect, useState, useMemo } from "react";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Eye, Edit, XCircle } from "feather-icons-react";
import Badge from "components/base/Badge";
import { useSearchParams } from "react-router-dom";
import useAdvanceTable from "hooks/useAdvanceTable";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { Col, Row, Form } from "react-bootstrap";
import Button from "components/base/Button";
import ModalAcordos from "./modal/ModalAcordos";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import ModalBoletosAcordos from "./modal/ModalBoletosAcordos";

// ---------------
// Tipos unificados
// ---------------
interface ItemTabela {
  origem: "NAO_FEITOS" | "FEITOS";
  idCobranca: string;
  vencimento?: string;
  idAcordo?: string;
  principal?: number;
  multa?: number;
  juros?: number;
  atualizado?: number;
  honorarios?: number;
  acordoFeito?: boolean;
  emProcessoJudicial?: boolean;
}

// Retorno de "não feitos"
interface EncargoDetalhes {
  atualizacaomonetaria: string;
  juros: string;
  multa: string;
  honorarios: string;
}
interface Encargo {
  detalhes: EncargoDetalhes;
}
interface Recebimento {
  ids_recebimento_recb_ref: string;
  dt_acordo_aco: string;
  vl_emitido_recb: string;
  encargos: Encargo[];
}
export interface DadosNaoFeitos {
  st_unidade_uni: string;
  recebimento: Recebimento[];
}

// Retorno de "feitos"
export interface DadosFeitos {
  id_acordo_aco: string;
  vl_emitido_recb: string;
  vl_acordojuros_recb: number;
  vl_acordomulta_recb: number;
  vl_acordoatualizacao_recb: number;
  vl_acordohonorarios_recb: number;
  ids_recebimento_recb: string[];
  ids_recebimento_recb_ref: string;
  dt_acordo_aco: string;
}

// Component
const EfetuarAcordo: React.FC = () => {
  const [searchParams] = useSearchParams();
  const totalDevedor = searchParams.get("totalDevedor") || "0";
  const unidade = searchParams.get("unidade") || "";
  const idsCobrancas = searchParams.get("idsCobrancas")
    ? (searchParams.get("idsCobrancas")?.split(",") ?? [])
    : [];

  const idCondominio = Number(searchParams.get("idCondominio") || "0");
  // "unidade" é o idUnidade para a rota
  const idUnidade = unidade.trim();

  const iddUnidade = searchParams.get("idUnidade") || "";

  // Armazenam o retorno cru da API
  const [dadosNaoFeitos, setDadosNaoFeitos] = useState<DadosNaoFeitos[]>([]);
  const [dadosFeitos, setDadosFeitos] = useState<DadosFeitos[]>([]);

  // Armazena o array unificado para a tabela
  const [detalhesCobrancas, setDetalhesCobrancas] = useState<ItemTabela[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "year").format("YYYY-MM"),
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM"));

  // Converte p/ MM/DD/YYYY
  const dtInicio = dayjs(startDate, "YYYY-MM")
    .startOf("month")
    .format("MM/DD/YYYY");
  const dtFim = dayjs(endDate, "YYYY-MM").endOf("month").format("MM/DD/YYYY");

  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

  // -------------------------
  // 1) Buscar dados
  // -------------------------
  const buscarDadosCobrancas = async () => {
    try {
      console.log("idCondominio:", idCondominio);
      console.log("idUnidade (da unidade):", idUnidade);
      console.log("idsCobrancas:", idsCobrancas);
      console.log("dtInicio (MM/DD/YYYY):", dtInicio);
      console.log("dtFim (MM/DD/YYYY):", dtFim);

      if (!idCondominio || !idUnidade || idsCobrancas.length === 0) {
        console.log("Parâmetros insuficientes. Nenhuma requisição feita.");
        return;
      }

      const urlNaoFeitos = `${REACT_APP_API_URL}/efetuar-acordos/buscar-acordos-nao-feitos?idCondominio=${idCondominio}&idUnidade=${idUnidade}`;
      const urlFeitos = `${REACT_APP_API_URL}/efetuar-acordos/buscar-acordos-feitos?idCondominio=${idCondominio}&idUnidade=${idUnidade}&dtInicio=${dtInicio}&dtFim=${dtFim}`;

      console.log("URL Não Feitos:", urlNaoFeitos);
      console.log("URL Feitos:", urlFeitos);

      const [respNaoFeitos, respFeitos] = await Promise.all([
        fetch(urlNaoFeitos),
        fetch(urlFeitos),
      ]);

      console.log("respNaoFeitos status:", respNaoFeitos.status);
      console.log("respFeitos status:", respFeitos.status);

      const dadosNaoFeitosJson = await respNaoFeitos.json();
      const dadosFeitosJson = await respFeitos.json();

      console.log("Dados Não Feitos:", dadosNaoFeitosJson);
      console.log("Dados Feitos:", dadosFeitosJson);

      setDadosNaoFeitos(dadosNaoFeitosJson);
      setDadosFeitos(dadosFeitosJson);
    } catch (erro) {
      console.error("Erro ao buscar dados das cobranças:", erro);
    }
  };

  // Chama buscar ao montar e sempre que dtInicio/dtFim/idUnidade/idCondominio mudarem
  useEffect(() => {
    buscarDadosCobrancas();
  }, [dtInicio, dtFim, idUnidade, idCondominio]);

  // -------------------------
  // 2) Unificar dados no "detalhesCobrancas"
  // -------------------------
  useEffect(() => {
    // Função auxiliar para converter string -> number
    const parseStrToNum = (val: any): number => {
      if (!val) return 0;
      if (typeof val === "number") return val;
      return parseFloat(String(val).replace(",", "."));
    };

    const novoArray: ItemTabela[] = [];

    // Mapeia "Não Feitos"
    dadosNaoFeitos.forEach((naoFeito) => {
      naoFeito.recebimento.forEach((rec) => {
        novoArray.push({
          origem: "NAO_FEITOS",
          vencimento: rec.dt_acordo_aco, // "dd/MM/yyyy" ou "MM/dd/yyyy", depende do server
          idCobranca: rec.ids_recebimento_recb_ref,
          idAcordo: undefined,
          principal: parseStrToNum(rec.vl_emitido_recb),
          multa: parseStrToNum(rec.encargos?.[0]?.detalhes.multa),
          juros: parseStrToNum(rec.encargos?.[0]?.detalhes.juros),
          honorarios: parseStrToNum(rec.encargos?.[0]?.detalhes.honorarios),
          atualizado: parseStrToNum(
            rec.encargos?.[0]?.detalhes.atualizacaomonetaria,
          ),
          acordoFeito: false,
          emProcessoJudicial: false,
        });
      });
    });

    // Mapeia "Feitos"
    dadosFeitos.forEach((feito) => {
      novoArray.push({
        origem: "FEITOS",
        vencimento: feito.dt_acordo_aco, // Adicionado para usar dt_acordo_aco como vencimento
        idCobranca: feito.ids_recebimento_recb_ref,
        idAcordo: feito.id_acordo_aco,
        principal: parseStrToNum(feito.vl_emitido_recb),
        multa: parseStrToNum(feito.vl_acordomulta_recb),
        juros: parseStrToNum(feito.vl_acordojuros_recb),
        honorarios: parseStrToNum(feito.vl_acordohonorarios_recb),
        atualizado: parseStrToNum(feito.vl_acordoatualizacao_recb),
        acordoFeito: true,
        emProcessoJudicial: false,
      });
    });

    setDetalhesCobrancas(novoArray);
  }, [dadosNaoFeitos, dadosFeitos]);

  // -------------------------
  // 3) Definição das colunas
  // -------------------------

  const styles = `
  .action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .action-buttons .icon-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
  }
  .phoenix-table tbody tr:hover .icon-button {
    opacity: 1 !important;
    visibility: visible;
  }
`;

  const columns = useMemo(
    () => [
      {
        accessorKey: "vencimento",
        header: "data competência",
        cell: ({ getValue }: any) => {
          const dateValue = getValue();
          if (!dateValue) return "N/A";
          try {
            // Ajuste se o backend manda "DD/MM/YYYY" ou "YYYY-MM-DD", etc.
            return format(new Date(dateValue), "dd/MM/yyyy");
          } catch {
            return dateValue; // ou "Data inválida"
          }
        },
      },
      {
        accessorKey: "idAcordo",
        header: "Acordo",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return val ? val : "-";
        },
      },
      {
        accessorKey: "idCobranca",
        header: "Cobrança",
      },
      {
        accessorKey: "principal",
        header: "Principal",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return typeof val === "number"
            ? val.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
            : "N/A";
        },
      },
      {
        accessorKey: "multa",
        header: "Multa",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return typeof val === "number"
            ? val.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
            : "N/A";
        },
      },
      {
        accessorKey: "juros",
        header: "Juros",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return typeof val === "number"
            ? val.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
            : "N/A";
        },
      },
      {
        accessorKey: "atualizado",
        header: "Atualização",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return typeof val === "number"
            ? val.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
            : "N/A";
        },
      },
      {
        accessorKey: "honorarios",
        header: "Honorários",
        cell: ({ getValue }: any) => {
          const val = getValue();
          return typeof val === "number"
            ? val.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
            : "N/A";
        },
      },
      {
        accessorKey: "atualizado",
        header: "Atualizado",
        cell: ({ row }: any) => {
          const {
            principal = 0,
            multa = 0,
            juros = 0,
            atualizado = 0,
            honorarios = 0,
          } = row.original;
          const totalAtualizado =
            principal + multa + juros + atualizado + honorarios;

          return totalAtualizado.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
          });
        },
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          return (
            <div
              className="action-buttons"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="link"
                className="btn-sm text-primary p-0 m-0 icon-button"
                title="Ver detalhes"
                onClick={() => onDetailsClick(row.original)}
              >
                <Eye size={16} />
              </Button>

              {/* <Button
                variant="link"
                className="btn-sm text-warning p-0 m-0 icon-button"
                title="Desfazer acordo"
              >
                <XCircle size={16} />
              </Button> */}
            </div>
          );
        },
      },
      {
        accessorKey: "acordoFeito",
        header: "Status",
        cell: ({ getValue }: any) => {
          const isFeito = getValue();
          return isFeito ? (
            <Badge
              variant="phoenix"
              bg="success"
              className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
              style={{ minWidth: "80px" }}
            >
              Feito
            </Badge>
          ) : (
            <Badge
              variant="phoenix"
              bg="danger"
              className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
              style={{ minWidth: "80px" }}
            >
              Não Feito
            </Badge>
          );
        },
      },
    ],
    [],
  );

  // -------------------------
  // 4) Configuração da tabela
  // -------------------------
  const tableInstance = useAdvanceTable({
    columns,
    data: detalhesCobrancas,
    selection: true,
    sortable: true,
    pagination: true,
    pageSize: 10,
  });

  // -------------------------
  // 5) Funções do Modal
  // -------------------------
  const handleOpenModal = () => {
    const selecionadas = tableInstance
      .getSelectedRowModel()
      .rows.map((row: { original: ItemTabela }) => row.original);

    // Exemplo de validação
    const possuiRestricoes = selecionadas.some(
      (item) => item.emProcessoJudicial || item.acordoFeito,
    );
    if (possuiRestricoes) {
      alert("Não é possível parcelar que estão judicial ou já feitas.");
      return;
    }

    setSelectedData(selecionadas);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // -------------------------
  // 6) Função Modal Ver detalhes
  // -------------------------

  const [showModalBoletos, setShowModalBoletos] = useState(false);
  const [acordoSelecionado, setAcordoSelecionado] = useState<string | null>(
    null,
  );

  const onDetailsClick = (acordo: ItemTabela) => {
    if (!acordo.idAcordo) {
      toast.error("Este item não possui acordo associado.");
      return;
    }
    setAcordoSelecionado(acordo.idAcordo);
    setShowModalBoletos(true);
  };

  // -------------------------
  // 7) Render
  // -------------------------
  return (
    <div>
      <style>{styles}</style>
      <ToastContainer />
      <PageBreadcrumb
        items={[
          { label: "Home", link: "/" },
          { label: "Acordos", link: "/pages/juridico/inadimplencias" },
          { label: "Efetuar Acordo", active: true },
        ]}
      />
      <h2 className="mb-4">Acordos</h2>

      <AdvanceTableProvider {...tableInstance}>
        <div className="mb-4">
          <Row className="g-4 align-items-end justify-content-between">
            <Col
              xs="auto"
              className="d-flex flex-column flex-md-row p-2"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h4 className="me-md-3 mb-0 fw-bold">Unidade: {unidade}</h4>
              <h4 className="mb-0 fw-bold">
                Total Devedor:{" "}
                {Number(totalDevedor).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </h4>
            </Col>

            <Col>
              <div
                className="d-flex align-items-end gap-2"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Group style={{ minWidth: "160px" }}>
                  <Form.Label>Selecione Início</Form.Label>
                  <Form.Control
                    type="month"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={endDate}
                    style={{ width: "160px", height: "30px" }}
                  />
                </Form.Group>

                <Form.Group style={{ minWidth: "160px" }}>
                  <Form.Label>Selecione Fim</Form.Label>
                  <Form.Control
                    type="month"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    min={startDate}
                    style={{ width: "160px", height: "30px" }}
                  />
                </Form.Group>
              </div>
            </Col>

            <Col xs="auto">
              <Button variant="primary" onClick={handleOpenModal}>
                Iniciar Acordo
              </Button>
            </Col>
          </Row>
        </div>

        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{
              className:
                "phoenix-table fs-9 table-hover text-center align-left",
            }}
          />
        </div>

        <div className="px-4">
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <ModalAcordos
        show={showModal}
        onHide={() => {
          handleCloseModal();
          buscarDadosCobrancas();
        }}
        selectedData={selectedData}
        REACT_APP_API_URL={REACT_APP_API_URL || ""}
        idCondominio={String(idCondominio)}
        onToast={(message, type) => {
          if (type === "success") {
            toast.success(message);
          } else if (type === "error") {
            toast.error(message);
          }
        }}
      />

      <ModalBoletosAcordos
        show={showModalBoletos}
        onHide={() => setShowModalBoletos(false)}
        idAcordo={acordoSelecionado}
        idCondominio={idCondominio}
        dtInicio={dtInicio}
        dtFim={dtFim}
        idUnidade={idUnidade}
        REACT_APP_API_URL={REACT_APP_API_URL || ""}
        onToast={(message, type) => {
          if (type === "success") {
            toast.success(message);
          } else if (type === "error") {
            toast.error(message);
          }
        }}
      />
    </div>
  );
};

export default EfetuarAcordo;
