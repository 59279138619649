import useToggleStyle from "hooks/useToggleStyle";
import { useAppContext } from "providers/AppProvider";
import { useEffect, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import UserContext from "./components/UserContext";

const App = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const { user } = useContext(UserContext); // Pegando o usuário diretamente do contexto
  const {
    config: { theme },
  } = useAppContext();

  // Configura o token do Axios sempre que o usuário mudar
  useEffect(() => {
    if (user) {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        console.log("Token configurado no Axios:", token);
      } else {
        console.warn("Token ausente. Removendo configuração do Axios.");
        delete axios.defaults.headers.common["Authorization"];
      }
    } else {
      console.warn("Usuário não autenticado. Removendo configuração do Axios.");
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [user]);

  // Rolagem automática para o topo sempre que o caminho mudar
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === "dark" ? "#000" : "#fff",
          }}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default App;
