//
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { io, Socket } from "socket.io-client";
import { Notification } from "data/notifications";
import { UserContext } from "../components/UserContext";
import { toast } from "react-toastify";

interface NotificationsContextProps {
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  markAllAsRead: () => void;
  unreadCount: number; // Número de notificações não lidas
}

export const NotificationsContext = createContext<
  NotificationsContextProps | undefined
>(undefined);

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const { user } = useContext(UserContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const unreadCount = notifications.filter((n) => !n.lida).length; // Cálculo de notificações não lidas

  const showCustomToast = (
    message: string,
    type: "info" | "warning" = "info",
  ) => {
    if (type === "warning") {
      toast.warn(message);
    } else {
      toast.info(message);
    }
  };

  useEffect(() => {
    if (user && user.id_func) {
      // Substitua pela URL do servidor WebSocket
      const SOCKET_URL = "http://localhost:3000"; // ou a URL do servidor WebSocket

      const newSocket = io(SOCKET_URL, {
        query: { userId: user.id_func, area: user.area },
      });

      newSocket.on("receiveNotification", (notification: Notification) => {
        setNotifications((prev) => [notification, ...prev]);

        if (notification.type === "condominium") {
          showCustomToast(
            "Atenção! Você tem uma nova notificação do seu condomínio!",
            "warning",
          );
        } else {
          showCustomToast("Você tem uma nova notificação!");
        }
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [user]);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!user || !user.id_func) return;

      try {
        const response = await fetch(`${API_URL}/notificacoes/${user.id_func}`);
        if (response.ok) {
          const data: Notification[] = await response.json();
          setNotifications(data);
        } else {
          console.error("Erro ao buscar notificações:", response.statusText);
        }
      } catch (error) {
        console.error("Erro ao buscar notificações:", error);
      }
    };

    fetchNotifications();
  }, [API_URL, user]);

  const markAllAsRead = async () => {
    const unreadNotifications = notifications.filter((n) => !n.lida);

    const updatePromises = unreadNotifications.map((notification) =>
      fetch(`${API_URL}/notificacoes/lida/${notification.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ lida: 1 }),
      }),
    );

    try {
      await Promise.all(updatePromises);

      const updatedNotifications = notifications.map((n) => ({
        ...n,
        lida: true,
      }));
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error("Erro ao marcar todas as notificações como lidas", error);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
        markAllAsRead,
        unreadCount, // Fornecendo o número de notificações não lidas
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
