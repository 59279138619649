import { PropsWithChildren } from "react";
import { Col, Row } from "react-bootstrap";

interface AuthSplitLayoutProps {
  bg: string;
}

const AuthSplitLayout = ({
  bg,
  children,
}: PropsWithChildren<AuthSplitLayoutProps>) => {
  return (
    <Row className="vh-100 g-0">
      {/* Parte Esquerda: 2/3 da tela */}
      <Col lg={8} className="position-relative d-none d-lg-block">
        <div
          className="bg-holder"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover", // Garante que a imagem cubra a área
            backgroundPosition: "center", // Centraliza a imagem
            filter: "brightness(1.02)", // Aumenta o brilho em 30% para deixar mais claro
          }}
        />
      </Col>

      {/* Parte Direita: 1/3 da tela */}
      <Col lg={4}>
        <Row className="flex-center h-100 g-0 px-4 px-sm-0">
          <Col sm={6} lg={8} xl={7}>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthSplitLayout;
