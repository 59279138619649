// SSI 0094 - GCOLLE - Pagina de Eventos, Ícone usuário. Pagina de Notificações corerção socket. - 17/01/2025
import React from "react";
import { Pagination, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

interface CustomFooterProps {
  currentPage: number;
  totalPages: number;
  onPrevious: () => void;
  onNext: () => void;
  onPageChange: (page: number) => void;
}

const CustomFooter = ({
  currentPage,
  totalPages,
  onPrevious,
  onNext,
  onPageChange,
}: CustomFooterProps) => {
  const visiblePages = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  ).slice(Math.max(0, currentPage - 3), Math.min(currentPage + 2, totalPages));

  return (
    <Row className="align-items-center py-1">
      <Col xs="auto">
        <Pagination className="mb-0 justify-content-center">
          <Pagination.Prev disabled={currentPage === 1} onClick={onPrevious}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Pagination.Prev>

          {visiblePages.map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}

          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={onNext}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Pagination.Next>
        </Pagination>
      </Col>
    </Row>
  );
};

export default CustomFooter;
