// RotasComPermissao.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "./components/UserContext";

interface RotasComPermissaoProps {
  requiredRoles?: string[];
  requiredAreas?: string[];
  requiredUsuarioCidade?: "Sim" | "Não";
  requiredAdvogadoExterno?: "Sim" | "Não";
  children: React.ReactNode;
}

//SSI 0101 - MATHEUS BOTELHO - 24/01/25 - INICIO

const RotasComPermissao: React.FC<RotasComPermissaoProps> = ({
  requiredRoles,
  requiredAreas,
  requiredUsuarioCidade,
  requiredAdvogadoExterno,
  children,
}) => {
  const { user } = useContext(UserContext);

  // Obter o usuário do localStorage como fallback
  const storedUser = localStorage.getItem("user");
  const currentUser = user || (storedUser ? JSON.parse(storedUser) : null);

  console.log("Usuário atual:", currentUser);

  if (!currentUser) {
    console.log("Usuário não autenticado. Redirecionando para /login.");
    return <Navigate to="/login" replace />;
  }

  const { area, funcao, usuario_cidade, advogado_externo } = currentUser;

  console.log("Requisitos da rota:", { requiredAreas, requiredRoles });
  console.log("Dados do usuário:", { area, funcao });

  const areaPermitida =
    !requiredAreas || requiredAreas.length === 0
      ? true
      : requiredAreas.includes(area);

  const funcaoPermitida =
    !requiredRoles || requiredRoles.length === 0
      ? true
      : requiredRoles.includes(funcao);

  const usuarioCidadeOk =
    requiredUsuarioCidade === undefined
      ? true
      : usuario_cidade === requiredUsuarioCidade;

  const advogadoExternoOk =
    requiredAdvogadoExterno === undefined
      ? true
      : advogado_externo === requiredAdvogadoExterno;

  // Para liberar o acesso, todas as condições precisam ser verdadeiras
  const permitido =
    areaPermitida && funcaoPermitida && usuarioCidadeOk && advogadoExternoOk;

  if (permitido) {
    console.log(
      `Permissão concedida (área: "${area}", função: "${funcao}", ` +
        `cidade: "${usuario_cidade}", advogado_externo: "${advogado_externo}").`,
    );
    return <>{children}</>;
  } else {
    console.log(`Permissão negada. Redirecionando para /.`);
    return <Navigate to="/" replace />;
  }
};

//SSI 0101 - MATHEUS BOTELHO - 24/01/25 - FIM

export default RotasComPermissao;
