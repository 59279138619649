// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { forwardRef } from "react";
import { Form, FloatingLabel, Row, Col, Button } from "react-bootstrap";

interface Step1Props {
  selectedData: any[];
  handleNext: () => void;
  honorario: string;
  setHonorario: (value: string) => void;
  valorHonorario: number | string;
  setValorHonorario: (value: string) => void;
  taxaAdministradora: string;
  setTaxaAdministradora: (value: string) => void;
  valorTaxaAdministradora: number | string;
  setValorTaxaAdministradora: (value: string) => void;
  tipoRecebimentoTaxa: string;
  setTipoRecebimentoTaxa: (value: string) => void;
  porcentagem: number;
  setPorcentagem: (value: number) => void;
  formRef: (el: HTMLFormElement | null) => void;
  setDadosApiPaginaInicial: (dados: Record<string, any>) => void;
}

const ModalAcordosEtapa1 = forwardRef<HTMLFormElement, Step1Props>(
  (
    {
      selectedData,
      honorario,
      setHonorario,
      valorHonorario,
      setValorHonorario,
      taxaAdministradora,
      setTaxaAdministradora,
      valorTaxaAdministradora,
      setValorTaxaAdministradora,
      tipoRecebimentoTaxa,
      setTipoRecebimentoTaxa,
      porcentagem,
      setPorcentagem,
      setDadosApiPaginaInicial,
    },
    ref,
  ) => {
    React.useEffect(() => {
      if (!valorHonorario) {
        setValorHonorario("10");
      }
    }, [valorHonorario, setValorHonorario]);

    React.useEffect(() => {
      // Formatar os dados para a API sempre que `selectedData` mudar
      if (selectedData.length > 0) {
        const dadosApi = {
          TIPO_ACORDO: 0,
          PRIORIZARHONORARIOS: "0",
          COBS_ORIGEM: selectedData.map((cobranca) => ({
            ID_RECEBIMENTO_RECB: cobranca.idCobranca,
            VL_ACORDOJUROS_RECB: cobranca.juros?.toFixed(2) || "0.00",
            VL_ACORDOMULTA_RECB: cobranca.multa?.toFixed(2) || "0.00",
            VL_ACORDOATUALIZACAO_RECB:
              cobranca.atualizacao?.toFixed(2) || "0.00",
            VL_ACORDOHONORARIOS_RECB: cobranca.honorarios?.toFixed(2) || "0.00",
            VL_ACORDODESCONTO_RECB: "0.00",
            VL_ACORDOCUSTOS_RECB: "0.00",
          })),
        };

        setDadosApiPaginaInicial(dadosApi);
      }
    }, [selectedData]);

    return (
      <>
        <Form
          ref={ref}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h6
              className="mb-5"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              Taxas de Cobrança
            </h6>
            <Row className="mb-2">
              <Col md={6} className="mb-3">
                <FloatingLabel controlId="honorario" label="Honorário">
                  <Form.Select
                    value={honorario}
                    onChange={(e) => setHonorario(e.target.value)}
                  >
                    <option value="taxaConvencionada">
                      Taxa Convencionada
                    </option>
                    <option value="naoCobrar">Não cobrar honorários</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              {honorario !== "naoCobrar" && (
                <Col md={6} className="mb-3">
                  <FloatingLabel controlId="valorHonorario" label="Valor (%)">
                    <Form.Control
                      type="number"
                      value={valorHonorario}
                      onChange={(e) => setValorHonorario(e.target.value)}
                    />
                  </FloatingLabel>
                </Col>
              )}
            </Row>
            <Row className="mb-5">
              <Col md={6} className="mb-3">
                <FloatingLabel
                  controlId="taxaAdministradora"
                  label="Taxa da Administradora"
                >
                  <Form.Select
                    value={taxaAdministradora}
                    onChange={(e) => setTaxaAdministradora(e.target.value)}
                  >
                    <option value="naoCobrar">Não cobrar</option>
                    <option value="cobrar">Cobrar</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              {taxaAdministradora === "cobrar" && (
                <Col md={6} className="mb-3">
                  <FloatingLabel
                    controlId="valorTaxaAdministradora"
                    label="Valor (%)"
                  >
                    <Form.Control
                      type="number"
                      value={valorTaxaAdministradora}
                      onChange={(e) =>
                        setValorTaxaAdministradora(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
              )}
            </Row>
          </div>
          <div>
            <h6
              className="mb-5"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              Como Receber as Taxas de Cobrança?
            </h6>
            <Row>
              <Col md={12} className="mb-4">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    id="todasParcelas"
                    label=""
                    value="todasParcelas"
                    checked={tipoRecebimentoTaxa === "todasParcelas"}
                    onChange={() => setTipoRecebimentoTaxa("todasParcelas")}
                    style={{
                      marginRight: "8px",
                      marginTop: "2px",
                    }}
                  />
                  <span style={{ fontSize: "16px" }}>
                    Receber em todas as parcelas
                  </span>
                </div>
              </Col>
              <Col md={12} className="mb-4">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    id="primeirasParcelas"
                    label=""
                    value="primeirasParcelas"
                    checked={tipoRecebimentoTaxa === "primeirasParcelas"}
                    onChange={() => setTipoRecebimentoTaxa("primeirasParcelas")}
                    style={{
                      marginRight: "8px",
                      marginTop: "2px",
                    }}
                  />
                  <span style={{ fontSize: "16px" }}>
                    Receber
                    <Form.Control
                      type="number"
                      value={porcentagem}
                      onChange={(e) => setPorcentagem(Number(e.target.value))}
                      className="mx-2 d-inline"
                      style={{
                        width: "80px",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    />
                    % das primeiras parcelas
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    );
  },
);

ModalAcordosEtapa1.displayName = "ModalAcordosEtapa1";

export default ModalAcordosEtapa1;
