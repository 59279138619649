// SSI 0080 - Rateio por filtro por condominio
import React, { useEffect, useState } from "react";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import PhoenixDocCard from "components/base/PhoenixDocCard";
import { Card, Col, Row, Tab, Button, Form } from "react-bootstrap";
import RateioTable from "./RateioTable"; // Continua usando sua tabela
import axios from "axios";
import { distance } from "fastest-levenshtein";
import ReactSelect from "components/base/ReactSelect"; // <---- IMPORTANTE!

// Interfaces
interface Rateio {
  conta: string;
  descricao: string;
  observacao: string;
  agrupar: boolean;
  valor: string;
  isTotal?: boolean;
}

interface Account {
  st_conta_cont: string;
  st_descricao_cont: string;
}

interface PlanoConta {
  id_planoconta_plc: string;
  st_nome_plc: string;
}

interface Condominio {
  id_cond: string;
  razao: string | null;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Rateio", active: true },
];

const RateioPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState("");
  const [tableData, setTableData] = useState<Rateio[]>([]); // array usado no RateioTable
  const [output, setOutput] = useState<string>("");

  // Estados do plano/condomínio
  const [condominios, setCondominios] = useState<Condominio[]>([]);
  const [selectedCondominioId, setSelectedCondominioId] = useState("");
  const [idPlanoConta, setIdPlanoConta] = useState("");
  const [nomePlanoConta, setNomePlanoConta] = useState("");
  const [todosPlanos, setTodosPlanos] = useState<PlanoConta[]>([]);
  const [selectedPlanoId, setSelectedPlanoId] = useState("");
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  // Tokens e API
  const app_token = process.env.REACT_APP_APP_TOKEN;
  const access_token = process.env.REACT_APP_ACCESS_TOKEN;
  const API_URL = process.env.REACT_APP_API_URL;

  // "Copiado" feedback
  const [copied, setCopied] = useState(false);

  // 1) Buscar condomínios
  useEffect(() => {
    const fetchCondominios = async () => {
      try {
        const response = await axios.get(`${API_URL}/condominios/conds`);
        if (Array.isArray(response.data)) {
          setCondominios(response.data);
        } else {
          console.error(
            "Formato inesperado ao buscar condomínios",
            response.data,
          );
        }
      } catch (error) {
        console.error("Não foi possível buscar os condomínios", error);
      }
    };
    fetchCondominios();
  }, [API_URL]);

  // Ao selecionar um condomínio, buscar plano de contas
  const handleCondominioSelect = async (condominioId: string) => {
    setSelectedCondominioId(condominioId);
    if (!condominioId) {
      setTodosPlanos([]);
      setSelectedPlanoId("");
      setIdPlanoConta("");
      setNomePlanoConta("");
      return;
    }

    try {
      // 1) Buscar o ID do plano de contas
      const response1 = await axios.get(
        `https://api.superlogica.net/v2/condor/planocontas/index/?ID_CONDOMINIO_COND=${condominioId}`,
        {
          headers: {
            "Content-Type": "application/json",
            app_token,
            access_token,
          },
        },
      );

      const firstPlanoContaId = response1.data[0]?.id_planoconta_plc;
      if (!firstPlanoContaId) {
        throw new Error("Nenhum id_planoconta_plc encontrado.");
      }

      // 2) Buscar todos os planos (ID_CONDOMINIO_COND=-1)
      const response2 = await axios.get(
        `https://api.superlogica.net/v2/condor/planocontas/index/?ID_CONDOMINIO_COND=-1`,
        {
          headers: {
            "Content-Type": "application/json",
            app_token,
            access_token,
          },
        },
      );

      const planoContaEncontrado = response2.data.find(
        (item: PlanoConta) => item.id_planoconta_plc === firstPlanoContaId,
      );
      if (!planoContaEncontrado) {
        throw new Error("Nenhum plano de conta correspondente encontrado.");
      }

      setIdPlanoConta(firstPlanoContaId);
      setNomePlanoConta(planoContaEncontrado.st_nome_plc);
      setSelectedPlanoId(firstPlanoContaId);

      // Opcional: já carrega as contas do plano
      const response3 = await axios.get(
        `https://api.superlogica.net/v2/condor/planocontas?id=${firstPlanoContaId}`,
        {
          headers: { app_token, access_token },
        },
      );
      // filtra as que começam com '2', conforme seu snippet
      const filtered = response3.data.filter((a: Account) =>
        a.st_conta_cont.startsWith("2"),
      );
      setAccounts(filtered);
    } catch (error) {
      console.error("Erro ao selecionar condomínio:", error);
    }
  };

  // Reset
  const handleReset = () => {
    setFormData("");
    setTableData([]);
    setOutput("");
    setStep(1);

    setSelectedCondominioId("");
    setTodosPlanos([]);
    setSelectedPlanoId("");
    setAccounts([]);
    setIdPlanoConta("");
    setNomePlanoConta("");
  };

  // Muda o valor do Textarea
  const handleRateioEntriesChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setFormData(event.target.value);
  };

  // ------------------------------------------------------------
  // processRateioEntries - Substitui o antigo "processFormData"
  // ------------------------------------------------------------
  const processRateioEntries = async () => {
    try {
      // Carrega as contas do plano (se ainda não tiver carregado):
      if (accounts.length === 0 && selectedPlanoId) {
        const resp = await axios.get(
          `https://api.superlogica.net/v2/condor/planocontas?id=${selectedPlanoId}`,
          { headers: { app_token, access_token } },
        );
        const filtered = resp.data.filter((a: Account) =>
          a.st_conta_cont.startsWith("2"),
        );
        setAccounts(filtered);
      }

      // Faz o parse das linhas
      const lines = formData.split("\n");
      const entries: Rateio[] = [];
      const accountCounts: Record<string, number> = {};
      let pendingDescription = "";

      lines.forEach((line) => {
        // (Usar const, pois não será reatribuído)
        if (
          line.length > 55 &&
          !/\d{1,3}(?:\.\d{3})*,\d{2}(?=\s|$|\D)/.test(line)
        ) {
          return;
        }

        const description = line.substring(0, 50).trim().toUpperCase();
        const isTotalLine = description.includes("TOTAL");
        const valueMatch = line
          .substring(50)
          .trim()
          .match(/\d{1,3}(?:\.\d{3})*,\d{2}(?=\s|$|\D)/);
        const valueStr = valueMatch ? valueMatch[0] : null;
        const value = valueStr
          ? parseFloat(valueStr.replace(/\./g, "").replace(",", "."))
          : NaN;

        if (isTotalLine) {
          entries.push({
            descricao: description,
            valor: isNaN(value) ? "0,00" : value.toFixed(2).replace(".", ","),
            agrupar: false,
            isTotal: true,
            conta: "",
            observacao: "",
          });
          return;
        }

        if (!valueStr && pendingDescription) {
          // Concatena a descrição anterior com a atual
          pendingDescription += " " + description;
        } else {
          let finalDesc = description;
          if (pendingDescription) {
            finalDesc = pendingDescription + " " + description;
            pendingDescription = "";
          }

          if (!isNaN(value)) {
            // Tenta achar conta exata
            let observation = "";
            let account = accounts.find(
              (a) => a.st_descricao_cont.trim().toUpperCase() === finalDesc,
            );

            // Se não achou, usa Levenshtein
            if (!account) {
              const descriptionParts = finalDesc.split("-");
              if (descriptionParts.length > 1) {
                finalDesc = descriptionParts[0].trim();
                observation = descriptionParts.slice(1).join("-").trim();
              }

              let minDistance = Infinity;
              let bestAccount: Account | undefined = undefined;
              accounts.forEach((a) => {
                const d = distance(
                  a.st_descricao_cont.trim().toUpperCase(),
                  finalDesc,
                );
                if (d < minDistance) {
                  minDistance = d;
                  bestAccount = a;
                }
              });
              account = bestAccount;
            }

            const entry: Rateio = {
              conta: account ? account.st_conta_cont.replace(/\.$/, "") : "",
              descricao: finalDesc,
              observacao: observation,
              valor: isNaN(value) ? "0,00" : value.toFixed(2).replace(".", ","),
              agrupar: false,
              isTotal: false,
            };

            if (entry.conta) {
              accountCounts[entry.conta] =
                (accountCounts[entry.conta] || 0) + 1;
            }
            entries.push(entry);
          } else {
            // Linha sem valor => pending
            pendingDescription = finalDesc;
          }
        }
      });

      // Marca agrupar = true se a conta apareceu mais de uma vez
      const newEntries: Rateio[] = entries.map((entry) => ({
        ...entry,
        agrupar: !!entry.conta && accountCounts[entry.conta] > 1,
      }));

      // Salva no state da Tabela
      setTableData(newEntries);
      setStep(2);
    } catch (error) {
      console.error("Erro ao processar entradas de rateio:", error);
    }
  };

  // ------------------------------------------------------------
  // handleExport - Substitui o antigo "processTableData"
  // ------------------------------------------------------------
  const handleExport = () => {
    try {
      // Precisamos agrupar as entradas do tableData
      const entries = tableData;

      // 1) Agregar as entradas
      const aggregatedList: Rateio[] = [];
      const aggregatedEntriesByAccount: Record<string, Rateio> = {};

      entries.forEach((entry) => {
        if (!entry.agrupar) {
          aggregatedList.push(entry);
          return;
        }
        if (!aggregatedEntriesByAccount[entry.conta]) {
          aggregatedEntriesByAccount[entry.conta] = { ...entry };
          aggregatedList.push(aggregatedEntriesByAccount[entry.conta]);
        } else {
          const existingEntry = aggregatedEntriesByAccount[entry.conta];
          const newValue =
            parseFloat(existingEntry.valor.replace(",", ".")) +
            parseFloat(entry.valor.replace(",", "."));
          existingEntry.valor = newValue.toFixed(2).replace(".", ",");
        }
      });

      // 2) Dividir em grupos, separando a cada "isTotal"
      const groups: Rateio[][] = [];
      let currentGroup: Rateio[] = [];

      for (const entry of aggregatedList) {
        if (entry.isTotal) {
          currentGroup.push(entry);
          groups.push(currentGroup);
          currentGroup = [];
        } else {
          currentGroup.push(entry);
        }
      }
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }

      // 3) Montar o texto final
      let outputString = "DÉBITOS QUOTA CONDOMINIAL\n";

      for (const group of groups) {
        // Procura a linha total
        const totalEntry = group.find((e) => e.isTotal);
        if (totalEntry) {
          const groupName = totalEntry.descricao
            .replace("TOTAL DE", "")
            .replace(":", "")
            .trim();
          if (groupName !== "TOTAL") {
            outputString += ` ${groupName}\n`;
          }
        }

        for (const entry of group) {
          const formattedValue = parseFloat(entry.valor.replace(",", "."))
            .toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
            .slice(3)
            .padStart(11, " "); // 10 dígitos + 1 de segurança

          const description = entry.descricao.toUpperCase();
          const observation = entry.observacao
            ? entry.observacao.toUpperCase()
            : "";
          let combinedText = `${description} ${observation}`.trim();

          // Espaço disponível = 68 - espaço do valor
          const availableSpace = 68 - formattedValue.length;
          if (combinedText.length > availableSpace) {
            combinedText = combinedText.substring(0, availableSpace);
          }

          const line =
            combinedText.padEnd(68 - formattedValue.length, " ") +
            formattedValue;
          outputString += line + "\n";

          if (entry.isTotal) {
            outputString += "\n";
          }
        }
      }

      setOutput(outputString);
      setStep(2);
    } catch (err) {
      console.error("Erro ao exportar/gerar resultado:", err);
    }
  };

  // ------------------------------------------------------------
  // Handlers de Observação e Agrupar (para a tabela RateioTable)
  // ------------------------------------------------------------
  const handleObservationChange = (index: number, newObservation: string) => {
    setTableData((prevData) => {
      const updated = [...prevData];
      updated[index].observacao = newObservation;
      return updated;
    });
  };

  const handleAgruparChange = (index: number, checked: boolean) => {
    setTableData((prevData) => {
      const updated = [...prevData];
      updated[index].agrupar = checked;
      return updated;
    });
  };

  // Copiar
  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(output)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((err) => console.error("Erro ao copiar:", err));
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = output;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      } catch (err) {
        console.error("Erro ao copiar:", err);
      }
      document.body.removeChild(textarea);
    }
  };

  // Efeito de scroll (opcional)
  const [scrollPosition, setScrollPosition] = useState(0);
  const updateScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);
  const getStepColor = (stepNumber: number) => {
    const thresholds = [70, 400, 1100];
    const isActive = scrollPosition >= thresholds[stepNumber - 1];
    return isActive ? "#0e94ff" : "gray";
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <h2 className="mb-0" style={{ textAlign: "left" }}>
        Rateio
      </h2>

      <div className="d-flex justify-content-end mb-3">
        <Button variant="outline-danger" onClick={handleReset}>
          <i className="fas fa-undo-alt"></i> Resetar
        </Button>
      </div>

      <Row>
        {/* Card da esquerda */}
        <Col xs={12} xxl={6}>
          <PhoenixDocCard className="mb-4">
            <PhoenixDocCard.Header title="Preencher Dados" />
            <PhoenixDocCard.Body>
              <Tab.Container activeKey={step}>
                <Card>
                  <Card.Header>
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <Card.Body>
                          {/* AQUI substituímos o antigo Form.Control pelo ReactSelect */}
                          <Form.Group className="mb-3">
                            <Form.Label>Selecionar um Condomínio</Form.Label>
                            <ReactSelect
                              options={condominios.map((cond) => ({
                                value: cond.id_cond,
                                label: cond.razao,
                              }))}
                              placeholder="Selecione..."
                              value={
                                selectedCondominioId
                                  ? condominios
                                      .map((cond) => ({
                                        value: cond.id_cond,
                                        label: cond.razao,
                                      }))
                                      .find(
                                        (o) => o.value === selectedCondominioId,
                                      )
                                  : null
                              }
                              onChange={(newValue: unknown) => {
                                const option = newValue as {
                                  value: string;
                                } | null;
                                handleCondominioSelect(option?.value || "");
                              }}
                            />
                          </Form.Group>

                          {idPlanoConta && nomePlanoConta && (
                            <div className="d-flex align-items-center mb-2">
                              <div className="mx-3 me-3">
                                <strong>Id:</strong> {idPlanoConta}
                              </div>
                              <div>
                                <strong>Plano de Conta:</strong>{" "}
                                {nomePlanoConta}
                              </div>
                            </div>
                          )}

                          <Form.Group>
                            <Form.Label>Cole o Rateio</Form.Label>
                            <textarea
                              value={formData}
                              onChange={handleRateioEntriesChange}
                              placeholder="Insira os dados aqui..."
                              className="form-control"
                              style={{ minHeight: "232px" }}
                            />
                          </Form.Group>

                          <div className="d-flex justify-content-end mt-3">
                            <Button
                              variant="primary"
                              onClick={processRateioEntries}
                            >
                              Próximo
                            </Button>
                          </div>
                        </Card.Body>
                      </Tab.Pane>

                      <Tab.Pane eventKey={2}>
                        <Card.Body>
                          <h4>Revisar Dados</h4>

                          {/* Mantemos o RateioTable */}
                          <RateioTable
                            data={tableData}
                            onObservationChange={handleObservationChange}
                            onAgruparChange={handleAgruparChange}
                          />

                          <div className="d-flex justify-content-between mt-3">
                            <Button variant="primary" onClick={handleExport}>
                              Exibir Resultado
                            </Button>
                          </div>
                        </Card.Body>
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Header>
                </Card>
              </Tab.Container>
            </PhoenixDocCard.Body>
          </PhoenixDocCard>
        </Col>

        {/* Card da direita */}
        <Col xs={12} xxl={6}>
          <PhoenixDocCard className="mb-4">
            <PhoenixDocCard.Header title="Resultado Final" />
            <PhoenixDocCard.Body>
              {output ? (
                <div>
                  <textarea
                    value={output}
                    readOnly
                    className="form-control mb-3"
                    style={{ minHeight: "432px" }}
                  />
                  <div className="d-flex justify-content-end">
                    <Button variant="success" onClick={handleCopy}>
                      {copied ? "COPIADO!" : "Copiar Resultado"}
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "485px" }}
                >
                  <p>Aguardando dados para exibir o resultado final.</p>
                </div>
              )}
            </PhoenixDocCard.Body>
          </PhoenixDocCard>
        </Col>
      </Row>
    </div>
  );
};

export default RateioPage;
