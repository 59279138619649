import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useContext } from "react"; //SSI 0092 - MATHEUS BOTELHO - 15/01/2025
import { Collapse, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Route } from "sitemap";
import { capitalize } from "helpers/utils";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useNavbarVerticalCollapse } from "./NavbarVerticalCollapseProvider";
import Badge from "components/base/Badge";
import { useAppContext } from "providers/AppProvider";
import UserContext from "components/UserContext"; //SSI 0092 - MATHEUS BOTELHO - 15/01/2025

interface NavbarVerticalMenuProps {
  routes: Route[];
  level: number;
}

interface NavItemProps {
  route: Route;
  level: number;
}

const NavItem = ({ route, level }: NavItemProps) => {
  const {
    config: { isNavbarVerticalCollapsed },
  } = useAppContext();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  return (
    <Nav.Item as="li">
      <NavLink
        to={route.path ? route.path : "#!"}
        className={({ isActive }) =>
          classNames("nav-link", {
            "label-1": level === 1,
            active: isActive && route.path !== "#!",
          })
        }
        onClick={() => level === 1 && setOpenItems(openItems.map(() => ""))}
      >
        <div
          className={classNames("d-flex align-items-center", {
            "text-300": !route.active,
          })}
        >
          {route.icon ? (
            <>
              <span
                className={classNames("nav-link-icon", {
                  new: route.new || route.hasNew,
                })}
              >
                {route.iconSet === "font-awesome" ? (
                  <FontAwesomeIcon
                    icon={route.icon as IconProp}
                    transform={{ size: 16 }}
                  />
                ) : (
                  <FeatherIcon icon={route.icon} size={16} />
                )}
              </span>
              <span className="nav-link-text-wrapper">
                <span className="nav-link-text">{capitalize(route.name)}</span>
                {route.new && !isNavbarVerticalCollapsed && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </span>
            </>
          ) : (
            <>
              <span className="nav-link-text">{capitalize(route.name)}</span>
              {route.new && (
                <Badge variant="phoenix" bg="info" className="ms-2">
                  New
                </Badge>
              )}
            </>
          )}
        </div>
      </NavLink>
    </Nav.Item>
  );
};

const CollapsableNavItem = ({ route, level }: NavItemProps) => {
  const { pathname } = useLocation();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  const {
    config: { isNavbarVerticalCollapsed },
  } = useAppContext();

  const openCollapse = (childrens: Route[] = []) => {
    const checkLink = (children: Route) => {
      if (`${children.path}` === pathname) {
        return true;
      }
      return children.pages && children.pages.some(checkLink);
    };
    return childrens.some(checkLink);
  };

  const updateOpenItems = (name: string) => {
    const updatedOpenItems = [...openItems];
    updatedOpenItems[level] = name;
    updatedOpenItems.forEach((item, index) => {
      if (index > level) {
        updatedOpenItems[index] = "";
      }
    });
    setOpenItems(updatedOpenItems);
  };

  useEffect(() => {
    if (openCollapse(route.pages)) {
      updateOpenItems(route.name);
    }
  }, []);

  return (
    <>
      <Nav.Link
        onClick={() => {
          if (route.name === openItems[level]) {
            updateOpenItems("");
          } else {
            updateOpenItems(route.name);
          }
        }}
        className={classNames("dropdown-indicator", {
          "label-1": level === 1,
          collapsed: openItems[level] !== route.name,
          "text-300": !route.active,
        })}
        aria-expanded={openItems[level] === route.name}
      >
        <div className="d-flex align-items-center">
          <div className="dropdown-indicator-icon">
            <FontAwesomeIcon
              icon={faCaretRight}
              className={classNames({
                "text-300": !route.active,
              })}
            />
          </div>
          {level === 1 && (
            <span
              className={classNames("nav-link-icon", {
                new: route.new || route.hasNew,
              })}
            >
              <FeatherIcon icon={route.icon} size={16} />
            </span>
          )}
          <span
            className={classNames("nav-link-text", {
              new: route.hasNew,
            })}
          >
            {capitalize(route.name)}
            {(!isNavbarVerticalCollapsed || level !== 1) && route.new && (
              <Badge variant="phoenix" bg="info" className="ms-2">
                New
              </Badge>
            )}
          </span>
        </div>
      </Nav.Link>
      <div
        className={classNames("parent-wrapper", {
          "label-1": level === 1,
        })}
      >
        <Collapse in={openItems[level] === route.name} className="nav parent">
          <div>
            {level === 1 && (
              <div className="collapsed-nav-item-title d-none">
                {capitalize(route.name)}
                {isNavbarVerticalCollapsed && route.new && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </div>
            )}
            <NavbarVerticalMenu routes={route.pages || []} level={level + 1} />
          </div>
        </Collapse>
      </div>
    </>
  );
};

//SSI 0092 - MATHEUS BOTELHO - 15/01/2025 - INICIO
//SSI 0101 - MATHEUS BOTELHO - 24/01/25 - INICIO

const NavbarVerticalMenu = ({ routes, level }: NavbarVerticalMenuProps) => {
  const { user } = useContext(UserContext); // Obtenha o usuário atual do contexto

  // Função para verificar se o usuário tem permissão para acessar uma rota
  function userHasPermissionToRoute(user: any, route: Route): boolean {
    const { funcao, area, usuario_cidade, advogado_externo } = user || {};

    // Se a rota não especificar requiredPermissions, está liberada
    if (!route.requiredPermissions) {
      return true;
    }

    // Separar as áreas do usuário, caso existam várias
    const userAreas = area ? area.split(",").map((a: string) => a.trim()) : [];

    // Verifica se o usuário atende a pelo menos uma condição
    return route.requiredPermissions.some((permission) => {
      const funcaoOk = !permission.funcao || permission.funcao === funcao;

      // Verifica se pelo menos uma das áreas do usuário atende à condição
      const areaOk =
        !permission.area ||
        userAreas.some((userArea: string) => userArea === permission.area);

      // Se permissão não exigir usuario_cidade, é true; caso exija, compara
      const usuarioCidadeOk =
        !permission.usuario_cidade ||
        permission.usuario_cidade === usuario_cidade;

      // Se permissão não exigir advogado_externo, é true; caso exija, compara
      const advogadoExternoOk =
        !permission.advogado_externo ||
        permission.advogado_externo === advogado_externo;

      // Para que uma das permissões (um dos objetos do array) seja atendida,
      // todas as condições nesse objeto devem ser verdadeiras
      return funcaoOk && areaOk && usuarioCidadeOk && advogadoExternoOk;
    });
  }

  // Função recursiva para filtrar rotas acessíveis
  function filterRoutes(routes: Route[], user: any): Route[] {
    return routes
      .filter((route) => userHasPermissionToRoute(user, route)) // Filtra rotas permitidas
      .map((route) => {
        const newRoute: Route = { ...route }; // Use `const` aqui
        if (newRoute.pages && newRoute.pages.length > 0) {
          newRoute.pages = filterRoutes(newRoute.pages, user); // Filtra sub-rotas recursivamente
        }
        return newRoute;
      });
  }

  // Filtra as rotas com base nas permissões do usuário
  const filteredRoutes = filterRoutes(routes, user);

  return (
    <>
      {filteredRoutes.map((route: Route) => (
        <div key={route.name}>
          {level === 1 ? (
            <div className="nav-item-wrapper">
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} />
              ) : (
                <NavItem route={route} level={level} />
              )}
            </div>
          ) : (
            <>
              {route.pages ? (
                <CollapsableNavItem route={route} level={level} />
              ) : (
                <NavItem route={route} level={level} />
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

//SSI 0101 - MATHEUS BOTELHO - 24/01/25 - FIM

//SSI 0092 - MATHEUS BOTELHO - 15/01/2025 - FIM

export default NavbarVerticalMenu;
