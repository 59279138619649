/* ***********************************
Autor: Matheus Botelho
Data: 16/04/2024
Atividade: Automação Cadastro
Função: Modal de Confirmação para Notificação do Síndico
************************************ */

import React from "react";
import { Modal, Button } from "react-bootstrap";

const NotificarSindico = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Modal show={isOpen} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Notificar Síndico</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Deseja notificar o Síndico sobre o novo Morador?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Não
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificarSindico;
