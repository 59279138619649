import React from "react";
import ReactDOM from "react-dom/client";
import AppProvider from "providers/AppProvider";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";
import { UserProvider } from "components/UserContext"; // Use apenas UserProvider
import { NotificationsProvider } from "./context/NotificationsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <UserProvider>
      <NotificationsProvider>
        <AppProvider>
          <SettingsPanelProvider>
            <BreakpointsProvider>
              <RouterProvider router={router} />
            </BreakpointsProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </NotificationsProvider>
    </UserProvider>
  </React.StrictMode>,
);
