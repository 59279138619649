// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import useWizardForm from "hooks/useWizardForm";
import ModalAcordosEtapa1 from "./etapas/ModalEtapa1";
import ModalAcordosEtapa2 from "./etapas/ModalEtapa2";
import ModalAcordosEtapa3 from "./etapas/ModalEtapa3";
import ModalAcordosEtapa4 from "./etapas/ModalEtapa4";
import ModalAcordosEtapa5 from "./etapas/ModalEtapa5";

interface Configuracoes {
  honorario: string;
  valorHonorario: number;
  taxaAdministradora: string;
  valorTaxaAdministradora: number;
  tipoRecebimentoTaxa: string;
  porcentagemRecebimento: number | null;
}

interface ModalAcordosProps {
  show: boolean;
  onHide: () => void;
  selectedData: any[];
  REACT_APP_API_URL: string;
  idCondominio: string;
  onToast?: (message: string, type: "success" | "error") => void;
}

const ModalAcordos: React.FC<ModalAcordosProps> = ({
  show,
  onHide,
  selectedData,
  REACT_APP_API_URL,
  idCondominio,
  onToast,
}) => {
  const wizard = useWizardForm({ totalStep: 5, validation: true }, {});
  const [searchParams] = useSearchParams();
  const idUnidade = searchParams.get("idUnidade") || "";

  // Estados relacionados a honorários, taxas etc.
  const [honorario, setHonorario] = useState("taxaConvencionada");
  const [totalEtapa2, setTotalEtapa2] = useState<number>(0);
  const [valorHonorario, setValorHonorario] = useState("");
  const [taxaAdministradora, setTaxaAdministradora] = useState("naoCobrar");
  const [valorTaxaAdministradora, setValorTaxaAdministradora] = useState("");

  // Estados para armazenar dados intermediários de cada etapa
  const [dadosPrimeiraPagina, setDadosPrimeiraPagina] = useState<Record<
    string,
    any
  > | null>(null);
  const [dadosSegundaPagina, setDadosSegundaPagina] = useState<any>(null);
  const [dadosTerceiraPagina, setDadosTerceiraPagina] = useState<any>(null);
  const [dadosQuartaPagina, setDadosQuartaPagina] = useState<any>(null);
  const [dadosQuintaPagina, setDadosQuintaPagina] = useState<Record<
    string,
    any
  > | null>(null);

  // Estados para armazenamento de parcelas geradas
  const [parcelasGeradas, setParcelasGeradas] = useState<{
    numeroParcelas: number;
    parcelas: { vencimento: string; valor: number; observacao: string }[];
  }>({
    numeroParcelas: 0,
    parcelas: [],
  });

  // Estado para tipo de recebimento de taxa e porcentagem
  const [tipoRecebimentoTaxa, setTipoRecebimentoTaxa] =
    useState("todasParcelas");
  const [porcentagem, setPorcentagem] = useState(0);

  const [formaRecebimento, setFormaRecebimento] = useState<number>(1);

  // Estados para conta bancária e data
  const [contasBancariasAtivas, setContasBancariasAtivas] = useState<
    { value: string; label: string }[]
  >([
    { value: "conta1", label: "Conta 1 - Banco A" },
    { value: "conta2", label: "Conta 2 - Banco B" },
  ]);
  const [contaBancaria, setContaBancaria] = useState<string>("conta1");
  const [dataHoje, setDataHoje] = useState<string>(() => {
    const hoje = new Date();
    return hoje.toISOString().split("T")[0]; // Formato 'YYYY-MM-DD'
  });

  // Estado para registrar no CRM
  const [registrarCRM, setRegistrarCRM] = useState<boolean>(false);

  // Configurações gerais de honorários/taxas
  const configuracoes: Configuracoes = {
    honorario,
    valorHonorario: parseFloat(valorHonorario) || 0,
    taxaAdministradora,
    valorTaxaAdministradora: parseFloat(valorTaxaAdministradora) || 0,
    tipoRecebimentoTaxa,
    porcentagemRecebimento:
      tipoRecebimentoTaxa === "primeirasParcelas" ? porcentagem : null,
  };

  useEffect(() => {
    if (show) {
      wizard.startOver();
    }
  }, [show]);

  const handleNext = () => {
    wizard.goToStep(wizard.selectedStep + 1);
  };

  const handleBack = () => {
    wizard.goToStep(wizard.selectedStep - 1);
  };

  const renderStepContent = () => {
    switch (wizard.selectedStep) {
      case 1:
        return (
          <ModalAcordosEtapa1
            selectedData={selectedData}
            handleNext={handleNext}
            honorario={honorario}
            setHonorario={setHonorario}
            valorHonorario={valorHonorario}
            setValorHonorario={setValorHonorario}
            taxaAdministradora={taxaAdministradora}
            setTaxaAdministradora={setTaxaAdministradora}
            valorTaxaAdministradora={valorTaxaAdministradora}
            setValorTaxaAdministradora={setValorTaxaAdministradora}
            tipoRecebimentoTaxa={tipoRecebimentoTaxa}
            setTipoRecebimentoTaxa={setTipoRecebimentoTaxa}
            porcentagem={porcentagem}
            setPorcentagem={setPorcentagem}
            formRef={(el: HTMLFormElement | null) =>
              (wizard.formRefs.current[0] = el)
            }
            setDadosApiPaginaInicial={setDadosPrimeiraPagina}
          />
        );
      case 2:
        return (
          <ModalAcordosEtapa2
            selectedData={selectedData}
            configuracoes={configuracoes}
            handleNext={handleNext}
            REACT_APP_API_URL={REACT_APP_API_URL}
            idCondominio={idCondominio}
            formRef={(el: HTMLFormElement | null) =>
              (wizard.formRefs.current[1] = el)
            }
            updateTotal={(total: number) => setTotalEtapa2(total)}
            setDadosEtapa2={(dados) => setDadosSegundaPagina(dados)}
          />
        );
      case 3:
        return (
          <ModalAcordosEtapa3
            configuracoes={configuracoes}
            handleNext={handleNext}
            selectedData={selectedData}
            REACT_APP_API_URL={REACT_APP_API_URL}
            idCondominio={idCondominio}
            formRef={(el: HTMLFormElement | null) =>
              (wizard.formRefs.current[2] = el)
            }
            totalEtapa2={totalEtapa2}
            setParcelasGeradas={(parcelas) =>
              setParcelasGeradas({
                numeroParcelas: parcelas.length,
                parcelas,
              })
            }
            setDadosTerceiraPagina={(dados) => setDadosTerceiraPagina(dados)}
          />
        );
      case 4:
        return (
          <ModalAcordosEtapa4
            configuracoes={configuracoes}
            selectedData={selectedData}
            handleNext={handleNext}
            REACT_APP_API_URL={REACT_APP_API_URL}
            idCondominio={idCondominio}
            formRef={(el: HTMLFormElement | null) =>
              (wizard.formRefs.current[3] = el)
            }
            parcelas={parcelasGeradas.parcelas}
            setDadosQuartaPagina={(dados) => setDadosQuartaPagina(dados)}
          />
        );
      case 5:
        return (
          <ModalAcordosEtapa5
            formaRecebimento={formaRecebimento}
            setFormaRecebimento={setFormaRecebimento}
            configuracoes={configuracoes}
            selectedData={selectedData}
            handleConfirm={onHide}
            REACT_APP_API_URL={REACT_APP_API_URL}
            idCondominio={idCondominio}
            onComplete={onHide}
            formRef={(el: HTMLFormElement | null) =>
              (wizard.formRefs.current[4] = el)
            }
            contasBancariasAtivas={contasBancariasAtivas}
            setContasBancariasAtivas={setContasBancariasAtivas}
            contaBancaria={contaBancaria}
            setContaBancaria={setContaBancaria}
            dataHoje={dataHoje}
            setDataHoje={setDataHoje}
            registrarCRM={registrarCRM}
            setRegistrarCRM={setRegistrarCRM}
            setDadosQuintaPagina={setDadosQuintaPagina}
          />
        );
      default:
        return null;
    }
  };

  // Consolida todos os dados das etapas
  const dadosAcordo = {
    ...dadosPrimeiraPagina,
    ID_UNIDADE_UNI: idUnidade,
    ID_CONDOMINIO_COND: idCondominio,
    ...dadosSegundaPagina,
    ...dadosTerceiraPagina,
    ...dadosQuartaPagina,
    ...dadosQuintaPagina,
  };

  const finalizarAcordos = async () => {
    const dadosParaEnvio = new URLSearchParams();

    // Função para achatar os dados (transformar em chave=valor no URLSearchParams)
    const achatarDados = (obj: object, prefix = "") => {
      Object.entries(obj).forEach(([chave, valor]) => {
        const chaveFormatada = prefix ? `${prefix}[${chave}]` : chave;

        if (Array.isArray(valor)) {
          valor.forEach((item, index) =>
            achatarDados(item, `${chaveFormatada}[${index}]`),
          );
        } else if (typeof valor === "object" && valor !== null) {
          achatarDados(valor, chaveFormatada);
        } else {
          dadosParaEnvio.append(chaveFormatada, String(valor));
        }
      });
    };

    // Achatar os dados consolidados
    achatarDados(dadosAcordo);

    try {
      const response = await fetch(
        `${REACT_APP_API_URL}/efetuar-acordos/efetuar-acordo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: dadosParaEnvio,
        },
      );

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.mensagem) {
        onToast?.("Acordo realizado com sucesso!", "success");

        // Fechar o modal após o sucesso
        onHide();
      } else {
        onToast?.("Nenhuma mensagem retornada pelo servidor.", "error");
      }
    } catch (erro) {
      onToast?.("Erro ao finalizar os acordos.", "error");
      console.error("Erro ao finalizar os acordos:", erro);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Efetuar Acordo - Etapa {wizard.selectedStep} de {wizard.totalStep}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "450px", overflowY: "auto" }}>
        {renderStepContent()}
      </Modal.Body>
      <Modal.Footer>
        {wizard.getCanPreviousPage && (
          <Button variant="secondary" onClick={handleBack}>
            Voltar
          </Button>
        )}
        {wizard.getCanNextPage ? (
          <Button variant="primary" onClick={handleNext}>
            Próximo
          </Button>
        ) : (
          <Button variant="success" onClick={finalizarAcordos}>
            Confirmar Acordo
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAcordos;
