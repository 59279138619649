/* ***********************************
Autor: Matheus Botelho
Data: 08/04/2024
Atividade: Modal de Sucesso
Função: Padronizar a mensagem de sucesso com um modal
************************************ */

import React from "react";
import { Modal, Button } from "react-bootstrap";

const SuccessModal = ({ isOpen, message, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Sucesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
