// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface ICondominio {
  id_cond: number;
  CONDOMINIO: string;
  CIDADE: string;
}

interface TableCondominioCidadeProps {
  condominios: ICondominio[];
  loading?: boolean;
}

const TableCondominioCidade: React.FC<TableCondominioCidadeProps> = ({
  condominios,
  loading = false,
}) => {
  const columns = [
    { accessorKey: "id_cond", header: "ID Eureka" },
    { accessorKey: "CONDOMINIO", header: "Condomínio" },
    { accessorKey: "CIDADE", header: "Cidade" },
  ];

  const table = useAdvanceTable({
    data: condominios,
    columns,
    pageSize: 1000,
    pagination: true,
    sortable: true,
  });

  return (
    <Card>
      <Card.Header className="text-center">
        <h4>Condomínios Relacionados à Cidade</h4>
      </Card.Header>
      {loading ? (
        <div className="d-flex flex-column justify-content-center align-items-center py-4">
          <Spinner animation="border" />
          <span className="mt-3">Carregando dados, aguarde...</span>
        </div>
      ) : (
        <Card.Body className="p-0">
          <div
            style={{
              maxHeight: "524px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "1rem",
            }}
            className="bg-white border-top border-bottom border-200 position-relative top-1"
          >
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                tableProps={{
                  className:
                    "phoenix-table fs-9 table-hover text-center align-left",
                }}
              />

              {/* Caso não haja registros */}
              {condominios.length === 0 && (
                <div className="text-center p-3 text-muted py-10">
                  Nenhum condomínio encontrado
                </div>
              )}
            </AdvanceTableProvider>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default TableCondominioCidade;
