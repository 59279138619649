// SSI 0095 - GCOLLE - Criar Notificação - 17/01/2025
import React, { useState, useEffect } from "react";
import PageBreadcrumb, {
  PageBreadcrumbItem,
} from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Gestor {
  id_func: string;
  nome: string;
}

interface Condominio {
  id_cond: string;
  CONDOMINIO: string;
  fantasia?: string;
  chave?: string;
  cidade?: string;
}

export const timelineBreadcrumb: PageBreadcrumbItem[] = [
  { label: "Home", link: "/home" },
  { label: "Eventos", active: true },
];

const CriarNotificacao = () => {
  const [userIdsToSend, setUserIdsToSend] = useState<string[]>([]);
  const [titleToSend, setTitleToSend] = useState("");
  const [messageToSend, setMessageToSend] = useState("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const [userType, setUserType] = useState("Todos os Usuários");
  const [areaToSend, setAreaToSend] = useState("");
  const [usuariosAtivos, setUsuariosAtivos] = useState<any[]>([]);
  const [gestores, setGestores] = useState<Gestor[]>([]);
  const [customUserList, setCustomUserList] = useState<
    { id: string; name: string }[]
  >([{ id: "", name: "" }]);
  const [condominioId, setCondominioId] = useState("");
  const [condominios, setCondominios] = useState<Condominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<Condominio | null>(null);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const newSocket = io(process.env.ORIGIN_API || "http://localhost:3000");
    setSocket(newSocket);
    return () => {
      newSocket.close();
      setSocket(null);
    };
  }, []);

  useEffect(() => {
    const fetchUsuariosAtivos = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterUsuariosAtivos`,
        );
        setUsuariosAtivos(response.data);
      } catch (error) {
        console.error("Erro ao buscar usuários ativos:", error);
      }
    };

    const fetchGestores = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterGestores`,
        );
        setGestores(response.data);
      } catch (error) {
        console.error("Erro ao buscar gestores:", error);
      }
    };

    const fetchCondominios = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterCondominios`,
        );
        setCondominios(response.data);
      } catch (error) {
        console.error("Erro ao buscar condomínios:", error);
      }
    };

    fetchUsuariosAtivos();
    fetchGestores();
    fetchCondominios();
  }, [API_URL]);

  useEffect(() => {
    const fetchUserIds = async () => {
      try {
        if (userType === "Todos os Usuários") {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterTodosUsuarios`,
          );
          setUserIdsToSend(response.data.map(String));
        } else if (userType === "Área Específica" && areaToSend) {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterUsuariosPorArea/${areaToSend}`,
          );
          setUserIdsToSend(response.data.map(String));
        } else if (userType === "Gestores") {
          const gestorIds = gestores.map((gestor) => String(gestor.id_func));
          setUserIdsToSend(gestorIds);
        } else if (userType === "Usuários de Condomínio" && condominioId) {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterUsuariosCondominio/${condominioId}`,
          );
          setUserIdsToSend(response.data.map(String));
        }
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      }
    };

    fetchUserIds();
  }, [userType, areaToSend, gestores, condominioId, API_URL]);

  const sendInfoToServer = async () => {
    if (!socket) {
      toast.error("Erro: Socket não inicializado");
      console.error("Socket not initialized");
      return;
    }
    try {
      const type =
        userType === "Usuários de Condomínio" ? "condominium" : "general";

      socket.emit("sendNotificationToCustomList", {
        userIds: userIdsToSend,
        title: titleToSend,
        message: messageToSend,
        type,
      });

      toast.success("Notificação enviada com sucesso!");
    } catch (error) {
      console.error("Error sending notification:", error);

      toast.error("Erro ao enviar a notificação. Tente novamente.");
    }
  };

  const areas = [
    "Financeiro",
    "Jurídico",
    "Assembleias",
    "Cobrança",
    "RH",
    "Cadastro",
  ];

  const userTypes = [
    "Todos os Usuários",
    "Área Específica",
    "Gestores",
    "Usuários",
    "Usuários de Condomínio",
  ];

  const handleAddUserToList = () => {
    setCustomUserList([...customUserList, { id: "", name: "" }]);
  };

  const handleRemoveLastUserFromList = () => {
    const newList = customUserList.slice(0, -1);
    setCustomUserList(newList);
    setUserIdsToSend(newList.map((user) => user.id));
  };

  const handleCustomUserChange = (
    index: number,
    newValue: { id_func: string; nome: string } | null,
  ) => {
    const newList = customUserList.map((user, i) =>
      i === index
        ? {
            id: newValue ? String(newValue.id_func) : "",
            name: newValue ? newValue.nome : "",
          }
        : user,
    );
    setCustomUserList(newList);
    setUserIdsToSend(newList.map((user) => user.id));
  };

  return (
    <div>
      <PageBreadcrumb items={timelineBreadcrumb} />
      <h2>Criar Notificação</h2>
      <Row>
        {/* Conteúdo principal */}
        <Col xs={12} xl={9}>
          <Card>
            <Card.Header>
              <Form.Group>
                <Form.Label>Tipo de Usuário</Form.Label>
                <Form.Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  {userTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Card.Header>

            <Card.Body>
              {userType === "Área Específica" && (
                <Form.Group className="mb-3">
                  <Form.Label>Selecione a área</Form.Label>
                  <ReactSelect
                    options={areas.map((area) => ({
                      value: area,
                      label: area,
                    }))}
                    value={
                      areaToSend
                        ? { value: areaToSend, label: areaToSend }
                        : null
                    }
                    onChange={(newValue) =>
                      setAreaToSend(
                        (newValue as { value: string; label: string } | null)
                          ?.value || "",
                      )
                    }
                    placeholder="Selecione a área"
                    isClearable
                    closeMenuOnSelect
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      container: (provided) => ({
                        ...provided,
                        position: "relative",
                      }),
                    }}
                  />
                </Form.Group>
              )}

              {userType === "Usuários de Condomínio" && (
                <Form.Group className="mb-3">
                  <Form.Label>Selecione o Condomínio</Form.Label>
                  <ReactSelect
                    options={condominios.map((condominio) => ({
                      value: condominio.id_cond,
                      label: condominio.CONDOMINIO,
                    }))}
                    value={
                      selectedCondominio
                        ? {
                            value: selectedCondominio.id_cond,
                            label: selectedCondominio.CONDOMINIO,
                          }
                        : null
                    }
                    onChange={(newValue: unknown) => {
                      const selectedValue = newValue as {
                        value: string;
                        label: string;
                      } | null;
                      if (selectedValue) {
                        const selected = condominios.find(
                          (condominio) =>
                            condominio.id_cond === selectedValue.value,
                        );
                        setSelectedCondominio(selected || null);
                        setCondominioId(selectedValue.value);
                      } else {
                        setSelectedCondominio(null);
                        setCondominioId("");
                      }
                    }}
                    placeholder="Selecione o condomínio"
                    isClearable
                    closeMenuOnSelect
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      container: (provided) => ({
                        ...provided,
                        position: "relative",
                      }),
                    }}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Título da Notificação</Form.Label>
                <Form.Control
                  type="text"
                  value={titleToSend}
                  onChange={(e) => setTitleToSend(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Mensagem para Enviar</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={messageToSend}
                  onChange={(e) => setMessageToSend(e.target.value)}
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button variant="outline-success" onClick={sendInfoToServer}>
                  Enviar Notificação
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Janela lateral direita */}
        {userType === "Usuários" && (
          <Col xs={12} xl={3}>
            <Card>
              <Card.Header>
                <h5>Usuários Personalizados</h5>
              </Card.Header>
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                {customUserList.map((user, index) => (
                  <Col xs={12} key={index} className="mb-1">
                    <Card.Body className="p-2">
                      <Form.Label>Usuário {index + 1}</Form.Label>
                      <ReactSelect
                        options={usuariosAtivos.map((usuario) => ({
                          value: usuario.id_func,
                          label: usuario.nome,
                        }))}
                        placeholder={`Selecione o usuário ${index + 1}`}
                        value={
                          user.id ? { value: user.id, label: user.name } : null
                        }
                        onChange={(newValue: unknown) =>
                          handleCustomUserChange(index, {
                            id_func:
                              (
                                newValue as {
                                  value: string;
                                  label: string;
                                } | null
                              )?.value || "",
                            nome:
                              (
                                newValue as {
                                  value: string;
                                  label: string;
                                } | null
                              )?.label || "",
                          })
                        }
                        isClearable
                        closeMenuOnSelect
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          container: (provided) => ({
                            ...provided,
                            position: "relative",
                          }),
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                      />
                    </Card.Body>
                  </Col>
                ))}
              </div>
              <div className="d-flex justify-content-center gap-2 my-3">
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={handleRemoveLastUserFromList}
                  disabled={customUserList.length === 0}
                >
                  <FontAwesomeIcon icon={faMinus} /> Remover
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleAddUserToList}
                >
                  <FontAwesomeIcon icon={faPlus} /> Adicionar
                </Button>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CriarNotificacao;
