import React from "react";
import classNames from "classnames";
import { useAppContext } from "providers/AppProvider";
import { Navbar } from "react-bootstrap";
import { useBreakpoints } from "providers/BreakpointsProvider";
import NavbarToggleButton from "./NavbarToggleButton";
import { Link } from "react-router-dom";

const NavbarBrand = () => {
  const {
    config: { navbarTopShape, navbarPosition },
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  return (
    <div className="navbar-logo d-flex align-items-center">
      {breakpoints.down("lg") && <NavbarToggleButton />}
      <Navbar.Brand
        as={Link}
        to="/"
        className={classNames("ms-1", {
          // Adicionando margem esquerda
          "me-1 me-sm-3":
            navbarTopShape === "slim" || navbarPosition === "horizontal",
        })}
      >
        <img src="/images/EurekaLogo.png" alt="Euro Logo" width={100} />
      </Navbar.Brand>
    </div>
  );
};

export default NavbarBrand;
