import Avatar from "components/base/Avatar";
import { useContext, useRef, useState } from "react";
import { Card, Dropdown, Nav, Modal, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link, Navigate } from "react-router-dom";
import Scrollbar from "components/base/Scrollbar";
import classNames from "classnames";
import UserContext from "../../UserContext";
import axios from "axios";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { user, setUser, logout } = useContext(UserContext);

  const storedUser = localStorage.getItem("user");
  const currentUser = user || (storedUser ? JSON.parse(storedUser) : null);

  if (!currentUser) {
    console.log("Usuário não autenticado. Redirecionando para /login.");
    return <Navigate to="/login" replace />;
  }

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hovering, setHovering] = useState(false);

  const [avatarUrl, setAvatarUrl] = useState(
    currentUser?.avatar || "path/to/default-avatar.webp",
  );

  const [image, setImage] = useState<string | null>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [showModal, setShowModal] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
      setShowModal(true);
    };
    reader.readAsDataURL(file);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setImage(null);
  };

  const handleCrop = async () => {
    if (!cropper) return;

    const croppedCanvas = cropper.getCroppedCanvas({
      width: 300, // Define o tamanho que corresponde ao avatar
      height: 300,
    });

    croppedCanvas.toBlob(
      async (blob) => {
        if (!blob) return;

        const formData = new FormData();
        formData.append("avatar", blob, "cropped-avatar.png");
        formData.append("id_func", String(currentUser?.id_func));
        formData.append("nome_usuario", currentUser?.nome?.trim() || ""); // Adiciona o nome do usuário

        const token = localStorage.getItem("token");
        try {
          const { data } = await axios.post(
            `${API_URL}/user/upload-avatar`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            },
          );

          // Atualiza o contexto global (React Context API)
          setUser((prevUser: any) => ({
            ...prevUser,
            avatar: data.avatar,
          }));

          // Atualiza o estado local do avatar com a nova imagem (evita cache)
          setAvatarUrl(`${data.avatar}?t=${Date.now()}`);

          // Atualiza o localStorage
          const storedUserData = localStorage.getItem("user");
          if (storedUserData) {
            const parsedUser = JSON.parse(storedUserData);
            parsedUser.avatar = data.avatar;
            localStorage.setItem("user", JSON.stringify(parsedUser));
          }

          console.log(
            "Avatar atualizado com sucesso no localStorage e contexto!",
          );
        } catch (error) {
          console.error("Erro ao fazer upload do avatar:", error);
        }
      },
      "image/png",
      1, // Qualidade da imagem (0 a 1)
    );

    // Fecha o modal e limpa a imagem temporária
    setShowModal(false);
    setImage(null);
  };

  return (
    <>
      <Dropdown.Menu
        align="end"
        className={classNames(
          className,
          "navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border border-300",
        )}
      >
        <Card
          className="position-relative border-0"
          style={{ maxWidth: "300px" }}
        >
          <Card.Body className="p-0">
            <div className="d-flex flex-column align-items-center justify-content-center gap-1 pt-3 pb-2">
              <div
                className="position-relative"
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                onClick={handleAvatarClick}
                style={{ cursor: "pointer" }}
              >
                <Avatar
                  size="xl"
                  src={`${avatarUrl || user?.avatar}?t=${Date.now()}`}
                  rounded="circle"
                />
                {hovering && (
                  <div
                    className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50 text-white"
                    style={{ borderRadius: "50%" }}
                  >
                    <FeatherIcon icon="upload" size={24} />
                  </div>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <h6 className="text-black m-0">
                {currentUser?.nome || "Usuário"}
              </h6>
            </div>

            <div style={{ height: "6rem" }}>
              <Scrollbar>
                <Nav className="nav flex-column mb-2 pb-1">
                  <Nav.Item>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon="user"
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">Profile</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#!" className="px-3">
                      <FeatherIcon
                        icon="settings"
                        size={16}
                        className="me-2 text-900"
                      />
                      <span className="text-1000">Settings & Privacy</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Scrollbar>
            </div>
          </Card.Body>
          <Card.Footer className="p-0 border-top">
            <div className="px-3">
              <Link
                to="#!"
                className="btn btn-phoenix-secondary d-flex flex-center w-100 mt-2 mb-2"
                onClick={logout}
              >
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Sair
              </Link>
            </div>
          </Card.Footer>
        </Card>
      </Dropdown.Menu>

      {/* Modal para recorte da imagem */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Recortar Imagem</Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex justify-center items-center">
          {image && (
            <Cropper
              src={image}
              style={{
                width: "300px",
                height: "300px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
              aspectRatio={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              viewMode={1}
              guides={false}
              cropBoxResizable={false}
              checkOrientation={false}
              onInitialized={(instance) => setCropper(instance)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCrop}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileDropdownMenu;
