// LCORREA - SSI 0020 - 04/10/2024 - Criação e Gestão dos planos de contas
// organizei esse código para funcionar corretamente o BreadCrump , se quiser ver um exemplo procure //exemploBread

import classNames from "classnames";
import { Breadcrumb } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap"; // Importe o LinkContainer

export interface PageBreadcrumbItem {
	label: string;
	link?: string; // Use 'link' em vez de 'url'
	active?: boolean;
}

interface PageBreadcrumbProps {
	items: PageBreadcrumbItem[];
	className?: string;
}

const PageBreadcrumb = ({ items, className }: PageBreadcrumbProps) => {
	return (
		<Breadcrumb className={classNames(className, "mb-2")}>
			{items.map((item, index) =>
				item.link && index !== items.length - 1 ? (
					<LinkContainer to={item.link} key={item.label}>
						<Breadcrumb.Item active={item.active}>{item.label}</Breadcrumb.Item>
					</LinkContainer>
				) : (
					<Breadcrumb.Item active key={item.label}>
						{item.label}
					</Breadcrumb.Item>
				)
			)}
		</Breadcrumb>
	);
};

export default PageBreadcrumb;
