import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ConfirmarModal from "../Globais/ConfirmarModal";
import SuccessModal from "../Globais/SuccessModal";

const traduzirTipoResp = (idTipoResp) => {
  const tipos = {
    1: "COBRANCA NORMAL E EXTRA",
    2: "APENAS COBRANCAS EXTRA",
    4: "NÃO RECEBER COBRANÇAS",
    7: "APENAS COBRANCAS NORMAL",
  };
  return tipos[idTipoResp] || "Tipo desconhecido";
};

const traduzirTipoResponsavel = (idLabelTres) => {
  const tiposResponsavel = {
    1: "PROPRIETARIO RESIDENTE",
    2: "PROPRIETARIO NÃO RESIDENTE",
    3: "IMOBILIARIA",
    4: "DEPENDENTE",
    5: "EMPREGADO",
    6: "VISITANTE",
    7: "RESIDENTE",
    999: "PROCURADOR",
  };
  return tiposResponsavel[idLabelTres] || "Tipo desconhecido";
};

const MostrarContatos = ({
  isOpen,
  onRequestClose,
  unidCorresp,
  condCorresp,
}) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [currentContactId, setCurrentContactId] = useState(null);
  const [deleteAction, setDeleteAction] = useState("single"); // 'single' ou 'all'

  useEffect(() => {
    const fetchContatos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.superlogica.net/v2/condor/unidades/index?idCondominio=${condCorresp}&exibirDadosDosContatos=1&id=${unidCorresp}`,
          {
            headers: {
              app_token: process.env.REACT_APP_APP_TOKEN,
              access_token: process.env.REACT_APP_ACCESS_TOKEN,
            },
          },
        );

        if (
          response.data &&
          response.data.length > 0 &&
          Array.isArray(response.data[0].contatos)
        ) {
          setContatos(response.data[0].contatos);
        } else {
          setContatos([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar contatos:", error);
        setLoading(false);
        setContatos([]);
      }
    };

    if (isOpen) {
      fetchContatos();
    }
  }, [isOpen, unidCorresp, condCorresp]);

  const handleDeleteClick = (idContato, action = "single") => {
    setCurrentContactId(idContato);
    setDeleteAction(action);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    setIsConfirmModalOpen(false);
    if (deleteAction === "single" && currentContactId) {
      await deleteContact(currentContactId);
      setIsSuccessModalOpen(true);
    } else if (deleteAction === "all") {
      await deleteAllContacts();
      setIsSuccessModalOpen(true);
    }
  };

  const handleDeleteAllClick = () => {
    handleDeleteClick(null, "all"); // Passa null como id e 'all' como ação
  };

  const deleteContact = async (idContato) => {
    try {
      const config = {
        method: "post",
        url: `https://api.superlogica.net/v2/condor/contatos/delete?idUnidade=${unidCorresp}&idCondominio=${condCorresp}&idContato=${idContato}`,
        headers: {
          app_token: process.env.REACT_APP_APP_TOKEN,
          access_token: process.env.REACT_APP_ACCESS_TOKEN,
        },
      };
      const response = await axios(config);
      console.log("Contato excluído:", response.data);
      setContatos(
        contatos.filter((contato) => contato.id_contato_con !== idContato),
      );
    } catch (error) {
      console.error("Erro ao excluir contato:", error);
    }
  };

  const deleteAllContacts = async () => {
    for (let contato of contatos) {
      await deleteContact(contato.id_contato_con);
    }
    setContatos([]); // Limpa a lista de contatos após todos serem excluídos
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Contatos da Unidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Carregando contatos...</p>
        ) : contatos.length > 0 ? (
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {contatos.map((contato) => (
                <li
                  key={contato.id_contato_con}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 0", // Espaço interno
                    borderBottom: "1px solid #ddd", // Linha separadora
                  }}
                >
                  <div className="contact-info">
                    <div>
                      <strong>Nome:</strong> {contato.st_nome_con}
                    </div>
                    <div>
                      <strong>Tipo de Cobrança:</strong>{" "}
                      {traduzirTipoResp(contato.id_tiporesp_tres)}
                    </div>
                    <div>
                      <strong>Tipo de Responsável:</strong>{" "}
                      {traduzirTipoResponsavel(contato.id_label_tres)}
                    </div>
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{
                      maxHeight: "40px",
                    }}
                    onClick={() => handleDeleteClick(contato.id_contato_con)}
                  >
                    Excluir
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>Nenhum contato encontrado.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={handleDeleteAllClick}>
          Excluir Tudo
        </button>
        <button className="btn btn-secondary" onClick={onRequestClose}>
          Fechar
        </button>
      </Modal.Footer>
      <ConfirmarModal
        isOpen={isConfirmModalOpen}
        message={`Tem certeza que deseja excluir ${
          deleteAction === "all" ? "todos os contatos" : "este contato"
        }?`}
        onConfirm={confirmDelete}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        message="Contato(s) excluído(s) com sucesso!"
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </Modal>
  );
};

export default MostrarContatos;
