import { jwtDecode } from "jwt-decode";

const useAuth = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.log("Token não encontrado.");
    return { isAuthenticated: false, user: null };
  }

  try {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000;

    if (Date.now() >= expirationTime) {
      console.log("Token expirado.");
      localStorage.removeItem("token");
      return { isAuthenticated: false, user: null };
    }

    console.log("Token decodificado com sucesso:", decodedToken);
    return { isAuthenticated: true, user: Object.freeze(decodedToken) };
  } catch (error) {
    console.error("Erro ao decodificar o token:", error);
    return { isAuthenticated: false, user: null };
  }
};

export default useAuth;
