import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { colors } from "./utils/colorPalette";
import CondosPorCidadeChart from "./CondosPorCidadeChart";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";

interface APIResponseItem {
  CIDADE: string;
  total_condominios: number;
  detalhes?: { cidade: string; total_condominios: number }[];
}

interface CondoStat {
  city: string;
  count: number;
  bg: string;
  details?: { cidade: string; total_condominios: number }[];
}

const CondosPorCidade: React.FC = () => {
  const [condoStats, setCondoStats] = useState<CondoStat[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [allCitiesData, setAllCitiesData] = useState<
    { city: string; count: number }[]
  >([]);
  const API_URL = process.env.REACT_APP_API_URL || "";

  useEffect(() => {
    const fetchCondoStats = async () => {
      try {
        const response = await axios.get<APIResponseItem[]>(
          `${API_URL}/dados-dashboard/buscar-total-condominios-por-cidade`,
        );
        const data = response.data;

        setCondoStats(
          data.map((item: APIResponseItem, index: number) => ({
            city: item.CIDADE,
            count: item.total_condominios,
            bg: colors[index % colors.length],
            details: item.detalhes || [],
          })),
        );

        const total = data.reduce(
          (acc, item) => acc + item.total_condominios,
          0,
        );
        setTotalCount(total);
      } catch (error) {
        console.error("Erro ao buscar dados de condomínios por cidade:", error);
      }
    };

    fetchCondoStats();
  }, [API_URL]);

  const handleShowModal = () => {
    // Transformar dados para incluir as cidades de "Outras Cidades"
    // diretamente na lista, ignorando "Outras Cidades" como categoria.
    const transformed = condoStats.flatMap((item) => {
      if (item.city === "Outras Cidades" && item.details) {
        return item.details.map((d) => ({
          city: d.cidade || "Sem Nome",
          count: d.total_condominios,
        }));
      } else {
        return [{ city: item.city, count: item.count }];
      }
    });

    // Ordena do maior para o menor
    transformed.sort((a, b) => b.count - a.count);
    setAllCitiesData(transformed);
    setShowModal(true);
  };

  // Configuração da tabela no modal
  const allCitiesTable = useAdvanceTable({
    data: allCitiesData,
    columns: [
      {
        id: "spacerLeft1", // IDs únicos para evitar conflitos
        header: "",
        cell: () => <div style={{ width: "40px" }} />,
      },
      {
        accessorKey: "city",
        header: "Nome da Cidade",
      },
      {
        accessorKey: "count",
        header: "Quantidade",
      },
    ],
    pageSize: 100,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (
    <>
      <Row className="g-3 mb-3">
        <Col xs={12} md={6}>
          <h3 className="text-body-emphasis text-nowrap">
            Condomínios por Cidade
          </h3>
          <p className="text-body-tertiary mb-md-2">
            Visualize os dados de condomínios por cidade.
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0 fw-bold">Cidades</p>
            <p className="mb-0 fs-9">
              Total <span className="fw-bold">{totalCount}</span>
            </p>
          </div>
          <hr className="bg-body-secondary mb-2 mt-2" />
          <div className="d-flex flex-column align-items-start">
            {condoStats.map((condo: CondoStat) => (
              <div
                className="d-flex align-items-center justify-content-between w-100 mb-1"
                key={condo.city}
              >
                <div className="d-flex align-items-center">
                  <span
                    className="d-inline-block me-2"
                    style={{
                      backgroundColor: condo.bg,
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                    }}
                  />
                  <p className="mb-0 fw-semibold text-body lh-sm">
                    {condo.city}
                  </p>
                </div>
                <div>
                  <h5 className="mb-0 text-body text-end">{condo.count}</h5>
                </div>
              </div>
            ))}
          </div>
          <Button
            variant="primary"
            style={{ backgroundColor: "#238ee6", borderColor: "#238ee6" }}
            className="mt-3"
            onClick={handleShowModal}
          >
            Ver Detalhes
          </Button>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <div style={{ maxWidth: "300px", width: "100%" }}>
            <CondosPorCidadeChart condoData={condoStats} />
          </div>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhes condomínios por cidade</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="overflow-auto"
          style={{
            maxHeight: "40vh", // Altura máxima do modal
            whiteSpace: "nowrap", // Evita quebra de linha na tabela
          }}
        >
          <AdvanceTableProvider {...allCitiesTable}>
            <AdvanceTable
              tableProps={{
                className: "table table-hover",
              }}
            />
          </AdvanceTableProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CondosPorCidade;
