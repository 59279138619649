import React from "react";
import { ProjectManagementStat } from "./stats";

type StatProps = {
  stat: ProjectManagementStat;
  color: string;
};

const Stat: React.FC<StatProps> = ({ stat, color }) => {
  const IconComponent = stat.icon; // O ícone agora é um componente React
  return (
    <div className="d-flex align-items-center">
      <IconComponent width="40" height="40" style={{ color }} />
      <div className="ms-2">
        <div className="d-flex align-items-end">
          <h2 className="mb-0 me-2">{stat.count}</h2>
          <span className="fs-7 fw-semibold text-body">{stat.title}</span>
        </div>
        <p className="text-body-secondary fs-9 mb-0">{stat.subtitle}</p>
      </div>
    </div>
  );
};

export default Stat;
