// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import ReactSelect from "components/base/ReactSelect";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import moment from "moment";
import "moment/locale/pt-br";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IColaborador {
  id_func: number;
  nome: string;
}

interface IEmpreendimento {
  id_cond: number;
  CONDOMINIO: string;
  peso: number;
}

interface IColabTemp extends IColaborador {
  pesoTotal: number;
  empreendimentos: IEmpreendimento[];
  quantidade: number;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Redirecionar Carteira", active: true },
];

const RedirecionarCarteira: React.FC = () => {
  const [colaboradores, setColaboradores] = useState<IColaborador[]>([]);
  const [selectedColaborador, setSelectedColaborador] =
    useState<IColaborador | null>(null);

  const [empreendimentos, setEmpreendimentos] = useState<number>(0);
  const [pesoTotal, setPesoTotal] = useState<number>(0);

  // Lista de empreendimentos do colaborador selecionado
  const [empreendimentosColaborador, setEmpreendimentosColaborador] = useState<
    IEmpreendimento[]
  >([]);

  // Lista de colaboradores temporários (mesma área)
  const [colaboradoresTemp, setColaboradoresTemp] = useState<IColabTemp[]>([]);

  // Guarda qual colaborador temporário foi selecionado para cada empreendimento
  const [selectedTemporaryColaboradores, setSelectedTemporaryColaboradores] =
    useState<Record<number, number>>({});

  // Número de colaboradores para distribuir empreendimentos
  const [
    numColaboradoresParaRedirecionamento,
    setNumColaboradoresParaRedirecionamento,
  ] = useState<number>(1);

  // Armazena os colaboradores escolhidos para redirecionamento em cada posição
  const [
    selectedColaboradoresParaRedirecionamento,
    setSelectedColaboradoresParaRedirecionamento,
  ] = useState<(IColabTemp | null)[]>([]);

  const API_URL = process.env.REACT_APP_API_URL;

  // Carrega lista de colaboradores ausentes
  useEffect(() => {
    fetch(`${API_URL}/ausencia/colaboradores-ausentes`)
      .then((response) => response.json())
      .then((data) => setColaboradores(data))
      .catch((error) =>
        console.error("Erro ao buscar os colaboradores:", error),
      );
  }, [API_URL]);

  // Carrega empreendimentos e colaboradores temporários para 'selectedColaborador'
  useEffect(() => {
    if (!selectedColaborador) {
      setEmpreendimentos(0);
      setPesoTotal(0);
      setEmpreendimentosColaborador([]);
      setColaboradoresTemp([]);
      setSelectedTemporaryColaboradores({});
      return;
    }

    fetch(
      `${API_URL}/ausencia/colaboradores-mesma-area/${selectedColaborador.id_func}`,
    )
      .then((res) => res.json())
      .then((data: IColaborador[]) => {
        const promises = data.map((colab) =>
          fetch(`${API_URL}/ausencia/condominios-colaborador/${colab.id_func}`)
            .then((res) => res.json())
            .then((result) => ({
              ...colab,
              pesoTotal: Number(result.pesoTotal),
              empreendimentos: result.empreendimentos,
              quantidade: result.empreendimentos.length,
            })),
        );
        return Promise.all(promises);
      })
      .then((results: IColabTemp[]) => {
        setColaboradoresTemp(results);
      })
      .catch((error) =>
        console.error("Erro ao buscar colaboradores temporários:", error),
      );

    fetch(
      `${API_URL}/ausencia/condominios-colaborador/${selectedColaborador.id_func}`,
    )
      .then((res) => res.json())
      .then((data) => {
        setEmpreendimentosColaborador(data.empreendimentos);
        setEmpreendimentos(data.empreendimentos.length);
        setPesoTotal(data.pesoTotal);
      })
      .catch((error) =>
        console.error("Erro ao buscar empreendimentos do colaborador:", error),
      );
  }, [selectedColaborador, API_URL]);

  // Atualiza a lista 'selectedColaboradoresParaRedirecionamento'
  useEffect(() => {
    setSelectedColaboradoresParaRedirecionamento(
      Array(numColaboradoresParaRedirecionamento).fill(null),
    );
  }, [numColaboradoresParaRedirecionamento]);

  // Função para adicionar no banco a ausência/redirecionamento
  const handleRedirectionConfirm = () => {
    const hasEmptyFields = empreendimentosColaborador.some(
      (item) => !selectedTemporaryColaboradores[item.id_cond],
    );

    if (hasEmptyFields) {
      toast.error(
        "Por favor, selecione colaboradores temporários para todos os empreendimentos.",
      );
      return;
    }

    const promises = empreendimentosColaborador.map((item) => {
      return fetch(`${API_URL}/ausencia/add-redirection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_aus: (selectedColaborador as any)?.id_aus,
          id_cond: item.id_cond,
          id_func_temp: selectedTemporaryColaboradores[item.id_cond],
          id_func_aus: selectedColaborador?.id_func,
          ativo: 1,
          dt_final: moment((selectedColaborador as any)?.dt_fim).format(
            "YYYY-MM-DD",
          ),
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Falha ao redirecionar ${item.CONDOMINIO}. Status: ${response.status}`,
            );
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            console.log(`Redirecionamento de ${item.CONDOMINIO} registrado.`);
          }
        });
    });

    Promise.all(promises)
      .then(() => {
        toast.success(
          "Todos os redirecionamentos foram registrados com sucesso!",
        );
        setSelectedColaborador(null);
        setSelectedTemporaryColaboradores({});
        setEmpreendimentos(0);
        setPesoTotal(0);
        setEmpreendimentosColaborador([]);
      })
      .catch((error) => {
        console.error("Erro ao redirecionar:", error);
        toast.error(
          "Ocorreu um erro ao redirecionar. Por favor, tente novamente.",
        );
      });
  };

  // Distribuir empreendimentos automaticamente
  const distributeEmpreendimentos = () => {
    const isAnyInvalid = selectedColaboradoresParaRedirecionamento.some(
      (colab) => !colab || colab.nome === "Não se aplica",
    );
    if (isAnyInvalid) {
      toast.error("Por favor, selecione colaboradores válidos.");
      return;
    }

    const updatedTempColaboradores: Record<number, number> = {};
    let index = 0;

    empreendimentosColaborador.forEach((item) => {
      const colab =
        selectedColaboradoresParaRedirecionamento[
          index % numColaboradoresParaRedirecionamento
        ];
      if (colab) {
        updatedTempColaboradores[item.id_cond] = colab.id_func;
      }
      index++;
    });

    setSelectedTemporaryColaboradores(updatedTempColaboradores);
  };

  // Buscar ID da ausência ao selecionar colaborador
  const fetchAbsenceIdForColaborador = (id_func: number) => {
    fetch(`${API_URL}/ausencia/${id_func}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.absence) {
          setSelectedColaborador((prev) =>
            prev
              ? {
                  ...prev,
                  id_aus: data.absence.id_aus,
                  dt_fim: data.absence.dt_fim,
                }
              : null,
          );
        }
      })
      .catch((error) =>
        console.error("Erro ao buscar id_aus e dt_fim:", error),
      );
  };

  // AdvanceTable

  const tableData = useMemo(() => {
    return empreendimentosColaborador.map((item) => ({
      ...item,
      colaboradorTemporario: selectedTemporaryColaboradores[item.id_cond] || "",
    }));
  }, [empreendimentosColaborador, selectedTemporaryColaboradores]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "CONDOMINIO",
        header: "condominio",
      },
      {
        accessorKey: "peso",
        header: "Peso",
      },
      {
        accessorKey: "colaboradorTemporario",
        header: "Colaborador Temporário",
        cell: ({ row }: any) => {
          const item: IEmpreendimento = row.original;
          const selectedTempId =
            selectedTemporaryColaboradores[item.id_cond] || "";

          return (
            <Form.Select
              value={selectedTempId}
              onChange={(event) => {
                const newId = Number(event.target.value);
                setSelectedTemporaryColaboradores((prev) => ({
                  ...prev,
                  [item.id_cond]: newId,
                }));
              }}
            >
              <option value="">Selecione...</option>
              {colaboradoresTemp.map((colab) => (
                <option key={colab.id_func} value={colab.id_func}>
                  {colab.nome}
                </option>
              ))}
            </Form.Select>
          );
        },
      },
      {
        accessorKey: "pesoTotalColab",
        header: "Peso Atual Colab",
        cell: ({ row }: any) => {
          const item: IEmpreendimento = row.original;
          const selectedTempId = selectedTemporaryColaboradores[item.id_cond];
          const selectedTempColab = colaboradoresTemp.find(
            (col) => col.id_func === selectedTempId,
          );
          return selectedTempColab ? selectedTempColab.pesoTotal : "-";
        },
      },
      {
        accessorKey: "quantidadeEmpreend",
        header: "Qtd. Empreend.",
        cell: ({ row }: any) => {
          const item: IEmpreendimento = row.original;
          const selectedTempId = selectedTemporaryColaboradores[item.id_cond];
          const selectedTempColab = colaboradoresTemp.find(
            (col) => col.id_func === selectedTempId,
          );
          return selectedTempColab ? selectedTempColab.quantidade : "-";
        },
      },
      {
        accessorKey: "novoPesoEstimado",
        header: "Novo Peso (estimado)",
        cell: ({ row }: any) => {
          const item: IEmpreendimento = row.original;
          const selectedTempId = selectedTemporaryColaboradores[item.id_cond];
          const selectedTempColab = colaboradoresTemp.find(
            (col) => col.id_func === selectedTempId,
          );
          const novoPeso = selectedTempColab
            ? Number(item.peso) + (selectedTempColab.pesoTotal || 0)
            : item.peso;
          return novoPeso;
        },
      },
    ];
  }, [selectedTemporaryColaboradores, colaboradoresTemp]);

  const tableInstance = useAdvanceTable({
    data: tableData,
    columns: columns,
    pageSize: 1000,
    pagination: true,
    sortable: true,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "80vh",
      }}
    >
      {" "}
      <Row className="mx-0">
        <Col
          xs={12}
          xl={9}
          style={{
            paddingLeft: "2rem",
          }}
        >
          <ToastContainer />
          <PageBreadcrumb items={defaultBreadcrumbItems} />
          <h2 className="mb-4">Redirecionar Carteira</h2>

          <Row>
            <Col xs={12} md={4}>
              <Form.Label>Colaborador Ausente</Form.Label>
              <ReactSelect
                options={colaboradores.map((colab) => ({
                  value: colab,
                  label: colab.nome,
                }))}
                placeholder="Selecione o colaborador"
                value={
                  selectedColaborador
                    ? {
                        value: selectedColaborador,
                        label: selectedColaborador.nome,
                      }
                    : null
                }
                onChange={(option) => {
                  const colab =
                    (option as { value: IColaborador })?.value || null;
                  setSelectedColaborador(colab || null);
                  if (colab) {
                    fetchAbsenceIdForColaborador(colab.id_func);
                  } else {
                    setEmpreendimentosColaborador([]);
                    setColaboradoresTemp([]);
                    setSelectedTemporaryColaboradores({});
                    setEmpreendimentos(0);
                    setPesoTotal(0);
                  }
                }}
                isClearable
                closeMenuOnSelect={true} // Fecha automaticamente após a seleção
              />
            </Col>

            <Col xs={6} md={2}>
              <Form.Label>Condomínios</Form.Label>
              <Form.Control
                value={empreendimentos}
                readOnly
                placeholder="Empreendimentos"
              />
            </Col>

            <Col xs={6} md={3}>
              <Form.Label>Peso Total</Form.Label>
              <Form.Control
                value={pesoTotal}
                readOnly
                placeholder="Peso Total"
              />
            </Col>

            <Col xs={3}>
              <Form.Label>Quantos Colaboradores Distribuir</Form.Label>
              <Form.Select
                value={numColaboradoresParaRedirecionamento}
                onChange={(e) =>
                  setNumColaboradoresParaRedirecionamento(
                    Number(e.target.value),
                  )
                }
              >
                {[...Array(10).keys()].map((n) => (
                  <option key={n + 1} value={n + 1}>
                    {n + 1}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="mt-6">
            {selectedColaborador ? (
              <div
                style={{
                  maxHeight: "524px",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  padding: "1rem",
                }}
                className="bg-white border-top border-bottom border-200 position-relative top-1"
              >
                <AdvanceTableProvider {...tableInstance}>
                  <AdvanceTable
                    tableProps={{
                      className:
                        "phoenix-table fs-9 table-hover text-center align-left mb-0",
                    }}
                  />
                  {empreendimentosColaborador.length === 0 && (
                    <div className="text-center py-10">
                      <p className="text-muted">Nenhum condomínio encontrado</p>
                    </div>
                  )}
                </AdvanceTableProvider>
              </div>
            ) : (
              <div className="text-muted text-center mt-10">
                Selecione um colaborador para ver seus condomínios
              </div>
            )}
          </Row>
        </Col>

        <Col xs={12} xl={3}>
          <Card className="mt-10">
            <Card.Header>
              <h5>Redirecionamento Automático</h5>
            </Card.Header>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              {selectedColaboradoresParaRedirecionamento.map(
                (colabTemp, index) => (
                  <Col xs={12} md={12} key={index} className="mb-1">
                    <Card.Body className="p-2">
                      <Form.Label>Colaborador {index + 1}</Form.Label>
                      <ReactSelect
                        options={colaboradoresTemp.map((colab) => ({
                          value: colab,
                          label: colab.nome,
                        }))}
                        placeholder={`Selecione o colaborador ${index + 1}`}
                        value={
                          colabTemp
                            ? {
                                value: colabTemp,
                                label: colabTemp.nome,
                              }
                            : null
                        }
                        onChange={(option) => {
                          const chosen = (option as { value: IColabTemp })
                            ?.value;
                          const updated = [
                            ...selectedColaboradoresParaRedirecionamento,
                          ];
                          updated[index] = chosen || null;
                          setSelectedColaboradoresParaRedirecionamento(updated);
                        }}
                        isClearable
                        closeMenuOnSelect={true}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          container: (provided) => ({
                            ...provided,
                            position: "relative",
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                      />
                    </Card.Body>
                  </Col>
                ),
              )}
            </div>
            <div>
              <div className="d-flex justify-content-center my-3">
                <Button
                  variant="outline-primary"
                  onClick={distributeEmpreendimentos}
                >
                  Distribuir Condomínios
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row
        className="mx-0"
        style={{
          backgroundColor: "#f8f9fa",
          paddingTop: "2rem",
        }}
      >
        <div className="d-flex justify-content-end w-100">
          <Button
            variant="outline-success"
            onClick={handleRedirectionConfirm}
            className="me-10"
          >
            Confirmar Redirecionamento
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default RedirecionarCarteira;
