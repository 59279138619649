/* ***********************************
Autor: Matheus Botelho
Data: 04/06/24
Atividade: SSI 0024
Função: Rotas para fazer manipulação de arquivos para lançamentos
************************************ */

//SSI 0091 - MATHEUS BOTELHO - 15/01/25 - INICIO

import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import axios from "axios";
import UserContext from "../../components/UserContext";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

const FileUpload = ({
  label,
  accept,
  multiple,
  onFileSelect,
  fileNames = [],
  Icon,
  onFileRemove,
}) => {
  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      const validFiles = acceptedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        const validExtensions = accept
          .split(",")
          .map((ext) => ext.replace(".", ""));

        return validExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        onFileSelect(multiple ? validFiles : validFiles[0]);
      } else {
        toast.error(`Verifique o tipo de arquivo selecionado.`);
      }
    } else if (fileRejections.length > 0) {
      toast.error(`Verifique o tipo de arquivo selecionado.`);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  const truncateFileName = (name, maxLength = 20) => {
    if (name.length <= maxLength) return name;
    const extIndex = name.lastIndexOf(".");
    const ext = name.substring(extIndex);
    const truncatedName = name.substring(0, maxLength - ext.length - 3);
    return `${truncatedName}...${ext}`;
  };

  return (
    <div
      {...getRootProps()}
      style={{
        border: "1px dashed #ddd",
        padding: "10px",
        textAlign: "center",
        cursor: "pointer",
        width: "100%",
        height: "120px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        transition: "background-color 0.3s",
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#eff2f4")}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
    >
      <input {...getInputProps()} />
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        <Icon style={{ fontSize: 30, color: "#6c757d" }} />
      </div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "500",
          marginBottom: "10px",
          color: "#434343",
          fontSize: "12px",
        }}
      >
        <p>{label}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {fileNames.length > 0 ? (
          fileNames.map((name, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
                backgroundColor: "#fcfcfc",
                border: "1px solid #cdcfd0",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#eff2f4")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fcfcfc")
              }
            >
              <span
                style={{
                  marginRight: "8px",
                  fontSize: "11px",
                }}
              >
                {truncateFileName(name)}
              </span>
              <DeleteRoundedIcon
                style={{
                  fontSize: 16,
                  color: "red",
                  cursor: "pointer",
                  transition: "color 0.3s",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onFileRemove(index);
                }}
              />
            </div>
          ))
        ) : (
          <p
            style={{
              margin: 0,
              fontSize: "10px",
              color: "#6c757d",
            }}
          >
            Arraste aqui ou{" "}
            <span
              style={{ color: "#007bff", cursor: "pointer", fontSize: "10px" }}
            >
              Procure no seu computador
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

function CriarSalarios() {
  const [csvFile, setCsvFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [espelhoFilePDF, setEspelhoFilePDF] = useState(null);
  const [csvAdiantamento, setCsvAdiantamento] = useState(null);
  const [pdfAdiantamento1, setPdfAdiantamento1] = useState(null);
  const [pdfAdiantamento2, setPdfAdiantamento2] = useState(null);
  const [pdfInssFiles, setPdfInssFiles] = useState([]);
  const [nomeCondominio, setNomeCondominio] = useState("");
  const [categoria, setCategoria] = useState("");
  const [periodo, setPeriodo] = useState("");
  const { user } = useContext(UserContext);
  const [quantidadeFuncionarios, setQuantidadeFuncionarios] = useState(0);
  const [isVerificationSuccessful, setIsVerificationSuccessful] =
    useState(false);
  const [infoCondominioAdiantamentos, setInfoCondominioAdiantamentos] =
    useState({}); //SSI 0030 - 27/06/24
  const [
    isVerificationSuccessfulAdiantamento,
    setIsVerificationSuccessfulAdiantamento,
  ] = useState(false); //SSI 0030 - 27/06/24
  const [isUploading, setIsUploading] = useState(false); //SSI 0030 - 27/06/24

  //SSI 0031

  const [guiaFgtsFile, setGuiaFgtsFile] = useState(null);
  const [declaracaoFgtsFile, setDeclaracaoFgtsFile] = useState(null);
  const [isVerificationSuccessfulFgts, setIsVerificationSuccessfulFgts] =
    useState(false);

  //FIM DA ALTERAÇÃO

  const API_URL = process.env.REACT_APP_API_URL;

  //SSI 0032 - 02/07/24

  const [darfInssFile, setDarfInssFile] = useState(null);
  const [declaracaoCompletaFile, setDeclaracaoCompletaFile] = useState(null);
  const [reciboFile, setReciboFile] = useState(null);
  const [isVerificationSuccessfulInss, setIsVerificationSuccessfulInss] =
    useState(false);

  //FIM DA ALTERAÇÃO

  //SSI 0034 - MATHEUS BOTELHO - 09/07/24

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [despesasNaoLiquidadas, setDespesasNaoLiquidadas] = useState([]);
  const [haDespesasLiquidadas, setHaDespesasLiquidadas] = useState(false);
  const [isVerifyingDespesas, setIsVerifyingDespesas] = useState(false);
  const [isVerifyingDespesasAdiantamento, setIsVerifyingDespesasAdiantamento] =
    useState(false);

  //FIM DA ALTERAÇÃO

  //SSI 0036 - MATHEUS BOTELHO - 10/07/24

  const [boletoSindicatoFile, setBoletoSindicatoFile] = useState(null);
  const [isVerificationSuccessfulBoleto, setIsVerificationSuccessfulBoleto] =
    useState(false);

  //FIM DA ALTERAÇÃO

  const defaultBreadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Importação Salários", active: true },
  ];

  const [selectedOption, setSelectedOption] = useState("salarios");

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (csvFile && pdfFile && espelhoFilePDF) {
      handleFilesVerification();
    }
  }, [csvFile, pdfFile, espelhoFilePDF]);

  const handleFilesVerification = async () => {
    if (!csvFile || !pdfFile || !espelhoFilePDF) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", csvFile);
    formData.append("pdfFile", pdfFile);
    formData.append("espelhoFilePDF", espelhoFilePDF); // Adicionando o espelhoFilePDF

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-arquivos-salario`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { nomeCondominio, categoria, periodo, quantidadeFuncionarios } =
        response.data;
      console.log("Dados verificados:", response.data); // Log para verificar os dados recebidos
      setNomeCondominio(nomeCondominio);
      setCategoria(categoria);
      setPeriodo(periodo);
      setQuantidadeFuncionarios(quantidadeFuncionarios);
      setIsVerificationSuccessful(true);
      toast.success(response.data.message);

      // //SSI 0034 - MATHEUS BOTELHO - 09/07/24

      await verificarDespesas();

      //FIM DA ALTERAÇÃO
    } catch (error) {
      setIsVerificationSuccessful(false);
      console.error("Erro ao verificar arquivos:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar arquivos. Por favor, tente novamente.",
      );
    }
  };

  //SSI 0034 - MATHEUS BOTELHO - 09/07/24

  const verificarDespesas = async () => {
    if (!csvFile) {
      toast.error("Por favor, selecione o arquivo CSV.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", csvFile);

    setIsVerifyingDespesas(true); // Inicia o estado de carregamento

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-despesas`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      const responseData = response.data || { data: [] };

      // Verifica se há despesas
      if (!responseData.data || responseData.data.length === 0) {
        toast.success("Nenhuma despesa encontrada.");
        return;
      }

      const { message, data } = responseData;

      setModalMessage(message);
      setShowModal(true);

      // Verifica se há despesas liquidadas
      const algumaLiquidada = data.some(
        (despesa) => despesa.fl_liquidado_pdes === "1",
      );

      // Armazena as despesas não liquidadas se não houver despesas liquidadas
      if (!algumaLiquidada) {
        const despesasNaoLiquidadas = data.filter(
          (despesa) => despesa.fl_liquidado_pdes === "0",
        );
        setDespesasNaoLiquidadas(despesasNaoLiquidadas);
      }

      // Atualiza estado para saber se há despesas liquidadas
      setHaDespesasLiquidadas(algumaLiquidada);
    } catch (error) {
      console.error("Erro ao verificar despesas:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar despesas. Por favor, tente novamente.",
      );
    } finally {
      setIsVerifyingDespesas(false); // Finaliza o estado de carregamento
    }
  };

  const excluirDespesas = async () => {
    try {
      const data = {
        despesas: despesasNaoLiquidadas.map((despesa) => ({
          ID_PARCELA_PDES: despesa.id_parcela_pdes,
        })),
      };

      await axios.put(
        `${API_URL}/lancamentos-salarios/excluir-despesas`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      toast.success("Despesas excluídas com sucesso!");
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao excluir despesas:", error);
      toast.error("Erro ao excluir despesas. Por favor, tente novamente.");
    }
  };

  const verificarDespesasAdiantamento = async () => {
    if (!csvAdiantamento) {
      toast.error("Por favor, selecione o arquivo CSV de adiantamento.");
      return;
    }

    const formData = new FormData();
    formData.append("csvFile", csvAdiantamento);

    setIsVerifyingDespesasAdiantamento(true); // Inicia o estado de carregamento

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-despesas-adiantamento`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      const responseData = response.data || { data: [] };

      // Verifica se há despesas
      if (!responseData.data || responseData.data.length === 0) {
        toast.success("Nenhuma despesa encontrada.");
        return;
      }

      const { message, data } = responseData;

      setModalMessage(message);
      setShowModal(true);

      // Verifica se há despesas liquidadas
      const algumaLiquidada = data.some(
        (despesa) => despesa.fl_liquidado_pdes === "1",
      );

      // Armazena as despesas não liquidadas se não houver despesas liquidadas
      if (!algumaLiquidada) {
        const despesasNaoLiquidadas = data.filter(
          (despesa) => despesa.fl_liquidado_pdes === "0",
        );
        setDespesasNaoLiquidadas(despesasNaoLiquidadas);
      }

      // Atualiza estado para saber se há despesas liquidadas
      setHaDespesasLiquidadas(algumaLiquidada);
    } catch (error) {
      console.error("Erro ao verificar despesas:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar despesas. Por favor, tente novamente.",
      );
    } finally {
      setIsVerifyingDespesasAdiantamento(false); // Finaliza o estado de carregamento
    }
  };

  const excluirDespesasAdiantamento = async () => {
    try {
      const data = {
        despesas: despesasNaoLiquidadas.map((despesa) => ({
          ID_PARCELA_PDES: despesa.id_parcela_pdes,
        })),
      };

      await axios.put(
        `${API_URL}/lancamentos-salarios/excluir-despesas-adiantamento`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      toast.success("Despesas de adiantamento excluídas com sucesso!");
      setShowModal(false);
    } catch (error) {
      console.error("Erro ao excluir despesas de adiantamento:", error);
      toast.error(
        "Erro ao excluir despesas de adiantamento. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0030 - 27/06/24

  useEffect(() => {
    if (csvAdiantamento && pdfAdiantamento1 && pdfAdiantamento2) {
      handleFilesVerificationAdiantamento();
    }
  }, [csvAdiantamento, pdfAdiantamento1, pdfAdiantamento2]);

  const handleFilesVerificationAdiantamento = async () => {
    if (!csvAdiantamento || !pdfAdiantamento1 || !pdfAdiantamento2) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    const formData = new FormData();
    formData.append("csvAdiantamento", csvAdiantamento);
    formData.append("pdfAdiantamento1", pdfAdiantamento1);
    formData.append("pdfAdiantamento2", pdfAdiantamento2);

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-arquivos-adiantamento`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { nomeCondominio, categoria, periodo, quantidadeFuncionarios } =
        response.data;
      console.log("Dados verificados:", response.data);
      setInfoCondominioAdiantamentos({
        nomeCondominio,
        categoria,
        periodo,
        quantidadeFuncionarios,
      });
      setIsVerificationSuccessfulAdiantamento(true);
      toast.success(response.data.message);

      // Chama a função de verificação de despesas adiantamento
      await verificarDespesasAdiantamento();
    } catch (error) {
      setIsVerificationSuccessfulAdiantamento(false);
      console.error("Erro ao verificar arquivos:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar arquivos. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0031 - 28/06/24

  useEffect(() => {
    if (guiaFgtsFile && declaracaoFgtsFile && csvFile) {
      handleFilesVerificationFgts();
    }
  }, [guiaFgtsFile, declaracaoFgtsFile, csvFile]);

  const handleFilesVerificationFgts = async () => {
    if (!guiaFgtsFile || !declaracaoFgtsFile || !csvFile) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    const formData = new FormData();
    formData.append("guiaFgtsFile", guiaFgtsFile);
    formData.append("declaracaoFgtsFile", declaracaoFgtsFile);
    formData.append("csvFile", csvFile);

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-arquivos-fgts`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log("Dados verificados:", response.data);
      setIsVerificationSuccessfulFgts(true);
      toast.success(response.data.message);
    } catch (error) {
      setIsVerificationSuccessfulFgts(false);
      console.error("Erro ao verificar arquivos:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar arquivos. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0032 - 01/07/24

  useEffect(() => {
    if (darfInssFile && declaracaoCompletaFile && reciboFile && csvFile) {
      handleInssFilesVerification();
    }
  }, [darfInssFile, declaracaoCompletaFile, reciboFile, csvFile]);

  const handleInssFilesVerification = async () => {
    if (!darfInssFile || !declaracaoCompletaFile || !reciboFile || !csvFile) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    const formData = new FormData();
    formData.append("darfInssFile", darfInssFile);
    formData.append("declaracaoCompletaFile", declaracaoCompletaFile);
    formData.append("reciboFile", reciboFile);
    formData.append("csvFile", csvFile);

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-arquivos-inss`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log("Dados verificados:", response.data);
      setIsVerificationSuccessfulInss(true);
      toast.success(response.data.message);
    } catch (error) {
      setIsVerificationSuccessfulInss(false);
      console.error("Erro ao verificar arquivos:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar arquivos. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0036 - MATHEUS BOTELHO - 10/07/24

  useEffect(() => {
    if (boletoSindicatoFile && csvFile) {
      handleBoletoSindicatoVerification();
    }
  }, [boletoSindicatoFile, csvFile]);

  const handleBoletoSindicatoVerification = async () => {
    if (!boletoSindicatoFile || !csvFile) {
      toast.error("Por favor, selecione os arquivos Boleto Sindicato e CSV.");
      return;
    }

    const formData = new FormData();
    formData.append("boletoSindicatoFile", boletoSindicatoFile);
    formData.append("csvFile", csvFile);

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/verificar-boleto-sindicato`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log("Dados verificados:", response.data); // Log para verificar os dados recebidos
      setIsVerificationSuccessfulBoleto(true);
      toast.success(response.data.message);
    } catch (error) {
      setIsVerificationSuccessfulBoleto(false);
      console.error("Erro ao verificar arquivo:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao verificar arquivo. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  const handleFilesUpload = async () => {
    if (!csvFile || !pdfFile || !espelhoFilePDF) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    setIsUploading(true); // Desabilita o botão

    const loadingToast = toast.loading("Enviando arquivos...");

    const formData = new FormData();
    formData.append("csvFile", csvFile);
    formData.append("pdfFile", pdfFile);
    formData.append("espelhoFilePDF", espelhoFilePDF);
    formData.append("id_func", user?.id_func);

    let emailLoadingToast;
    let fgtsLoadingToast;
    let inssLoadingToast;
    let sindicatoLoadingToast;

    try {
      console.log("Enviando arquivos CSV, PDF e espelho para processamento");
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/salarios`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log(
        "Resposta da verificação e processamento dos arquivos:",
        response.data,
      );

      toast.update(loadingToast, {
        render: "Arquivos enviados com sucesso! Aguardando envio de e-mail...",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      emailLoadingToast = toast.loading("Enviando e-mail...");

      const emailResponse = await axios.post(
        `${API_URL}/lancamentos-salarios/enviar-email-salarios`,
        {
          idCond: response.data.idCond,
          nomeCondominio: response.data.nomeCondominio,
          periodCSV: response.data.periodCSV,
          emailSindico: response.data.emailSindico,
          pdfFileName: response.data.pdfFileName,
          espelhoFilePDFName: response.data.espelhoFilePDFName,
        },
      );

      toast.update(emailLoadingToast, {
        render: emailResponse.data,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      // Enviar arquivos de INSS
      inssLoadingToast = toast.loading("Enviando arquivos de INSS...");

      const inssFormData = new FormData();
      inssFormData.append("darfInssFile", darfInssFile);
      inssFormData.append("declaracaoCompletaFile", declaracaoCompletaFile);
      inssFormData.append("reciboFile", reciboFile);
      inssFormData.append("csvFile", csvFile);
      inssFormData.append("id_func", user?.id_func);

      console.log("Enviando arquivos de INSS para processamento");
      const inssResponse = await axios.post(
        `${API_URL}/lancamentos-salarios/criar-inss`,
        inssFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log("Resposta da criação de INSS:", inssResponse.data);

      toast.update(inssLoadingToast, {
        render: "Arquivos de INSS enviados com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      // Enviar arquivos de FGTS, se presentes
      if (guiaFgtsFile && declaracaoFgtsFile) {
        fgtsLoadingToast = toast.loading("Enviando arquivos de FGTS...");

        const fgtsFormData = new FormData();
        fgtsFormData.append("guiaFgtsFile", guiaFgtsFile);
        fgtsFormData.append("declaracaoFgtsFile", declaracaoFgtsFile);
        fgtsFormData.append("csvFile", csvFile);
        fgtsFormData.append("id_func", user?.id_func);

        console.log("Enviando arquivos de FGTS para processamento");
        const fgtsResponse = await axios.post(
          `${API_URL}/lancamentos-salarios/criar-fgts`,
          fgtsFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        console.log("Resposta da criação de FGTS:", fgtsResponse.data);

        toast.update(fgtsLoadingToast, {
          render: "Arquivos de FGTS enviados com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      // Verifica e envia o arquivo do sindicato, se presente
      if (boletoSindicatoFile) {
        sindicatoLoadingToast = toast.loading(
          "Enviando boleto do sindicato...",
        );
        await handleCreateDespesaSindicato();
        toast.update(sindicatoLoadingToast, {
          render: "Boleto do sindicato enviado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      clearFilesAndState();
      clearFilesAndStateFgts();
      clearFilesAndStateInss();
      clearFilesAndStateSindicato();
    } catch (error) {
      console.error("Erro ao fazer upload dos arquivos:", error);
      toast.update(loadingToast, {
        render:
          "Erro ao fazer upload dos arquivos. Por favor, tente novamente.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });

      if (emailLoadingToast) {
        toast.update(emailLoadingToast, {
          render: "Erro ao enviar o e-mail. Por favor, tente novamente.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }

      if (fgtsLoadingToast) {
        toast.update(fgtsLoadingToast, {
          render:
            "Erro ao enviar os arquivos de FGTS. Por favor, tente novamente.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }

      if (inssLoadingToast) {
        toast.update(inssLoadingToast, {
          render:
            "Erro ao enviar os arquivos de INSS. Por favor, tente novamente.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }

      if (sindicatoLoadingToast) {
        toast.update(sindicatoLoadingToast, {
          render:
            "Erro ao enviar o boleto do sindicato. Por favor, tente novamente.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } finally {
      setIsUploading(false); // Reabilita o botão
    }
  };

  //SSI 0036 - MATHEUS BOTELHO - 11/07/24

  const handleCreateDespesaSindicato = async () => {
    if (!boletoSindicatoFile || !csvFile) {
      toast.error("Por favor, selecione os arquivos Boleto Sindicato e CSV.");
      return;
    }

    const formData = new FormData();
    formData.append("boletoSindicatoFile", boletoSindicatoFile);
    formData.append("csvFile", csvFile);
    formData.append("id_func", user?.id_func);

    try {
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/criar-despesa-sindicato`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log("Despesa criada:", response.data); // Log para verificar os dados recebidos
      toast.success("Despesa do Sindicato criada com sucesso!");
    } catch (error) {
      console.error("Erro ao criar despesa:", error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao criar despesa. Por favor, tente novamente.",
      );
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0030 - 27/06/24

  const handleFilesUploadAdiantamento = async () => {
    if (!csvAdiantamento || !pdfAdiantamento1 || !pdfAdiantamento2) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    setIsUploading(true); // Desabilita o botão

    const loadingToast = toast.loading("Enviando arquivos de adiantamento...");

    const formData = new FormData();
    formData.append("csvAdiantamento", csvAdiantamento);
    formData.append("pdfAdiantamento1", pdfAdiantamento1);
    formData.append("pdfAdiantamento2", pdfAdiantamento2);
    formData.append("id_func", user?.id_func);

    let emailLoadingToast;

    try {
      console.log("Enviando arquivos de adiantamento para processamento");
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/criar-adiantamentos`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log(
        "Resposta da verificação e processamento dos arquivos de adiantamento:",
        response.data,
      );

      toast.update(loadingToast, {
        render:
          "Arquivos de adiantamento enviados com sucesso! Aguardando envio de e-mail...",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      emailLoadingToast = toast.loading("Enviando e-mail...");

      const emailResponse = await axios.post(
        `${API_URL}/lancamentos-salarios/enviar-email-adiantamentos`,
        {
          idCond: response.data.idCond,
          nomeCondominio: response.data.nomeCondominio,
          periodCSV: response.data.periodCSV,
          emailSindico: response.data.emailSindico,
          pdfFileName1: response.data.pdfFileName1,
          pdfFileName2: response.data.pdfFileName2,
        },
      );

      toast.update(emailLoadingToast, {
        render: emailResponse.data,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      clearFilesAndStateAdiantamento();
    } catch (error) {
      console.error(
        "Erro ao fazer upload dos arquivos de adiantamento:",
        error,
      );
      toast.update(loadingToast, {
        render:
          "Erro ao fazer upload dos arquivos de adiantamento. Por favor, tente novamente.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });

      if (emailLoadingToast) {
        toast.update(emailLoadingToast, {
          render: "Erro ao enviar o e-mail. Por favor, tente novamente.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } finally {
      setIsUploading(false); // Reabilita o botão
    }
  };

  //FIM DA ALTERAÇÃO

  //SSI 0032 - 02/07/24

  const handleFilesUploadInss = async () => {
    if (!darfInssFile || !declaracaoCompletaFile || !reciboFile || !csvFile) {
      toast.error("Por favor, selecione todos os arquivos necessários.");
      return;
    }

    setIsUploading(true); // Desabilita o botão

    const loadingToast = toast.loading("Enviando arquivos INSS...");

    const formData = new FormData();
    formData.append("darfInssFile", darfInssFile);
    formData.append("declaracaoCompletaFile", declaracaoCompletaFile);
    formData.append("reciboFile", reciboFile);
    formData.append("csvFile", csvFile);
    formData.append("id_func", user?.id_func);

    try {
      console.log("Enviando arquivos INSS para processamento");
      const response = await axios.post(
        `${API_URL}/lancamentos-salarios/criar-inss`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      console.log("Resposta da criação de INSS:", response.data);

      toast.update(loadingToast, {
        render: "Arquivos INSS enviados com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      clearFilesAndStateInss();
    } catch (error) {
      console.error("Erro ao fazer upload dos arquivos INSS:", error);
      toast.update(loadingToast, {
        render:
          "Erro ao fazer upload dos arquivos INSS. Por favor, tente novamente.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsUploading(false); // Reabilita o botão
    }
  };

  //FIM DA ALTERAÇÃO

  const resetState = () => {
    setNomeCondominio("");
    setCategoria("");
    setPeriodo("");
    setQuantidadeFuncionarios(0);
    setIsVerificationSuccessful(false);
  };

  const clearFilesAndState = () => {
    setCsvFile(null);
    setPdfFile(null);
    setEspelhoFilePDF(null);
    setPdfInssFiles([]);
    setDarfInssFile(null);
    setDeclaracaoCompletaFile(null);
    setReciboFile(null);
    resetState();
  };

  const clearFilesAndStateFgts = () => {
    setGuiaFgtsFile(null);
    setDeclaracaoFgtsFile(null);
    setCsvFile(null);
  };

  const clearFilesAndStateInss = () => {
    setDarfInssFile(null);
    setDeclaracaoCompletaFile(null);
    setReciboFile(null);
    setCsvFile(null);
    setIsVerificationSuccessfulInss(false);
  };

  const clearFilesAndStateAdiantamento = () => {
    setCsvAdiantamento(null);
    setPdfAdiantamento1(null);
    setPdfAdiantamento2(null);
    setInfoCondominioAdiantamentos({}); // Adicionando para limpar as informações do Adiantamento
    setIsVerificationSuccessfulAdiantamento(false); // Adicionando para resetar o estado de verificação do Adiantamento
  };

  const clearFilesAndStateSindicato = () => {
    setBoletoSindicatoFile(null);
    setIsVerificationSuccessfulBoleto(false); // Adicionando para resetar o estado de verificação do Adiantamento
  };

  const handleCsvFileRemove = () => {
    setCsvFile(null);
    resetState();
  };

  const handlePdfFileRemove = () => {
    setPdfFile(null);
    resetState();
  };

  const handleEspelhoFileRemove = () => {
    setEspelhoFilePDF(null);
    resetState();
  };

  const handleCsvAdiantamentoRemove = () => setCsvAdiantamento(null);
  const handlePdfAdiantamento1Remove = () => setPdfAdiantamento1(null);
  const handlePdfAdiantamento2Remove = () => setPdfAdiantamento2(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="mb-4">
        <PageBreadcrumb items={defaultBreadcrumbItems} />
        <h2>Lançamentos - Funcionários</h2>
        <div className="form-group mt-4 mb-4">
          <label htmlFor="menuSelect">Selecione o tipo de lançamento:</label>
          <select
            id="menuSelect"
            className="form-select"
            value={selectedOption}
            onChange={handleSelectionChange}
          >
            <option value="salarios">Salários</option>
            <option value="adiantamentos">Adiantamentos</option>
          </select>
        </div>
      </div>
      {selectedOption === "salarios" && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <div
                style={{
                  borderRadius: "10px",
                  flex: 8,
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "left",
                    backgroundColor: "#eef0f1",
                    border: "1px solid #cdcfd0",
                    padding: "7px 0 7px 15px",
                    borderRadius: "10px 10px 0 0",
                    color: "#434343",
                  }}
                >
                  Salários
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #cdcfd0",
                    borderRadius: "0 0 10px 10px",
                    marginBottom: "10px",
                  }}
                >
                  <FileUpload
                    label="SALÁRIO - CSV"
                    accept=".csv"
                    multiple={false}
                    onFileSelect={(file) => setCsvFile(file)}
                    onFileRemove={handleCsvFileRemove}
                    fileNames={csvFile ? [csvFile.name] : []}
                    Icon={DescriptionRoundedIcon}
                  />
                  <FileUpload
                    label="SALÁRIO - HOLERITE"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setPdfFile(file)}
                    onFileRemove={handlePdfFileRemove}
                    fileNames={pdfFile ? [pdfFile.name] : []}
                    Icon={PictureAsPdfRoundedIcon}
                  />
                  <FileUpload
                    label="SALÁRIO - ESPELHO"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setEspelhoFilePDF(file)}
                    onFileRemove={handleEspelhoFileRemove}
                    fileNames={espelhoFilePDF ? [espelhoFilePDF.name] : []}
                    Icon={PictureAsPdfRoundedIcon}
                  />
                </div>
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  flex: 3,
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "left",
                    backgroundColor: "#eef0f1",
                    border: "1px solid #cdcfd0",
                    padding: "7px 0 7px 15px",
                    borderRadius: "10px 10px 0 0",
                    color: "#434343",
                  }}
                >
                  Boleto Sindicato
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #cdcfd0",
                    borderRadius: "0 0 10px 10px",
                    marginBottom: "10px",
                  }}
                >
                  <FileUpload
                    label="BOLETO SINDICATO"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setBoletoSindicatoFile(file)}
                    onFileRemove={() => setBoletoSindicatoFile(null)}
                    fileNames={
                      boletoSindicatoFile ? [boletoSindicatoFile.name] : []
                    }
                    Icon={PictureAsPdfRoundedIcon}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  flex: 3,
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "left",
                    backgroundColor: "#eef0f1",
                    border: "1px solid #cdcfd0",
                    padding: "7px 0 7px 15px",
                    borderRadius: "10px 10px 0 0",
                    color: "#434343",
                  }}
                >
                  FGTS
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #cdcfd0",
                    borderRadius: "0 0 10px 10px",
                    marginBottom: "10px",
                  }}
                >
                  <FileUpload
                    label="GUIA FGTS"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setGuiaFgtsFile(file)}
                    onFileRemove={() => setGuiaFgtsFile(null)}
                    fileNames={guiaFgtsFile ? [guiaFgtsFile.name] : []}
                    Icon={PictureAsPdfRoundedIcon}
                  />
                  <FileUpload
                    label="DECLARAÇÃO FGTS"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setDeclaracaoFgtsFile(file)}
                    onFileRemove={() => setDeclaracaoFgtsFile(null)}
                    fileNames={
                      declaracaoFgtsFile ? [declaracaoFgtsFile.name] : []
                    }
                    Icon={PictureAsPdfRoundedIcon}
                  />
                </div>
              </div>
              <div
                style={{
                  borderRadius: "10px",
                  flex: 3,
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "left",
                    backgroundColor: "#eef0f1",
                    border: "1px solid #cdcfd0",
                    padding: "7px 0 7px 15px",
                    borderRadius: "10px 10px 0 0",
                    color: "#434343",
                  }}
                >
                  INSS
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #cdcfd0",
                    borderRadius: "0 0 10px 10px",
                    marginBottom: "10px",
                  }}
                >
                  <FileUpload
                    label="DARF - INSS"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setDarfInssFile(file)}
                    onFileRemove={() => setDarfInssFile(null)}
                    fileNames={darfInssFile ? [darfInssFile.name] : []}
                    Icon={PictureAsPdfRoundedIcon}
                  />
                  <FileUpload
                    label="Declaração completa"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setDeclaracaoCompletaFile(file)}
                    onFileRemove={() => setDeclaracaoCompletaFile(null)}
                    fileNames={
                      declaracaoCompletaFile
                        ? [declaracaoCompletaFile.name]
                        : []
                    }
                    Icon={PictureAsPdfRoundedIcon}
                  />
                  <FileUpload
                    label="Recibo"
                    accept=".pdf"
                    multiple={false}
                    onFileSelect={(file) => setReciboFile(file)}
                    onFileRemove={() => setReciboFile(null)}
                    fileNames={reciboFile ? [reciboFile.name] : []}
                    Icon={PictureAsPdfRoundedIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          {isVerificationSuccessful && nomeCondominio && (
            <div
              style={{
                padding: "0 15px",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
                color: "#434343",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Nome do Condomínio:</strong> {nomeCondominio}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Categoria:</strong> {categoria}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Período:</strong> {periodo}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Quantidade de Funcionários:</strong>{" "}
                {quantidadeFuncionarios}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={handleFilesUpload}
              disabled={
                !isVerificationSuccessful || isUploading || isVerifyingDespesas
              }
              className={`btn ${
                isUploading ? "btn-secondary" : "btn-primary"
              } me-1 mb-1`}
              type="button"
            >
              {isUploading ? "Enviando..." : "Enviar arquivos Salário"}
            </button>
          </div>
        </div>
      )}
      {selectedOption === "adiantamentos" && (
        <div>
          <div
            style={{
              borderRadius: "10px",
              flex: 3,
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                textAlign: "left",
                backgroundColor: "#eef0f1",
                border: "1px solid #cdcfd0",
                padding: "7px 0 7px 15px",
                borderRadius: "10px 10px 0 0",
                color: "#434343",
              }}
            >
              Adiantamentos
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                padding: "10px",
                border: "1px solid #cdcfd0",
                borderRadius: "0 0 10px 10px",
                marginBottom: "10px",
              }}
            >
              <FileUpload
                label="ADIANTAMENTO - CSV"
                accept=".csv"
                multiple={false}
                onFileSelect={(file) => setCsvAdiantamento(file)}
                onFileRemove={handleCsvAdiantamentoRemove}
                fileNames={csvAdiantamento ? [csvAdiantamento.name] : []}
                Icon={DescriptionRoundedIcon}
              />
              <FileUpload
                label="ADIANTAMENTO - HOLERITE"
                accept=".pdf"
                multiple={false}
                onFileSelect={(file) => setPdfAdiantamento1(file)}
                onFileRemove={handlePdfAdiantamento1Remove}
                fileNames={pdfAdiantamento1 ? [pdfAdiantamento1.name] : []}
                Icon={PictureAsPdfRoundedIcon}
              />
              <FileUpload
                label="ADIANTAMENTO - ESPELHO"
                accept=".pdf"
                multiple={false}
                onFileSelect={(file) => setPdfAdiantamento2(file)}
                onFileRemove={handlePdfAdiantamento2Remove}
                fileNames={pdfAdiantamento2 ? [pdfAdiantamento2.name] : []}
                Icon={PictureAsPdfRoundedIcon}
              />
            </div>
          </div>

          {infoCondominioAdiantamentos.nomeCondominio && (
            <div
              style={{
                padding: "0 15px",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
                color: "#434343",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Nome do Condomínio:</strong>{" "}
                {infoCondominioAdiantamentos.nomeCondominio}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Categoria:</strong>{" "}
                {infoCondominioAdiantamentos.categoria}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Período:</strong> {infoCondominioAdiantamentos.periodo}
              </div>
              <div
                style={{
                  backgroundColor: "#fcfcfc",
                  border: "1px solid #cdcfd0",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  flex: 1,
                  fontSize: "11px",
                }}
              >
                <strong>Quantidade de Funcionários:</strong>{" "}
                {infoCondominioAdiantamentos.quantidadeFuncionarios}
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={handleFilesUploadAdiantamento}
              disabled={
                !isVerificationSuccessfulAdiantamento ||
                isUploading ||
                isVerifyingDespesasAdiantamento
              }
              className={`btn ${
                isUploading ? "btn-secondary" : "btn-primary"
              } me-1 mb-1`}
              type="button"
            >
              {isUploading ? "Enviando..." : "Enviar Adiantamentos"}
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div>
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirmação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{modalMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancelar
              </Button>
              {!haDespesasLiquidadas && despesasNaoLiquidadas.length > 0 && (
                <Button variant="danger" onClick={excluirDespesas}>
                  Excluir Despesas
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}

//SSI 0091 - MATHEUS BOTELHO - 15/01/25 - FIM

export default CriarSalarios;
