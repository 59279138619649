// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { forwardRef, useMemo } from "react";
import { Row } from "react-bootstrap";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface Parcela {
  vencimento: string;
  valor: number;
  observacao?: string;
}

interface Step4Props {
  parcelas: Parcela[];
  handleNext: () => void;
  configuracoes: {
    honorario: string;
    valorHonorario: number;
    taxaAdministradora: string;
    valorTaxaAdministradora: number;
    tipoRecebimentoTaxa: string;
    porcentagemRecebimento: number | null;
  };
  selectedData: any[];
  REACT_APP_API_URL: string;
  idCondominio: string;
  formRef: (el: HTMLFormElement | null) => void;
  setDadosQuartaPagina: (dados: Record<string, any>) => void;
}

const ModalAcordosEtapa4 = forwardRef<HTMLDivElement, Step4Props>(
  ({ parcelas, setDadosQuartaPagina }, ref) => {
    // Calcula o total das parcelas
    const totalParcelas = parcelas.reduce(
      (acc, parcela) => acc + parcela.valor,
      0,
    );

    // Função para formatar os dados das parcelas
    const formatarDadosQuartaPagina = () => {
      const dados: Record<string, any> = {};

      parcelas.forEach((parcela, index) => {
        // Converte a data de ISO (AAAA-MM-DD) para MM/DD/AAAA
        const [ano, mes, dia] = parcela.vencimento.split("-");
        const dataFormatada = `${mes}/${dia}/${ano}`;

        dados[`PARCELAS[${index}][VENCIMENTO]`] = dataFormatada;
        dados[`PARCELAS[${index}][VALOR]`] = parcela.valor.toFixed(2);
        dados[`PARCELAS[${index}][OBSERVACAO]`] = parcela.observacao;
      });

      return dados;
    };

    // UseEffect para passar os dados formatados ao modal geral
    React.useEffect(() => {
      if (parcelas.length > 0) {
        const dadosFormatados = formatarDadosQuartaPagina();
        setDadosQuartaPagina(dadosFormatados);
      }
    }, [parcelas]);

    // Definindo colunas para o AdvanceTable
    const columns = useMemo(() => {
      return [
        {
          accessorKey: "index",
          header: "parcela",
          cell: ({ row }: any) => row.index + 1,
        },
        {
          accessorKey: "vencimento",
          header: "Vencimento",
          cell: ({ row }: any) =>
            new Date(row.original.vencimento).toLocaleDateString("pt-BR"),
        },
        {
          accessorKey: "valor",
          header: "Valor (R$)",
          cell: ({ row }: any) => `R$ ${row.original.valor.toFixed(2)}`,
        },
      ];
    }, []);

    // Preparando os dados para a tabela
    const tableData = useMemo(() => parcelas, [parcelas]);

    // Criando instância da tabela
    const tableInstance = useAdvanceTable({
      data: tableData,
      columns: columns,
      pageSize: 10,
      pagination: true,
      sortable: true,
    });

    return (
      <div ref={ref}>
        <h6
          className="mb-3"
          style={{ fontSize: "20px", fontWeight: "bold", color: "#495057" }}
        >
          Validação das Parcelas
        </h6>
        {/* AdvanceTable */}
        <Row>
          <div
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              padding: "1rem 2rem",
            }}
            className="bg-white border-top border-bottom border-200 position-relative top-1"
          >
            <AdvanceTableProvider {...tableInstance}>
              <AdvanceTable
                tableProps={{
                  className:
                    "phoenix-table fs-9 table-hover text-left align-left mb-0",
                }}
              />
              {tableInstance.getRowModel().rows.length === 0 && (
                <div className="text-start py-10">
                  <p className="text-muted">Nenhuma parcela encontrada</p>
                </div>
              )}
            </AdvanceTableProvider>
          </div>
        </Row>
        {/* Total das Parcelas */}
        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "0 2rem",
          }}
        >
          <strong>Total: R$ {totalParcelas.toFixed(2)}</strong>
        </div>
      </div>
    );
  },
);

ModalAcordosEtapa4.displayName = "ModalAcordosEtapa4";

export default ModalAcordosEtapa4;
