// SSI 0089 - MATHEUS BOTELHO - 14/01/2025

import React from "react";
import ChartInadimplencia from "./charts/ChartInadimplencia";

const DashboardInadimplencia2: React.FC = () => {
  return (
    <div>
      <ChartInadimplencia />
    </div>
  );
};

export default DashboardInadimplencia2;
