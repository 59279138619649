// SSI - GCOLLE - REFAZENDO A PÁGINA DE EFETUAR ACORDOS - 20/01/2025
import React, { useEffect, useState, forwardRef, useMemo } from "react";
import { Form, Button, Spinner, Row } from "react-bootstrap";
import ReactSelect from "components/base/ReactSelect";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";

interface PlanoDeContasOption {
  label: string;
  value: string;
}

interface LinhaDetalhe {
  st_conta_cont: string;
  st_descricao_cont: string;
  complemento: string;
  total_receita: number;
}

interface Step2Props {
  selectedData: any[];
  configuracoes: {
    honorario: string;
    valorHonorario: number;
    taxaAdministradora: string;
    valorTaxaAdministradora: number;
    tipoRecebimentoTaxa: string;
    porcentagemRecebimento: number | null;
  };
  handleNext: () => void;
  REACT_APP_API_URL: string;
  idCondominio: string;
  formRef: (el: HTMLFormElement | null) => void;
  updateTotal: (total: number) => void;
  setDadosEtapa2: (dados: any) => void;
}

const ModalAcordosEtapa2 = forwardRef<HTMLFormElement, Step2Props>(
  (
    {
      selectedData,
      configuracoes,
      handleNext,
      REACT_APP_API_URL,
      idCondominio,
      updateTotal,
      setDadosEtapa2,
    },
    ref,
  ) => {
    const [detalhesCobrancasUnidade, setDetalhesCobrancasUnidade] = useState<
      any[]
    >([]);
    const [planoDeContas, setPlanoDeContas] = useState<PlanoDeContasOption[]>(
      [],
    );
    const [mostrarCamposAdicionar, setMostrarCamposAdicionar] = useState(false);

    const [novaLinha, setNovaLinha] = useState({
      conta: "",
      descricao: "",
      complemento: "",
      valor: "",
    });

    const [carregandoDados, setCarregandoDados] = useState(false);

    const formatarDadosParaApi = (detalhesCobrancas: any[]) => {
      const dadosFormatados: Record<string, any> = {};
      let total = 0;

      detalhesCobrancas.forEach((detalhe, index) => {
        const totalReceitaStr = detalhe.total_receita.toString();
        const valorNumerico = parseFloat(totalReceitaStr.replace(",", "."));
        total += valorNumerico;

        dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_CONTA_CONT]`] =
          detalhe.st_conta_cont;
        dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_DESCRICAO_CONT]`] =
          detalhe.st_descricao_cont;
        dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_COMPLEMENTO_REC]`] =
          detalhe.complemento || "";
        dadosFormatados[`RECEITA_APROPRIACAO[${index}][VL_VALOR_REC]`] =
          valorNumerico.toFixed(2);
        dadosFormatados[`RECEITA_APROPRIACAO[${index}][APROPRIARPRIMEIRO]`] =
          "0";
      });

      dadosFormatados["TOTAL_RECEITAAPRO"] = total.toFixed(2);
      return dadosFormatados;
    };

    useEffect(() => {
      if (detalhesCobrancasUnidade.length > 0) {
        const dadosFormatados = formatarDadosParaApi(detalhesCobrancasUnidade);
        setDadosEtapa2(dadosFormatados);
      }
    }, [detalhesCobrancasUnidade]);

    const buscarDetalhesCobrancasPorUnidade = async () => {
      try {
        setCarregandoDados(true);
        const detalhesCompletos: any[] = [];

        for (const item of selectedData) {
          const url = `${REACT_APP_API_URL}/efetuar-acordos/buscar-detalhes-cobrancas-unidade/${idCondominio}/${item.idUnidade}/${item.idCobranca}`;
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error("Erro ao buscar detalhes das cobranças.");
          }

          const data = await response.json();

          // LOG para verificar a resposta da API
          // console.log("Resposta da API para cobranças:", data);

          if (data.length > 0 && data[0].receita_apropriacao) {
            detalhesCompletos.push(...data[0].receita_apropriacao);
          }
        }

        const receitasProcessadas = processarReceitas(
          detalhesCompletos,
          configuracoes,
        );
        setDetalhesCobrancasUnidade(receitasProcessadas);
      } catch (error) {
        console.error("Erro ao buscar detalhes das cobranças:", error);
      } finally {
        setCarregandoDados(false);
      }
    };

    const processarReceitas = (receitas: any[], configuracoes: any) => {
      let totalInicial = 0;

      const receitasAgrupadas = receitas.reduce((agrupadas, receita) => {
        // console.log("Receita sendo processada:", receita);

        if (!["honorarios", "taxaAdministradora"].includes(receita.encargo)) {
          const chave = `${receita.st_conta_cont}-${receita.encargo || "principal"}`;
          const valor = parseFloat(receita.total_receita.replace(",", "."));

          totalInicial += valor;

          if (agrupadas[chave]) {
            agrupadas[chave].total_receita += valor;
          } else {
            agrupadas[chave] = {
              ...receita,
              total_receita: valor,
              complemento: "",
            };
          }
        }
        return agrupadas;
      }, {});

      // console.log("Receitas agrupadas após processamento:", receitasAgrupadas);

      if (configuracoes.honorario === "taxaConvencionada") {
        // console.log("Configuração Honorário:", configuracoes);
        // console.log("Total Inicial:", totalInicial);

        const valorHonorarios =
          totalInicial * (configuracoes.valorHonorario / 100);

        // console.log("Valor Honorários Calculado:", valorHonorarios);

        receitasAgrupadas["1.4.2.1-honorarios"] = {
          st_conta_cont: "1.4.2.1",
          st_descricao_cont: "Arrec. Cobrança Inadimplência - Individual",
          total_receita: valorHonorarios,
          encargo: "honorarios",
          complemento: "",
        };
      }

      if (configuracoes.taxaAdministradora === "cobrar") {
        const valorTaxaAdministradora =
          totalInicial * (configuracoes.valorTaxaAdministradora / 100);
        receitasAgrupadas["1.2.4-taxaAdministradora"] = {
          st_conta_cont: "1.2.4",
          st_descricao_cont: "Multas e Infrações",
          total_receita: valorTaxaAdministradora,
          encargo: "taxaAdministradora",
          complemento: "",
        };
      }

      return Object.values(receitasAgrupadas);
    };

    const carregarPlanoDeContas = async () => {
      try {
        const url = `${REACT_APP_API_URL}/efetuar-acordos/buscar-plano-de-contas/${idCondominio}`;
        const response = await fetch(url);
        if (!response.ok) throw new Error("Erro ao buscar plano de contas.");

        const data = await response.json();
        const formatado = data.map((conta: any) => ({
          label: `${conta.st_conta_cont} - ${conta.st_descricao_cont}`,
          value: conta.st_conta_cont,
        }));
        setPlanoDeContas(formatado);
      } catch (error) {
        console.error("Erro ao carregar plano de contas:", error);
      }
    };

    useEffect(() => {
      buscarDetalhesCobrancasPorUnidade();
    }, [selectedData]);

    useEffect(() => {
      carregarPlanoDeContas();
    }, []);

    useEffect(() => {
      const total = detalhesCobrancasUnidade.reduce(
        (acc, detalhe) => acc + parseFloat(detalhe.total_receita),
        0,
      );
      updateTotal(total);
    }, [detalhesCobrancasUnidade]);

    const handleAdicionarLinha = () => {
      if (!novaLinha.conta || !novaLinha.valor) {
        alert("Preencha todos os campos.");
        return;
      }

      const contaSelecionada = planoDeContas.find(
        (option) => option.value === novaLinha.conta,
      );

      const novaLista = [
        ...detalhesCobrancasUnidade,
        {
          st_conta_cont: novaLinha.conta,
          st_descricao_cont: contaSelecionada?.label.split(" - ")[1] || "",
          complemento: novaLinha.complemento,
          total_receita: parseFloat(novaLinha.valor),
        },
      ];

      setDetalhesCobrancasUnidade(novaLista);

      setNovaLinha({ conta: "", descricao: "", complemento: "", valor: "" });

      const totalAtualizado = novaLista.reduce(
        (acc, detalhe) => acc + parseFloat(detalhe.total_receita),
        0,
      );
      updateTotal(totalAtualizado);
    };

    const columns = useMemo(() => {
      return [
        {
          accessorKey: "st_conta_cont",
          header: "Conta Categoria",
          cell: ({ row }: any) =>
            `${row.original.st_conta_cont} - ${row.original.st_descricao_cont}`,
        },
        { accessorKey: "complemento", header: "Complemento" },
        {
          accessorKey: "total_receita",
          header: "Valor (R$)",
          cell: ({ row }: any) =>
            `R$ ${parseFloat(row.original.total_receita).toFixed(2)}`,
        },
      ];
    }, []);

    const tableData = useMemo(
      () => detalhesCobrancasUnidade,
      [detalhesCobrancasUnidade],
    );

    const tableInstance = useAdvanceTable({
      data: tableData,
      columns: columns,
      pageSize: 10,
      pagination: true,
      sortable: true,
    });

    return (
      <Form ref={ref}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6 style={{ fontSize: "20px", fontWeight: "bold" }}>
            Detalhes do Acordo
          </h6>
          <Button
            variant="primary"
            onClick={() => setMostrarCamposAdicionar(!mostrarCamposAdicionar)}
          >
            Adicionar Linha
          </Button>
        </div>

        {carregandoDados ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
            <span className="ms-2">Carregando...</span>
          </div>
        ) : (
          <>
            <Row>
              {mostrarCamposAdicionar && (
                <div className="mb-3">
                  <div className="d-flex gap-2">
                    <ReactSelect
                      options={planoDeContas as any}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      placeholder="Conta Categoria"
                      value={planoDeContas.find(
                        (option) => option.value === novaLinha.conta,
                      )}
                      onChange={(selectedOption: any) =>
                        setNovaLinha({
                          ...novaLinha,
                          conta: selectedOption?.value || "",
                        })
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "300px",
                          minWidth: "300px",
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Complemento"
                      value={novaLinha.complemento}
                      onChange={(e) =>
                        setNovaLinha({
                          ...novaLinha,
                          complemento: e.target.value,
                        })
                      }
                    />
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Valor"
                      value={novaLinha.valor}
                      onChange={(e) =>
                        setNovaLinha({ ...novaLinha, valor: e.target.value })
                      }
                    />
                    <Button variant="primary" onClick={handleAdicionarLinha}>
                      +
                    </Button>
                  </div>
                </div>
              )}
            </Row>
            <Row>
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  padding: "1rem 2rem",
                }}
                className="bg-white border-top border-bottom border-200 position-relative top-1"
              >
                <AdvanceTableProvider {...tableInstance}>
                  <AdvanceTable
                    tableProps={{
                      className:
                        "phoenix-table fs-9 table-hover text-left align-left mb-0",
                    }}
                  />
                  {tableInstance.getRowModel().rows.length === 0 && (
                    <div className="text-start py-10">
                      {" "}
                      <p className="text-muted">
                        Nenhum detalhe de cobrança encontrado
                      </p>
                    </div>
                  )}
                </AdvanceTableProvider>
              </div>
            </Row>

            <div
              className="mt-2"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "4rem",
              }}
            >
              <strong>
                Total: R${" "}
                {detalhesCobrancasUnidade
                  .reduce(
                    (acc, detalhe) => acc + parseFloat(detalhe.total_receita),
                    0,
                  )
                  .toFixed(2)}
              </strong>
            </div>
          </>
        )}
      </Form>
    );
  },
);

ModalAcordosEtapa2.displayName = "ModalAcordosEtapa2";

export default ModalAcordosEtapa2;
